import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, chatboxEllipses } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface LeaveApplication {
    _id: string,
    title: string,
    status: string,
    employee: Employee,
    messages: Message[],
    createdAt: Date
}

interface Message {
    timestamp: Date
}

interface Employee {
    employeeID: string,
    name: string
}

interface ParamTypes {
    role?: string
}

const StaffLeaves: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {role} = useParams < ParamTypes > ();
    const [leaves, setLeaves] = useState < LeaveApplication[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const loadItems = async () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip + 30;

        try {

            if (authInfo.user.role === 'Admin')
            {
                const result = await api.get(`/staffChats/getLeaves/${authInfo.user.instituteID}?skip=${limit}`);

                if(result.data.leaveApplications && result.data.leaveApplications.length)
                {
                    setLeaves([...leaves, ...result.data.leaveApplications]);
                }
            }
            else
            {
                if ((authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && role === "teachers"))
                {
                    const result = await api.get(`/staffChats/getTeacherLeaves/${authInfo.user.instituteID}?skip=${limit}`);

                    if(result.data.leaveApplications && result.data.leaveApplications.length)
                    {
                        setLeaves([...leaves, ...result.data.leaveApplications]);
                    }
                }
                if ((authInfo.user.role === 'Transporter') || (authInfo.user.role === 'Employee' && role === "drivers"))
                {
                    const result = await api.get(`/staffChats/getDriverLeaves/${authInfo.user.instituteID}?skip=${limit}`);

                    if(result.data.leaveApplications && result.data.leaveApplications.length)
                    {
                        setLeaves([...leaves, ...result.data.leaveApplications]);
                    }
                }
                
            }
                
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
            else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        } 
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                if (authInfo.user.role === 'Super' || authInfo.user.role === 'Admin')
                {
                    const result = await api.get(`/staffChats/getLeaves/${authInfo.user.instituteID}`);
                    setLeaves(result.data.leaveApplications);
                    setTotal(result.data.total);
                    setSkip(0);
                }
                else
                {
                    if ((authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && role === "teachers"))
                    {
                        const result = await api.get(`/staffChats/getTeacherLeaves/${authInfo.user.instituteID}`);
                        setLeaves(result.data.leaveApplications);
                        setTotal(result.data.total);
                    }
                    if ((authInfo.user.role === 'Transporter') || (authInfo.user.role === 'Employee' && role === "drivers"))
                    {
                        const result = await api.get(`/staffChats/getDriverLeaves/${authInfo.user.instituteID}`);
                        setLeaves(result.data.leaveApplications);
                        setTotal(result.data.total);
                    }
                }
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, role]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("leave_applications")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("leave_applications")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">

                            <IonCol sizeLg="4" size="8" className='table-heading'>
                                {t("title")}
                            </IonCol>
                            <IonCol sizeLg="4" size="0" className='table-heading ion-hide-lg-down'>
                                {t("employee")}
                            </IonCol>
                            <IonCol size="3" className='table-heading'>
                                {t("date")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={chatboxEllipses}
                                    color="light"
                                    className='table-icon'
                                />
                            </IonCol>
                        </IonRow>

                        {(leaves.length > 0) ? (leaves.map((leave, i) => {
                            return (
                                <IonRow key={leave._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                onClick={
                                    () => history.push(`/app/chat/${leave._id}`)
                                }>
                                    <IonCol sizeLg="4" size="8" className='table-field' >
                                        <IonRow>
                                            <IonCol>
                                                {leave.title}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='ion-hide-lg-up vid-responsive-field-1 text-capitalize'>
                                            <IonCol>
                                                <IonLabel>
                                                    {leave.employee.name}
                                                </IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                    <IonCol sizeLg="4" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                        {leave.employee.name}
                                    </IonCol>
                                    <IonCol size="3" className='table-field'>
                                        {formatDated(leave.createdAt)}
                                    </IonCol>
                                    <IonCol size="1" className='table-field'>
                                        <IonBadge className=''>{leave.messages.length+1}</IonBadge>
                                    </IonCol>
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_leaves")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )} 
                    </div>

                    {(leaves.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                   </IonRow>
                    }
                    
                </IonGrid>

            </IonContent>
            <IonFooter className='footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className="ion-padding-start footer-stats">
                                {t("showing")} {total === 0 ? t("no_records") : `1 - ${leaves.length} ${t("of")} ${total}`} 
                                </IonCol>
                            </IonRow>
                           

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default StaffLeaves;