import {
    ActionSheetButton,
    createAnimation,
    IonActionSheet,
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { add, addOutline, alertCircleOutline, calendar, call, chatbubblesOutline, chevronBack, chevronForward, close, closeOutline, constructOutline, flameOutline, gridOutline, logoWhatsapp, mailOpen, mailOutline, mailUnread, snowOutline, thermometerOutline, thumbsDown, thumbsUp, trashOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { Anchorme } from 'react-anchorme';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useAuth } from '../../../../components/AuthContext';
import usePageUnloadHandler from '../../../../components/usePageUnloadHandler';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

import 'react-vertical-timeline-component/style.min.css';
import 'swiper/css';
import "swiper/css/navigation";
import '@ionic/react/css/ionic-swiper.css';

interface Inquiry {
    _id: string,
    instituteInfo: Institute,
    source: string,
    type: string,
    text: string,
    status: string,
    condition?: string,
    userInfo: User,
    updates: Update[],
    createdAt: Date,
    updatedAt: Date
}

interface Institute {
    instituteID: string,
    name: string
}

interface User {
    userID: string,
    name: string,
    phone: string,
    email: string
}

interface Update {
    timestamp: Date,
    userInfo: UserInfo,
    update: UpdateInfo
}

interface UserInfo {
    userID: string,
    userName: string
}

interface UpdateInfo {
    text: string,
    status: string,
    dated?: Date
}

interface Meeting {
    date: string,
    textColor: string,
    backgroundColor: string
}

interface Total {
    status: string,
    totalCount: number
}

interface AdmnClass {
    masterID: string,
    name: string,
    annualCost: number,
    applicationFees: number,
    isActive: boolean
}

const allCategories = ["new", "unreachable", "shared", "followup", "meeting", "converted", "failed", "disqualified"];

const InquiriesBoard: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showActionSheet, setShowActionSheet] = useState < boolean > (false);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (1);
    const [categories, setCategories] = useState < string[] > (allCategories);
    const [actionButtons, setActionButtons] = useState < ActionSheetButton[] > ([]);
    const [current, setCurrent] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [reply, setReply] = useState < string > ("");
    const [status, setStatus] = useState < string > ("");
    const [enquiry, setEnquiry] = useState < Inquiry > ();
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();
    const [isOpen, setIsOpen] = useState < boolean > (false);
    const [showUpdate, setShowUpdate] = useState < boolean > (false);
    const [meetingDates, setMeetingDates] = useState < Meeting[] > ([]);
    const [total, setTotal] = useState < Total[] > ([]);
    const [source, setSource] = useState < string > ("all");
    const [type, setType] = useState < string > ("all");
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [searchText, setSearchText] = useState < string > ("");
    const [classes, setClasses] = useState < AdmnClass[] > ([]);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    // Create an instance of axios with default settings
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem) setScrollPosition(elem.scrollTop);
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const openURL = (value: string) => {
        var url = value;
        if (!/^https?:\/\//i.test(value)) {
            url = 'http://' + value;
        }
        window.open(url, '_system');
        return false;
    };

    const enterAnimation = (baseEl: HTMLElement) => {
        const root = baseEl.shadowRoot;
    
        const backdropAnimation = createAnimation()
          .addElement(root?.querySelector('ion-backdrop')!)
          .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
    
        const wrapperAnimation = createAnimation()
          .addElement(root?.querySelector('.modal-wrapper')!)
          .keyframes([
            { offset: 0, opacity: '0', transform: 'translateX(100%)' },
            { offset: 1, opacity: '0.99', transform: 'translateX(0)' },
          ]);
    
        return createAnimation()
          .addElement(baseEl)
          .easing('ease-out')
          .duration(500)
          .addAnimation([backdropAnimation, wrapperAnimation]);
    };
    
    const leaveAnimation = (baseEl: HTMLElement) => {
        return enterAnimation(baseEl).direction('reverse');
    };

    const formatDated = (value: string | string[] | undefined) => {
        if (typeof value === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB') + " at " + valueDate.toLocaleTimeString('en-US');
        }
    };

    const formatTheDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const formatDates = (value: Date) => {
        const valueDate = new Date(value);
        return " "+valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' }) + " at " + valueDate.toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" });
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleEnquiry = (inquiryID: string) => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/admissionInquiries/' + inquiryID);
                setEnquiry(result.data);
                setCurrent(inquiryID);

                const meet = await api.get('/admissionInquiries/getMeetings/' + authInfo.user.instituteID);
                setMeetingDates(meet.data);

                setIsOpen(true);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchData();

    }

    const updateCondition = (condition: string) => {
      
        setShowLoading(true);
        api.put('/admissionInquiries/'+current, { condition })
            .then((res: any) => {
                if (res.data.inquiry)
                {
                    setEnquiry(res.data.inquiry);
                    setPass(t("admn_inquiry")+t("has_been_updated"));
                    setIspass(true);
                }
            })
            .catch((error) => {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            })
            .finally(() => setShowLoading(false));
        
    }

    const handleSubmit = () => {

        if (!reply || reply === "") {
            setMessage(t("response_mandatory"));
            setIserror(true);
            return;
        }

        if (!status || status === "") {
            setMessage(t("status_mandatory"));
            setIserror(true);
            return;
        }

        if ((status === "followup" || status === "meeting")) {
            if (!selectedDate || selectedDate === "") {
                setMessage(t("select_app_date"));
                setIserror(true);
                return;
            }
        }
      
        setShowLoading(true);
        api.put('/admissionInquiries/'+current, { status, reply, 'adminID': authInfo.user._id, 'adminName': authInfo.user.name, selectedDate })
        .then((res: any) => {
            if (res.data.inquiry)
            {
                let theEnquiries = [...inquiries];
                const index = theEnquiries.findIndex((a) => a._id === current);
                if(index > -1)
                {
                    let totally = [...total];
                    const addIndex = totally.findIndex((a) => a.status === status);
                    const subIndex = totally.findIndex((a) => a.status === theEnquiries[index].status);

                    if (addIndex > -1) {
                        totally[addIndex].totalCount = totally[addIndex].totalCount+1;
                    } else {
                        totally.push({status, totalCount: 1});
                    }

                    if (subIndex > -1)
                    {
                        totally[subIndex].totalCount = totally[subIndex].totalCount-1;
                    }
                    setTotal(totally);

                    theEnquiries.splice(index, 1);
                    theEnquiries.unshift(res.data.inquiry);
                }
                setInquiries(theEnquiries);
            }
        
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    const generateActionButtons = (index: number) => {
        let cObjects: ActionSheetButton[] = [];
        for (let i=0; i < allCategories.length; i++)
        {
            if (!(categories.includes(allCategories[i])))
            {
                let cObject: ActionSheetButton;
                cObject = {
                    text: t(allCategories[i]),
                    icon: !isPlatform('ios') ? gridOutline : undefined,
                    handler: () => addCat(allCategories[i], index)
                }
                cObjects.push(cObject);
            }
        }

        cObjects.push({
            text: t('cancel'),
            icon: !isPlatform('ios') ? closeOutline : undefined,
            role: 'cancel'
        });

        setActionButtons(cObjects);
        setShowActionSheet(true);
    }


    const moveLeft = (index: number) => {

        if (index !== 0)
        {
            let comps = [...categories];
            let swapl = comps[index];
            let swapr = comps[index-1];
            comps[index] = swapr;
            comps[index-1] = swapl;
            setCategories(comps);
            updateUser(comps);
        }
    }

    const moveRight = (index: number) => {

        if (index !== (categories.length-1))
        {
            let comps = [...categories];
            let swapl = comps[index];
            let swapr = comps[index+1];
            comps[index] = swapr;
            comps[index+1] = swapl;
            setCategories(comps);
            updateUser(comps);
        }
    }

    const deleteCat = (index: number) => {
        if (categories.length > 1)
        {
            let comps = [...categories];
            comps.splice(index, 1); // 2nd parameter means remove one item only
            setCategories(comps);
            updateUser(comps);
        }
       
    }

    const addCat = (value: string, addIndex: number) => {
        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof toDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        let comps = [...categories];
        comps.splice(addIndex, 0, value); // 2nd parameter means remove no item, third means insert

        setShowLoading(true);
        api.post(`/admissionInquiries/listBoard/${authInfo.user.instituteID}`, {categories: comps, type, source, toDOB, fromDOB, searchText})
        .then((result: any) => {
            setInquiries(result.data.inquiries);
            setTotal(result.data.totalCounts);
            setSkip(1);
            setCategories(comps);
            updateUser(comps);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
    }

    const updateUser = (comps: string[]) => {
        
        api.put('/users/'+authInfo.user._id, { categories: comps })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        });
      
    }

    const loadItems = async () => {

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof toDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        let limit = skip + 1;

        try {
            const result = await api.post(`/admissionInquiries/listBoard/${authInfo.user.instituteID}?skip=${limit}`, {categories, type, source, toDOB, fromDOB, searchText});

            if(result.data.inquiries && result.data.inquiries.length)
            {
                setInquiries([...inquiries, ...result.data.inquiries]); 
                setSkip(limit);
                
            }
            

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    const handleFilter = () => {


        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/admissionInquiries/listBoard/${authInfo.user.instituteID}`, {categories, type, source, toDOB, fromDOB, searchText});
                setInquiries(result.data.inquiries);
                setTotal(result.data.totalCounts);
                setSkip(1);
                setShowLoading(false);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
    }

    useEffect(() => {
        
        const fetchUp = async () => {
            try {

                const res = await api.get(`/institutes/${authInfo.user.instituteID}`);
                    
                if (res.data.admnClasses) setClasses(res.data.admnClasses);
                else setClasses([]);

                const query = await api.get('/users/' + authInfo.user._id);
                if ((query.data.settings !== undefined) && (query.data.settings.enquiries !== undefined) && (query.data.settings.enquiries.length > 0))
                {
                    setCategories(query.data.settings.enquiries);
                } 

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } 
                
        };

        fetchUp();

    }, [authInfo]);


    useEffect(() => {
        const storedState = sessionStorage.getItem(history.location.pathname);
    
        if (history.action === "POP" && storedState) {
            // Data is present in local storage, use it to initialize state
            const { inquiries, total, skip, source, type, toDate, fromDate, searchText, scrollPosition } = JSON.parse(storedState);
    
            setInquiries(inquiries);
            setTotal(total);
            setSkip(skip);
            setSource(source);
            setType(type);
            setToDate(toDate);
            setFromDate(fromDate);
            setSearchText(searchText);
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
        } 
        else
        {
            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const result = await api.post(`/admissionInquiries/listBoard/${authInfo.user.instituteID}`, {
                        categories, type: "all", source: "all", toDOB: "", fromDOB: "", searchText: ""
                    });
                    setInquiries(result.data.inquiries);
                    setTotal(result.data.totalCounts);
                    setSkip(1);
                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
            };
            if (categories.length > 0)
            {
                fetchUp();
            }
            
        }
        setInitialRender(false);
    }, [authInfo, refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            inquiries,
            total,
            skip,
            source,
            type,
            toDate,
            fromDate,
            searchText,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [inquiries, total, skip, source, type, toDate, fromDate, searchText, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/crm`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("enquiries_board")}</IonTitle>
                    {!isPlatform('mobile') && (
                        <IonButtons slot="end">
                            <ThemeToggle />
                            <LanguageToggle />
                        </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("enquiries_board")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonActionSheet isOpen={showActionSheet}
                    onDidDismiss={
                        () => setShowActionSheet(false)
                    }
                    cssClass='swap-sheet'
                    header={t("add_board_column")}
                    buttons={actionButtons} 
                />

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-xl">
                        <IonCol size="1.5" className="ion-text-center field-fixed-1">
                            <IonButton onClick={() => handleFilter() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap ion-margin mt-20'>{t("view_enquiries")}
                            </IonButton>
                        </IonCol>
                        <IonCol size="2.5">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                        
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("name")}
                                            </IonLabel>

                                        <IonInput type="text"
                                            value={searchText}
                                            placeholder={`\uD83D\uDD0D\u00A0\u00A0\u00A0${t("name")}`}
                                            onIonInput={
                                                (e) => setSearchText(e.detail.value!)
                                        } />
                                                
                                     
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
 
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("inquiry_type")}</IonLabel>
                                            <IonSelect value={type}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("inquiry_type")}
                                                    onIonChange={
                                                        (e) => setType(e.detail.value)
                                                }>
                                                        <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                        <IonSelectOption value="general">{t("general")}</IonSelectOption>
                                                    {classes.map((classy, i) => (
                                                       
                                                        (<IonSelectOption key={i} value={classy.name}>{classy.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("source")}
                                        </IonLabel>
                                        <IonSelect value={source}
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                            placeholder={t("source")}
                                            onIonChange={
                                                (e) => setSource(e.detail.value)
                                            }>
                                            <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                            <IonSelectOption value="google">{t("google")}</IonSelectOption>
                                            <IonSelectOption value="instagram">{t("instagram")}</IonSelectOption>
                                            <IonSelectOption value="facebook">{t("facebook")}</IonSelectOption>
                                            <IonSelectOption value="whatsapp">{t("whatsapp")}</IonSelectOption>
                                            <IonSelectOption value="youtube">{t("youtube")}</IonSelectOption>
                                            <IonSelectOption value="linkedin">{t("linkedin")}</IonSelectOption>
                                            <IonSelectOption value="justdial">{t("justdial")}</IonSelectOption>
                                            <IonSelectOption value="website">{t("website")}</IonSelectOption>
                                            <IonSelectOption value="walkin">{t("walkin")}</IonSelectOption>
                                            <IonSelectOption value="newspaper">{t("newspaper")}</IonSelectOption>
                                            <IonSelectOption value="magazine">{t("magazine")}</IonSelectOption>
                                            <IonSelectOption value="poster">{t("poster")}</IonSelectOption>
                                            <IonSelectOption value="reference">{t("reference")}</IonSelectOption>
                                            <IonSelectOption value="other">{t("other")}</IonSelectOption>
                                            
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                        
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("from_date")}
                                            </IonLabel>
                                            <IonInput value={formatTheDate(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("from_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("to_date")}
                                            </IonLabel>
                                            <IonInput value={formatTheDate(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("to_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                        
                    </IonRow>
                </IonGrid>

                <IonGrid className="ion-no-padding mb-60 fullscreen-board">
                    <IonRow className="fullscreen-board">
                        <IonCol>
                            <Swiper className="fullscreen-board"
                                modules={[Navigation]}
                                slidesPerView={1}
                                spaceBetween={0}
                                initialSlide={0}
                                navigation={true}
                                loop={false}
                                breakpoints={{
                                    576: {
                                        slidesPerView: 2
                                    },
                                    992: {
                                        slidesPerView: 3
                                    },
                                    1400: {
                                        slidesPerView: 4
                                    }
                                }}>
                                { categories.map((cat, index) => {
                                    return (
                                        <SwiperSlide key={index} className='categorySlide'>
                                            <IonCardSubtitle className='categoryName'>
                                                <div className='title-icon-left'>
                                                    <IonIcon icon={chevronBack} onClick={() => moveLeft(index)} />
                                                    <IonIcon icon={add} onClick={() => generateActionButtons(index)}  />
                                                </div>
                                                {t(cat)} ({total.find((val) => val.status === cat)?.totalCount || 0})
                                                <div className='title-icon-right'>
                                                    <IonIcon icon={trashOutline} onClick={() => deleteCat(index)} />
                                                    <IonIcon icon={chevronForward} onClick={() => moveRight(index)} />
                                                </div>
                                            </IonCardSubtitle>
                                                <Link to={ `/app/admission/inquiries/new/${cat}` } className='itemAdd'>
                                                    <IonIcon icon={ addOutline } />
                                                </Link>
                                                {inquiries.map((inquiry, i) => 
                                                    (inquiry.status === cat) && (
                                                    <IonRow key={inquiry._id} className={inquiry.condition ? `kanbanItem ${inquiry.condition}` : 'kanbanItem'} >
                                                        <IonCol size="10" className='pointer' onClick={
                                                            () => handleEnquiry(inquiry._id)
                                                        }>
                                                            <div className='itemList overflow-hide'>
                                                                <IonLabel>
                                                                    <div className='itemTitle'>
                                                                        <h3 className='text-capitalize'>{inquiry.userInfo.name}</h3>
                                                                    </div>
                                                                    <IonBadge color={(inquiry.status === "new") ? "medium" : ((inquiry.status === "shared") || (inquiry.status === "unreachable"))
                                                                        ? "secondary" : (inquiry.status === "converted") ? "success" : ((inquiry.status === "failed") || (inquiry.status === "disqualified")) ? "danger" : "warning"}>    
                                                                        {inquiry.type}
                                                                    </IonBadge>
                                                                    <p>
                                                                        {formatDate(inquiry.updatedAt)}
                                                                    </p>
                                                                </IonLabel>
                                                            </div>
                                                        </IonCol>
                                                        <IonCol size="2" className='ion-text-center' >
                                                            <IonIcon 
                                                                icon={call}
                                                                color="tertiary"
                                                                className='table-icon'
                                                                onClick={
                                                                    () => document.location.href = `tel:${inquiry.userInfo.phone}`
                                                                }
                                                            />
                                                            {inquiry.userInfo.email !== "" && (
                                                            <>
                                                            <br/>
                                                            <IonIcon 
                                                                icon={mailOutline}
                                                                color="secondary"
                                                                className='table-icon mt-10'
                                                                onClick={
                                                                    () => document.location.href = `mailto:${inquiry.userInfo.email}`
                                                                }
                                                            />
                                                            </>
                                                            )}
                                                            <br/>
                                                            <IonIcon 
                                                                icon={logoWhatsapp}
                                                                color="success"
                                                                className='table-icon mt-10'
                                                                onClick={
                                                                    () => openURL(`https://wa.me/${inquiry.userInfo.phone}`)
                                                                }
                                                            />
                                                            {(inquiry.userInfo.userID.match(/^[0-9a-fA-F]{24}$/)) && (
                                                            <>
                                                            <br/>
                                                                <IonIcon 
                                                                icon={chatbubblesOutline}
                                                                color="primary"
                                                                className='table-icon mt-10'
                                                                onClick={
                                                                    () => history.push(`/app/admission/inquiry/${inquiry._id}/chat`)
                                                                }
                                                            />
                                                            </>
                                                            )}
                                                            
                                                        </IonCol>
                                                    </IonRow>
                                                    )
                                                )}
                                                
                                                {(total.find((val) => val.status === cat)?.totalCount || 0) === 0 && (
                                                <IonRow className='kanbanItem' >
                                                     <IonCol>
                                                        <IonItem lines="none">
                                                            <IonIcon icon={alertCircleOutline}
                                                                slot="start"
                                                                color="danger" />
                                                            <IonLabel className="list-title"><h3>{t("no_open_enq")}</h3></IonLabel>
            
                                                        </IonItem>
                                                     </IonCol>
                                                 </IonRow>
                                                )}


                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </IonCol>
                    </IonRow>
                    {(inquiries.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                                >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                    </IonRow>
                    }

            <IonModal
                isOpen={isOpen}
                onIonModalDidDismiss={() => {
                    setIsOpen(false);
                    setShowUpdate(false);
                }}
                enterAnimation={enterAnimation}
                leaveAnimation={leaveAnimation}
            >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{t("admn_inquiry_details")}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => {
                                    setIsOpen(false);
                                    setShowUpdate(false);
                                }}><IonIcon icon={close} />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                {enquiry && (
                <IonContent className="grey-shade">
                    <IonGrid className="ion-no-padding">
                     <IonRow className='ion-margin-top'>
                        <IonCol>
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-no-margin">
                                                <IonCardContent className="ion-no-padding">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem detail={false}
                                                                lines='none'
                                                                button={false}
                                                                color="secondary">
                                                                    <IonRow>
                                                                        <IonCol size="8">
                                                                            <IonLabel position="stacked" className="input-label">{t("inquiry_status")}</IonLabel>
                                                                            <IonInput readonly={true} className='text-capitalize'>{t(enquiry.status)}</IonInput>
                                                                        </IonCol>
                                                                        <IonCol size="4" >
                                                                            <IonLabel position="stacked" className="input-label">{t("updated_at")}</IonLabel>
                                                                            <IonInput readonly={true} className='text-capitalize'>{formatDate(enquiry.updatedAt)}</IonInput>
                                                            
                                                                        </IonCol>
                                                                    </IonRow>
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                   
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {!showUpdate && (
                    <IonRow>
                        <IonCol className='ion-padding-horizontal ion-text-center'>
                            <IonButton onClick={() => setShowUpdate(true)}
                                color="dark"
                                >{t("update_status")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    )}
                    {showUpdate && (<>
                     <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='ion-padding-bottom border-bottom-sec'>
                                {t("update_status")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                   
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-no-margin">
                                                <IonCardContent className="ion-no-margin ion-no-padding">
                                                    <IonRow>
                                                        <IonCol size="5">
                                                            <IonItem detail={false}
                                                                lines='none'
                                                                button={false}
                                                                >
                                                                <IonLabel position="stacked" color="secondary">
                                                                    {t("select_status")}
                                                                </IonLabel>
                                                                    <IonSelect value={status}
                                                                            cancelText={t("cancel")}
                                                                            okText={t("ok")}
                                                                            placeholder={t("select_status")}
                                                                            onIonChange={
                                                                                (e) => setStatus(e.detail.value)
                                                                        }>
                                                                        <IonSelectOption value="new">{t("new")}</IonSelectOption>
                                                                        <IonSelectOption value="unreachable">{t("unreachable")}</IonSelectOption>
                                                                        <IonSelectOption value="shared">{t("shared")}</IonSelectOption>
                                                                        <IonSelectOption value="followup">{t("followup")}</IonSelectOption>
                                                                        <IonSelectOption value="meeting">{t("meeting")}</IonSelectOption>
                                                                        <IonSelectOption value="converted">{t("converted")}</IonSelectOption>
                                                                        <IonSelectOption value="failed">{t("failed")}</IonSelectOption>
                                                                        <IonSelectOption value="disqualified">{t("disqualified")}</IonSelectOption>
                                                                        
                                                                    </IonSelect>
                                                            </IonItem>
                                                        </IonCol>
                                                        <IonCol size="7">
                                                            <IonItem detail={false}
                                                                lines='none'
                                                                button={false}
                                                                >
                                                                <IonLabel position="stacked" color="secondary">{t("remarks")}</IonLabel>
                                                                <IonInput value={reply}
                                                                    placeholder={t("remarks")}
                                                                    onIonInput={
                                                                        (e) => setReply(e.detail.value!)
                                                                }></IonInput>
                                                            </IonItem>
                                                        </IonCol>
                                                        
                                                    </IonRow>
                                                    {(status === "followup" || status === "meeting") && (
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem detail={false}
                                                                lines='full'
                                                                button={false}
                                                                id="open-date-input">
                                                                <IonLabel position="stacked" color="secondary">{status === "followup" ? t("followup") : t("meeting")} - {t("date")}</IonLabel>
                                                                <IonInput value={typeof selectedDate !== 'string' ? t("pick_date") : formatDated(selectedDate)} readonly={true} />
                                                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                                            <IonToolbar>
                                                                                <IonTitle>{status === "followup" ? t("followup") : t("meeting")} - {t("pick_date")}</IonTitle>
                                                                                
                                                                            </IonToolbar>
                                                                            <IonDatetime
                                                                                cancelText={t("cancel")}
                                                                                doneText={t("ok")}
                                                                                value={selectedDate}
                                                                                presentation="date-time"
                                                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                                                showDefaultButtons={true}
                                                                                size="cover"
                                                                                highlightedDates={meetingDates}
                                                                            />
                                                                        </IonContent>
                                                                        
                                                                </IonModal>
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                    )}
                                                    <IonRow className="mt-10">
                                                        <IonCol className="ion-text-center ion-margin-bottom">
                                                            <IonButton onClick={handleSubmit}
                                                                className="first-button"
                                                                fill="clear">{t("update_status")}</IonButton>

                                                        </IonCol>
                                                        
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    </>)}
                    <IonRow className="mt-30">
                        <IonCol size="6">
                            <IonTitle className='border-bottom-sec'>
                                {t("enq_info")}
                            </IonTitle>
                        </IonCol>
                        <IonCol size="6" className='ion-text-right'>
                            <IonIcon 
                                icon={flameOutline}
                                color="danger"
                                className='pr-16 table-icon pointer'
                                onClick={
                                    () => updateCondition('hot')
                                }
                            />
                            <IonIcon 
                                icon={thermometerOutline}
                                color="warning"
                                className='pr-16 table-icon pointer'
                                onClick={
                                    () => updateCondition('warm')
                                }
                            />
                            <IonIcon 
                                icon={snowOutline}
                                color="secondary"
                                className='pr-16 table-icon pointer'
                                onClick={
                                    () => updateCondition('cold')
                                }
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol>
                            <IonCard className={enquiry.condition ? enquiry.condition : ""}>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-no-margin profile-photo-card">
                                                <IonCardContent>
                                                    <IonRow>
                                                        <IonCol size="11">
                                                        <span className="profile-text">{enquiry.userInfo.name}</span>
                                                        </IonCol>
                                                        <IonCol size="1">
                                                            <IonIcon 
                                                                icon={call}
                                                                color="tertiary"
                                                                className='table-icon'
                                                                onClick={
                                                                    () => document.location.href = `tel:${enquiry.userInfo.phone}`
                                                                }
                                                            />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="4">
                                                            <p>
                                                                <span className='pt-05 text-capitalize'>{enquiry.userInfo.phone}</span>
                                                                <span className='pt-05'> 
                                                                {t(enquiry.source)}</span>
                                                            </p>

                                                        </IonCol>
                                                        <IonCol size="7">
                                                                <p>
                                                                    <span className="pt-05">{enquiry.type}</span>
                                                                    <span className='pt-05'>{formatDate(enquiry.createdAt)}</span>
                                                            
                                                                </p>
                                                        </IonCol>
                                                        
                                                        <IonCol size="1">
                                                            {enquiry.userInfo.email !== "" && (
                                                            <>
                                                            <IonIcon 
                                                                icon={mailOutline}
                                                                color="secondary"
                                                                className='table-icon mt-10'
                                                                onClick={
                                                                    () => document.location.href = `mailto:${enquiry.userInfo.email}`
                                                                }
                                                            />
                                                            <br/>
                                                            </>
                                                            )}
                                                             
                                                            <IonIcon 
                                                                icon={logoWhatsapp}
                                                                color="success"
                                                                className='table-icon mt-10'
                                                                onClick={
                                                                    () => openURL(`https://wa.me/${enquiry.userInfo.phone}`)
                                                                }
                                                            />
                                                            <br/>
                                                            {(enquiry.userInfo.userID.match(/^[0-9a-fA-F]{24}$/)) && (
                                                            <>
                                                            
                                                                <IonIcon 
                                                                    icon={chatbubblesOutline}
                                                                    color="primary"
                                                                    className='table-icon mt-10'
                                                                    onClick={
                                                                        () => history.push(`/app/admission/inquiry/${enquiry._id}/chat`)
                                                                    }
                                                                />
                                                                <br/>
                                                            </>
                                                            )}
                                                            <IonIcon 
                                                                icon={constructOutline}
                                                                color="dark"
                                                                className='table-icon mt-10'
                                                                onClick={
                                                                    () => history.push(`/app/admission/inquiry/${enquiry._id}/details`)
                                                                }
                                                            />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className='ion-margin-top'>
                                                        <IonCol className='anchorme-text'>
                                                            <Anchorme>{enquiry.text}</Anchorme>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                   
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                   
                    {(enquiry.updates.length > 0) && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("timeline")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol>
                            <VerticalTimeline layout='1-column' animate={false} lineColor="#086E7D">
                            
                                        {(enquiry.updates.slice(0).reverse().map((update, i) => {
                                        return (
                                            <VerticalTimelineElement
                                                key={i}
                                                className="vertical-timeline-element"
                                                icon={ <IonIcon icon={(update.update.status === 'new') ? mailUnread : (update.update.status === 'meeting' || update.update.status === 'followup') 
                                                    ? calendar : (update.update.status === 'converted') ? thumbsUp : (update.update.status === 'failed' || update.update.status === 'disqualified') 
                                                    ? thumbsDown : mailOpen} className='timeline-icon' />}
                                                iconStyle={{ background: 'rgb(8, 110, 125)', color: '#fff', textAlign: 'center' }}>
                                                <IonText className='timeline-para'>
                                            
                                                    {t(update.update.status)} {update.update.dated && " - "+t("on")+formatDates(update.update.dated)}
                                                    
                                                
                                                </IonText>
                                                <IonText className='timeline-date'>
                                                    <br/>{update.update.text}
                                                    <br/>{t("updated_on")} {formatDate(update.timestamp)}
                                                    <IonText> {t("marked_by")} {t(update.userInfo.userName)}</IonText>
                                                </IonText>
                                            
                                            </VerticalTimelineElement> 
                                        )
                                    }))}
                                
                            </VerticalTimeline>
                        </IonCol>
                    </IonRow>
                    </>)}
                    </IonGrid>
                </IonContent>
                )}
                </IonModal>
            </IonGrid>
        </IonContent>
    </IonPage>
    );
};

export default InquiriesBoard;