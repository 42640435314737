import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonProgressBar,
    IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { addCircleOutline, 
    alertCircleOutline, 
    attach, 
    book, 
    camera, 
    chevronForwardOutline, 
    imageOutline, 
    star, 
    starOutline, 
    trash, 
    trashOutline,
    videocam, 
} from 'ionicons/icons';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { PhotoViewer, Image } from '@capacitor-community/photoviewer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../components/AuthContext";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import FileOpener from "../../../components/FileOpener";

interface Attach {
    caption: string,
    filename: string
}

interface FileUpload {
    caption: string,
    file: File,
    imageURL: string,
    ext: string
}

interface FileInfo {
    imageURL: string,
    ext: string
}

interface ParamTypes {
    topicID: string
}

const TopicSubmission: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {topicID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [pop, setPop] = useState < boolean > (false);
    const [info, setInfo] = useState < string > ("");
    const [files, setFiles] = useState < Attach[] > ([]);
    const [fileUploads, setFileUploads] = useState < FileUpload[] > ([]);
    const [caption, setCaption] = useState < string > ("");
    const [upload, setUpload] = useState < File[] > ([]);
    const [imageURL, setImageURL] = useState < FileInfo[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [submissionID, setSubmissionID] = useState < string > ("");
    const [response, setResponse] = useState < string > ("");
    const [rating, setRating] = useState < number > ();
    const [topicName, setTopicName] = useState < string > ("");
    const [studentID, setStudentID] = useState < string > ("");

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const openImage = (url: string, mode: string) => {

        let images: Image[] = [];
        let startFrom = 0;
        let j = -1;

        if (mode === "web")
        {
            for (let i = 0; i < files.length; i++)
            {
                if(isFileImage(files[i].filename))
                {
                    j += 1;
                    images.push({url: `${process.env.REACT_APP_STATIC_S3}/reports/${files[i].filename}`, title: files[i].caption});
                    if (files[i].filename === url)
                    {
                        startFrom = j;
                    }
                }
            }
        }
        if (mode === "local")
        {
            for (let i = 0; i < fileUploads.length; i++)
            {
                if((fileUploads[i].ext !== "mp4") && (fileUploads[i].ext !== "pdf"))
                {
                    j += 1;
                    images.push({url: fileUploads[i].imageURL, title: fileUploads[i].caption});
                    if (fileUploads[i].imageURL === url)
                    {
                        startFrom = j;
                    }
                }
                
            }
        }
        
 
        if (images.length > 1)
        {
            PhotoViewer.show({images, mode: isPlatform('mobile') ? 'slider' : 'gallery', startFrom});
        }
        if (images.length === 1)
        {
            PhotoViewer.show({images, mode: 'one'});
        }
      
    };


    const openFile = async (url: string, ext: string) => {
        const mimeType = getMimeType(ext);
        if (isPlatform('capacitor'))
        {
            try  {
                await FileOpener.open({filePath: url, contentType: mimeType});
            }
            catch {
                setMessage(t("file_error"));
                setIserror(true);
            }
            
        }
    };


    const isFileImage= (fileName: string) => {
        // Extract the extension from the file name
        const extension = fileName.split('.').pop();
    
        // Check if the extension matches 'jpeg' or 'png'
        return extension === 'jpeg' || extension === 'png';
    };

    const isFileVideo= (fileName: string) => {
        const extension = fileName.split('.').pop();
        return extension === 'mp4' || extension === 'mov';
    };

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        if (ext === 'mp4')
            mime = 'video/mp4';
        if (ext === 'mov')
            mime = 'video/quicktime';
        if (ext === 'docx')
            mime = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (ext === 'xlsx')
            mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (ext === 'pptx')
            mime = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        if (ext === 'doc')
            mime = 'application/msword';
        if (ext === 'xls')
            mime = 'application/vnd.ms-excel';
        if (ext === 'ppt')
            mime = 'application/vnd.ms-powerpoint';
        return mime;
    };

    const getExtension = (ext: string) => {
        let mime = "";
        if (ext === 'image/jpeg')
            mime = 'jpeg';
        if (ext === 'image/png')
            mime = 'png';
        if (ext === 'application/pdf')
            mime = 'pdf';
        if (ext === 'video/mp4')
            mime = 'mp4';
        if (ext === 'video/quicktime')
            mime = 'mov';
        if (ext === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
            mime = 'docx';
        if (ext === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            mime = 'xlsx';
        if (ext === 'application/vnd.openxmlformats-officedocument.presentationml.presentation')
            mime = 'pptx';
        if (ext === 'application/msword')
            mime = 'doc';
        if (ext === 'application/vnd.ms-excel')
            mime = 'xls';
        if (ext === 'application/vnd.ms-powerpoint')
            mime = 'ppt';
        return mime;
    };

    const downloadFile = async (fileName : string) => {

        const myArray = fileName.split(".");
        const mimeType = getMimeType(myArray[1]);
        const url = process.env.REACT_APP_STATIC_S3 + '/reports/' + fileName;

        if (isPlatform('capacitor') && !showDownloading)
        {
            try {
                Filesystem.checkPermissions().then(async (res) => {
                    if (res.publicStorage !== 'granted') {
                        Filesystem.requestPermissions().then(async (ress) => {
                            if (ress.publicStorage === 'denied') {
                                setMessage(t("storage_denied"));
                                setIserror(true);
                            }
                            else {
                                setShowDownloading(true);
                                try {
                                    const savedFile = await Filesystem.downloadFile({
                                        path: fileName,
                                        url,
                                        directory: Directory.External,
                                    });
                                    
                                    if(savedFile.path) {
                                        await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                                    } else {
                                        setMessage(t("download_error"));
                                        setIserror(true);
                                    }
                                    
                                } catch(error: any) {
                                    setMessage(t("download_error"));  
                                    setIserror(true);
                                } finally {
                                    setShowDownloading(false);
                                }
                            }
                        });
                    }
                    else {
                        setShowDownloading(true);
                        try {
                            const savedFile = await Filesystem.downloadFile({
                                path: fileName,
                                url,
                                directory: Directory.External,
                            });
                            
                            if(savedFile.path) {
                                await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                            } else {
                                setMessage(t("download_error"));
                                setIserror(true);
                            }
                            
                        } catch(error: any) {
                            setMessage(t("download_error"));  
                            setIserror(true);
                        } finally {
                            setShowDownloading(false);
                        }
                    }
                });

            } catch {
                setMessage(t("download_error"));
                setIserror(true);
            } 
        }
        if (isPlatform('capacitor') && showDownloading)
        {
            setMessage(t("download_in_progress"));
            setIserror(true);
        }

        if (!isPlatform('capacitor'))
        {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noreferrer';
            link.download = 'download'; // Triggers download

            // Append the anchor to the body
            document.body.appendChild(link);

            // Programmatically click the link
            link.click();

            // Remove the anchor from the document
            document.body.removeChild(link);
        }

    };

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };

    const pickImages = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
            const result = await FilePicker.pickImages();
            const theUploads =  [...upload];
            const theURLs =  [...imageURL];

            for (let i=0; i < result.files.length; i++)
            {
                const file = result.files[i];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);

                        if (file.mimeType === 'image/jpeg')
                        {
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "jpeg"});
                        }
                        if (file.mimeType === 'image/png')
                        {
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "png"});
                        }
                    }
                    if ((file.mimeType === 'image/heic' || file.mimeType === 'image/heif'))
                    {
                        const jpegPath = await FilePicker.convertHeicToJpeg({path: file.path});
                        const webPath = convertFileSrc(jpegPath.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        const base64 = await fileToDataUri(rawFile);
                        theURLs.push({imageURL: base64, ext: "jpeg"});
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);

                        if (file.mimeType === 'image/jpeg')
                        {
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "jpeg"});
                        }
                        if (file.mimeType === 'image/png')
                        {
                            const base64 = await fileToDataUri(rawFile);
                            theURLs.push({imageURL: base64, ext: "png"});
                        }
                    }
                }
            }
            setUpload(theUploads);
            setImageURL(theURLs);
        } 
        catch (error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }

    };

    const pickFile = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {

            const result = await FilePicker.pickFiles({limit: 1});
            const theUploads =  [...upload];
            const theURLs =  [...imageURL];

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        theURLs.push({imageURL: webPath, ext: getExtension(file.mimeType)});
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        theURLs.push({imageURL: "", ext: getExtension(file.mimeType)});
                    }
                }
            }
            setUpload(theUploads);
            setImageURL(theURLs);
        } 
        catch(error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }
    };

    const pickVideo = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
           const result = await FilePicker.pickVideos({limit: 1});
           const theUploads =  [...upload];
           const theURLs =  [...imageURL];

           if (result.files.length === 1)
           {
               const file = result.files[0];
               //for native
               if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        theURLs.push({imageURL: webPath, ext: getExtension(file.mimeType)});
                    }
               }
               //for web
               else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        theURLs.push({imageURL: "", ext: getExtension(file.mimeType)});
                    }
               }
           }
           setUpload(theUploads);
           setImageURL(theURLs);
       } 
       catch(error: any) {
           setMessage(t("no_files_selected"));  
           setIserror(true);
       } finally {
            setShowLoading(false);
        }
   };

    const handleAdd = () => {

        if (!caption || caption === "") {
            setMessage(t("title_mandatory"));
            setIserror(true);
            return;
        }

        if (!upload.length || !imageURL.length || (upload.length !== imageURL.length)) {
            setMessage(t("file_invalid"));
            setIserror(true);
            return;
        }

        let uploads = [...fileUploads];
        for (let i=0; i < upload.length; i++)
        {
            uploads.push({caption: `${caption} (${i+1})`, file: upload[i], imageURL: imageURL[i].imageURL, ext: imageURL[i].ext});
        }
        
        setFileUploads(uploads);
        setUpload([]);
        setImageURL([]);
        setPop(false);

    }

    const deleteImage = (index: number) => {

        let uploads = [...fileUploads];
        if (index > -1) {
            uploads.splice(index, 1); // 2nd parameter means remove one item only
        }
        setFileUploads(uploads);

    }

    const handleReport = () => {

        if (!info || info === "") {
            setMessage(t("summary_mandatory"));
            setIserror(true);
            return;
        }

        //If submissionID !== "" && fileUploads.length === 0 => Just update work
        //If submissionID !== "" && fileUploads.length > 0 => update work & send files
        //If submissionID === "" && fileUploads.length === 0 -> add report with just work
        //If submissionID === "" && fileUploads.length > 0 -> add report with work and files

        const addReport = () => {
            return new Promise((resolve, reject) => {
              api.post('/evaluations/add', {studentID, topicID, topicName, info}).then(res => {
                return resolve(res.data.evaluation);
              }).catch(err => reject(err));
            });
        };

        const addReportAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/evaluations/attach/add', formData).then(res => {
                return resolve(res.data.evaluation);
              }).catch(err => reject(err));
            });
        };

        const upReport = () => {
            return new Promise((resolve, reject) => {
                api.put('/evaluations/'+submissionID, { info }).then(res => {
                return resolve(res.data);
                }).catch(err => reject(err));
            });
        };

        const upAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
                api.post('/evaluations/attach/update', formData).then(res => {
                    return resolve(res.data.evaluation);
                }).catch(err => reject(err));
            });
        };

        setShowLoading(true);

        if (submissionID !== "")
        {
            if (fileUploads.length === 0)
            {
                upReport()
                .then(data => {
                    setPass(t("submission_updated"));
                    setIspass(true);
                })
                .catch((error) => {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                })
                .finally(() => setShowLoading(false));
            }
            else
            {
                let formData = new FormData();

                formData.append('evaluationID', submissionID);
                formData.append('info', info);

                for (let i=0; i < fileUploads.length; i++)
                {
                    formData.append('submission[]', fileUploads[i].file, fileUploads[i].caption);
                }
    
                setShowLoading(true);
                upAttach(formData)
                .then(data => {
                    setFileUploads([]);
                    setPass(t("submission_updated"));
                    setIspass(true);
                })
                .catch((error) => {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                })
                .finally(() => setShowLoading(false));
            }
        }
        else
        {
            if (fileUploads.length === 0)
            {
                addReport()
                .then(data => {
                    setPass(t("submission_updated"));
                    setIspass(true);
                })
                .catch((error) => {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                })
                .finally(() => setShowLoading(false));
            }
            else
            {
                let formData = new FormData();

                formData.append('studentID', studentID);
                formData.append('topicID', topicID);
                formData.append('topicName', topicName);
                formData.append('info', info);

                for (let i=0; i < fileUploads.length; i++)
                {
                    formData.append('submission[]', fileUploads[i].file, fileUploads[i].caption);
                }
    
                setShowLoading(true);
                addReportAttach(formData)
                .then(data => {
                    setFileUploads([]);
                    setPass(t("submission_updated"));
                    setIspass(true);
                })
                .catch((error) => {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                })
                .finally(() => setShowLoading(false));
            }
        }
        
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const top = await api.get(`/topics/${topicID}`);
                setTopicName(top.data.name);

                const student = await api.get(`/students/getByParent/${authInfo.user._id}`);
                setStudentID(student.data._id);

                const res = await api.post('/evaluations/getByStudentTopic', {'studentID': student.data._id, topicID});
                if (res.data)
                {
                    setSubmissionID(res.data._id);
                    setInfo(res.data.info);
                    setFiles(res.data.files);
                    setResponse(res.data.response);
                    setRating(res.data.rating);
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };
      
        fetchData();
     
    }, [authInfo, topicID, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/planner`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("topic_submission")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("topic_submission")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }

                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className="border-radius-10 ion-margin-bottom planner-card-3">
                                <IonCardContent>
                                    <IonItem detail={false}
                                            lines='none'
                                            button={false}
                                            >
                                            <IonIcon
                                                slot="start"
                                                className='tran-icon'
                                                icon={book}
                                                color="tertiary"
                                                />

                                            <IonLabel className='topic-label'>
                                                <h3>{topicName}</h3>
                                            </IonLabel>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                   
                    {(rating !== undefined) && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("teacher_rating")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonLabel className='ml-30'>
                                   
                                        {(rating === 1) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 2) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 3) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 4) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                        {(rating === 5) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    
                                                />
                                            </>
                                        )}
                                                                    
                                    </IonLabel>
                        </IonCol>
                    </IonRow>
                    </>
                    )}
                     {(response !== undefined) && (response !== "") && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("teacher_response")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}
                                        >
                                        <IonLabel position="floating" color="dark">{t("review_details")}</IonLabel>
                                        <IonTextarea rows={6} autoGrow={true} value={response} readonly={true}></IonTextarea>
                                    </IonItem>
                                            
                                        
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    </>
                    )}
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                            {t("submission_details")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}
                                        >
                                        <IonLabel position="floating" color="dark">{t("submission_info")}</IonLabel>
                                        <IonTextarea rows={6} autoGrow={true} value={info} readonly={(rating !== undefined)} onIonInput={
                                                (e) => setInfo(e.detail.value!)
                                        }></IonTextarea>
                                    </IonItem>
                                            
                                        
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {(rating === undefined) && (
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='action-card-one'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none"
                                    button={true}
                                    color="primary"
                                    onClick={() => setPop(true)}
                                    >
                                        <IonIcon icon={addCircleOutline}
                                            slot="start" />
                                        <IonLabel className="action-title-default"><h3>{t("attach_files")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            <IonPopover className='upload-popup' isOpen={pop} onDidDismiss={e => setPop(false)}>
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" color="secondary">{t("title")}*</IonLabel>
                                                    <IonInput type="text"
                                                        value={caption}
                                                        placeholder={t("title")}
                                                        onIonInput={
                                                            (e) => setCaption(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                                <IonItem detail={true}
                                                        lines='none'
                                                        button={true}
                                                        onClick={pickImages}
                                                        >
                                                        <IonIcon
                                                            slot="start"
                                                            icon={camera}
                                                        />
                                                        <IonLabel>
                                                            {t("upload_photos")}
                                                        </IonLabel>
                                                        
                                                    </IonItem>
                                                    <IonItem detail={true}
                                                        lines='none'
                                                        button={true}
                                                        onClick={pickVideo}
                                                        >
                                                        <IonIcon
                                                            slot="start"
                                                            icon={videocam}
                                                        />
                                                        <IonLabel>
                                                            {t("upload_video")}
                                                        </IonLabel>
                                                            
                                                    </IonItem>
                                                    <IonItem detail={true}
                                                        lines='none'
                                                        button={true}
                                                        onClick={pickFile}
                                                        >
                                                        <IonIcon
                                                            slot="start"
                                                            icon={attach}
                                                        />
                                                        <IonLabel>
                                                            {t("attach_doc")}
                                                        </IonLabel>
                                                            
                                                    </IonItem>
                                                    {(upload.length > 0) && (
                                                    <IonItem detail={true}
                                                            color="danger"
                                                            detailIcon={trash}
                                                            lines='none'
                                                            button={true}
                                                            onClick={() => {
                                                                setUpload([]);
                                                                setImageURL([]);
                                                            }}
                                                        >
                                                        <IonBadge color="light" slot="start">{upload.length}</IonBadge>
                                                        <IonLabel>
                                                            {t("remove_files")}
                                                        </IonLabel>
                                                        
                                                    </IonItem>
                                                    )}
                                               
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-padding-horizontal">
                                        <IonButton onClick={handleAdd}
                                            expand="block"
                                            color="secondary">{t("add_files")}</IonButton>

                                    </IonCol>
                                </IonRow>
                            </IonPopover>
                        </IonCol>
                    </IonRow>
                    )}
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            {(files.length > 0) && (files.map((afile, i) => {
                                return (

                                    <IonCard key={i}>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonItem lines="none"
                                            button={true}
                                            detail={true}
                                            detailIcon={chevronForwardOutline}
                                            onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, "web") : downloadFile(afile.filename)}
                                            >
                                                <IonIcon icon={isFileImage(afile.filename) ? imageOutline : isFileVideo(afile.filename) ? videocam : attach}
                                                    slot="start"
                                                    color="tertiary" />
                                                <IonLabel className="action-title-two"><h3>{afile.caption}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>

                                )}))}

                                {(fileUploads.length > 0) && (fileUploads.map((afile, i) => {
                                return (

                                    <IonCard key={i}>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonItem lines="none"
                                                button={false}
                                            >
                                                <IonIcon icon={((afile.ext === "jpeg") || (afile.ext === "png")) ? imageOutline : ((afile.ext === "mp4") || (afile.ext === "mov")) ? videocam : attach}
                                                    slot="start"
                                                    color="tertiary"
                                                    onClick={() => ((afile.ext === "jpeg") || (afile.ext === "png")) ? openImage(afile.imageURL, "local") : openFile(afile.imageURL, afile.ext) } />
                                                <IonLabel className="action-title-two" onClick={() => ((afile.ext === "jpeg") || (afile.ext === "png")) ? openImage(afile.imageURL, "local") : openFile(afile.imageURL, afile.ext) }><h3>{afile.caption}</h3></IonLabel>
                                                <IonIcon icon={trashOutline}
                                                    slot="end"
                                                    color="danger"
                                                    onClick={() => deleteImage(i)} />
                                                <IonIcon icon={chevronForwardOutline}
                                                    slot="end"
                                                    className='ml-16'
                                                    color="dark"
                                                    onClick={() => ((afile.ext === "jpeg") || (afile.ext === "png")) ? openImage(afile.imageURL, "local") : openFile(afile.imageURL, afile.ext)} />

                                            </IonItem>
                                            
                                        </IonCardContent>
                                    </IonCard>

                                )}))}

                                {(fileUploads.length === 0) && (files.length === 0) && (
                                    <IonCard className='red-card'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start" 
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_files_uploaded")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>

                                )}
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
            {(rating === undefined) && (
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonButton onClick={handleReport}
                                className="first-button"
                                fill="clear"
                                > {t("submit")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
            )}
        </IonPage>
    );
}

export default TopicSubmission;
