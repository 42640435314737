import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, arrowUndoCircle, checkmarkCircle} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';

interface Due {
    _id: string,
    item: Item,
    qty: number,
    amount: number,
    createdAt: Date
}
interface Item {
    itemID: string,
    itemName: string
}
interface Transaction {
    _id: string,
    mode: string,
    amount: number,
    remarks: string,
    reversed?: boolean,
    validated?: boolean,
    dated: Date
}

interface ParamTypes {
    vendorID: string
}

const VendorLedger: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {vendorID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [transactions, setTransactions] = useState < Transaction[] > ([]);
    const [dues, setDues] = useState < Due[] > ([]);
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [duration, setDuration] = useState < string > ("three");
    const [due, setDue] = useState < number > (0);
    const [balance, setBalance] = useState < number > (0);
    const [name, setName] = useState < string > ("");
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [showAlert1, setShowAlert1] = useState < boolean > (false);
    const [tranID, setTranID] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleSubmit = () => {

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        if (toDOB === "" && (!duration || duration === "")) {
            setMessage(t("duration_mandatory"));
            setIserror(true);
            return;
        }

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/transactions/listVendor/${vendorID}`, {duration, toDOB, fromDOB});
                setTransactions(result.data.transactions);

                const res = await api.post(`/itemTransactions/listByVendor/${vendorID}`, {duration, toDOB, fromDOB});
                setDues(res.data.credits);
                
                setBalance(res.data.total - result.data.total);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
    }

    const handleReverse = () => {
        setShowLoading(true);
        api.get(`/transactions/reverse/${tranID}/${authInfo.user._id}`)
        .then(res => {
            if(res.data)
            {
                let subs = [...transactions];
                const index = subs.findIndex((val) => val._id === tranID);
                if (index > -1) subs[index] = res.data;
                setTransactions(subs);
            }
        })
        .catch((error: any) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    const handleValidate = () => {
        setShowLoading(true);
        api.put('/transactions/'+tranID, { validated: true })
        .then(res => {
            if(res.data)
            {
                let subs = [...transactions];
                const index = subs.findIndex((val) => val._id === tranID);
                if (index > -1) subs[index] = res.data;
                setTransactions(subs);
            }
        
        })
        .catch((error: any) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const stud = await api.get(`/vendors/${vendorID}`);
                setName(stud.data.name);
                setDue(stud.data.due);
                setPhone(stud.data.phone);

                const result = await api.post(`/transactions/listVendor/${vendorID}`, {duration: "three", toDOB: "", fromDOB: ""});
                setTransactions(result.data.transactions);

                const res = await api.post(`/itemTransactions/listByVendor/${vendorID}`, {duration: "three", toDOB: "", fromDOB: ""});
                setDues(res.data.credits);
                
                setBalance(res.data.total + result.data.total);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, vendorID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary" className='text-capitalize'>{name}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className='text-capitalize'>{name}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    isOpen={showAlert}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_reverse")}
                    buttons={[
                        {
                            text: t("no"),
                            role: 'cancel',
                            cssClass: 'secondary',
                        },
                        {
                            text: t("yes"),
                            handler: () => {
                                handleReverse();
                            }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={showAlert1}
                    cssClass="first-alert"
                    onDidDismiss={() => setShowAlert1(false)}
                    header={t("confirm")}
                    message={t("confirm_validate")}
                    buttons={[
                        {
                            text: t("no"),
                            role: 'cancel',
                            cssClass: 'secondary',
                        },
                        {
                            text: t("yes"),
                            handler: () => {
                                handleValidate();
                            }
                        }
                    ]}
                />

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-md">
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("due_duration")}
                                        </IonLabel>
                                        <IonSelect value={duration}
                                            placeholder={t("due_duration")}
                                            onIonChange={
                                                (e) => setDuration(e.detail.value)
                                            }>
                                            <IonSelectOption value="one">{t("last_month")}</IonSelectOption>
                                            <IonSelectOption value="three">{t("three")}</IonSelectOption>
                                            <IonSelectOption value="six">{t("six")}</IonSelectOption>
                                            <IonSelectOption value="twelve">{t("twelve")}</IonSelectOption> 
                                            <IonSelectOption value="current">{t("current_fy")}</IonSelectOption>
                                            <IonSelectOption value="last">{t("last_fy")}</IonSelectOption> 
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                      
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}
                                        id="open-from-input">
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("tran_from")}
                                        </IonLabel>
                                        <IonInput value={formatDate(fromDate)} readonly={true} />
                                        <IonModal trigger="open-from-input" showBackdrop={true} 
                                            initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                            <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                <IonToolbar>
                                                    <IonTitle>{t("tran_from")}</IonTitle>
                                                    
                                                </IonToolbar>
                                                <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("done")}
                                                    value={fromDate}
                                                    presentation="date"
                                                    onIonChange={ev => setFromDate(ev.detail.value!)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonContent>
                                            
                                        </IonModal>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}
                                        id="open-to-input">
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("tran_to")}
                                        </IonLabel>
                                        <IonInput value={formatDate(toDate)} readonly={true} />
                                        <IonModal trigger="open-to-input" showBackdrop={true} 
                                            initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                            <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                <IonToolbar>
                                                    <IonTitle>{t("tran_to")}</IonTitle>
                                                    
                                                </IonToolbar>
                                                <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("done")}
                                                    value={toDate}
                                                    presentation="date"
                                                    onIonChange={ev => setToDate(ev.detail.value!)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonContent>
                                            
                                        </IonModal>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                      
                     
                    </IonRow>
                </IonGrid>

                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_transactions")}
                            </IonButton>
                        </IonCol>
                        <IonCol size="6" className='mt-10'>
                           <span className="text-capitalize text-bold">{phone && (phone !== "") && (`${t("phone")} ${phone}`)}</span>
                        </IonCol>
                        
                    </IonRow>
                    <IonRow className=" mt-10">

                        <IonCol sizeLg="6" size="12" className='ion-padding'>
                            <IonRow>
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("credit")} ({t("payments")})
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <div className="ion-table mt-30">
                                <IonRow className="table-title ion-padding">
                                    <IonCol size="1" className='table-heading'>
                                        {t("s_no")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading'>
                                        {t("date")}
                                    </IonCol>
                                    <IonCol size="5" className='table-heading'>
                                        {t("remarks")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading ion-text-right'>
                                        {t("amount")}
                                    </IonCol>
                                </IonRow>

                                {(transactions.length > 0) ? (transactions.map((tran, i) => {
                                    return (
                                    <IonRow key={tran._id} className={(tran.validated) ? 'row-table-success ion-padding-horizontal' : (tran.reversed) ? 'row-table-danger ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                            
                                        <IonCol size="1" className='table-field action-button' >
                                            {i+1}.
                                        </IonCol>
                                        <IonCol size="3" className='table-field'>
                                            <IonRow>
                                                <IonCol>
                                                    {formatDated(tran.dated)}
                                                </IonCol>
                                            </IonRow>
                                               
                                        </IonCol>
                                        <IonCol size="5" className='table-field'>
                                            {tran.remarks}
                                        </IonCol>
                                        <IonCol size="3" className='table-field'>
                                            
                                            <IonRow className='ion-text-right'>
                                                <IonCol>
                                                {Math.abs(tran.amount)}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='mt-10'>
                                                <IonCol size="6">
                                                    {!tran.validated && !tran.reversed && (authInfo.user.role === "Admin" || authInfo.user.role === "Super") && (
                                                        <IonIcon 
                                                            icon={checkmarkCircle}
                                                            color="success"
                                                            className='table-icon'
                                                            onClick={
                                                                () => {
                                                                    setTranID(tran._id);
                                                                    setShowAlert1(true);
                                                                }
                                                            }
                                                        />
                                                    )}
                                                </IonCol>
                                                <IonCol size="6" className='ion-text-right'>
                                                    {!tran.validated && !tran.reversed && (
                                                        <IonIcon 
                                                            icon={arrowUndoCircle}
                                                            color="danger"
                                                            className='table-icon'
                                                            onClick={
                                                                () => {
                                                                    setTranID(tran._id);
                                                                    setShowAlert(true);
                                                                }
                                                            }
                                                        />
                                                    )}
                                                    
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                    )
                                })) : ( 
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_payments")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                            } 
                            </div>
                        </IonCol>

                        <IonCol sizeLg="6" size="12" className='ion-padding'>
                            <IonRow>
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("debit")} ({t("dues")})
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <div className="ion-table mt-30">
                                <IonRow className="table-title ion-padding">
                                    <IonCol size="1" className='table-heading'>
                                        {t("s_no")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading'>
                                        {t("date")}
                                    </IonCol>
                                    <IonCol size="5" className='table-heading'>
                                        {t("remarks")}
                                    </IonCol>
                                    <IonCol size="3" className='table-heading ion-text-right'>
                                        {t("amount")}
                                    </IonCol>
                                </IonRow>

                                {(dues.length > 0) ? (dues.map((due, i) => {
                                    return (
                                <IonRow key={due._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                    
                                    <IonCol size="1" className='table-field action-button' >
                                        {i+1}.
                                    </IonCol>
                                    <IonCol size="3" className='table-field'>
                                        {formatDated(due.createdAt)}
                                    </IonCol>
                                    <IonCol size="5" className='table-field'>
                                    {t("purchased")} {due.qty} x {due.item && (due.item.itemID !== "none") && (<Link to={`/app/item/ledger/${due.item.itemID}`} className='link-dark'>{due.item.itemName}</Link>)} 
                                    </IonCol>
                                    <IonCol size="3" className='table-field ion-text-right'>
                                        {due.amount}
                                    </IonCol>
                                </IonRow>
                                    )
                                })) : ( 
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_dues")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                            } 
                            </div>
                        </IonCol>

                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                    <span>{t("page_balance")} {balance}</span><span className="ion-margin-start">{t("total_outstanding")} {due}</span>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>
                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/vendors/payment/${vendorID}`)
                                            
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("make_payment")}
                                    </IonButton>

                                <IonButton 
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("export_excel")}
                                    </IonButton>

                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default VendorLedger;