import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, star, starOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import usePageUnloadHandler from '../../../components/usePageUnloadHandler';

interface Evaluation {
    _id: string,
    student: Student,
    topic: TopicObj,
    response?: string,
    rating?: number,
    score?: number,
    createdAt: Date
}

interface Student {
    studentID: string,
    studentName: string
}
interface TopicObj {
    topicID: string,
    topicName: string
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    courseID?: string
}

interface Chapter {
    _id: string,
    chapterName: string,
    isActive: boolean,
    order: number
}

interface Topic {
    _id: string,
    name: string,
    isActive: boolean,
    order?: number
}

interface ParamTypes {
    topicID?: string,
    classID?: string
}

const TopicSubmissions: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {classID, topicID} = useParams < ParamTypes > ();
    const [evaluations, setEvaluations] = useState < Evaluation[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [selectedTopic, setSelectedTopic] = useState <string> ("all");
    const [batches, setBatches] = useState < ClassInterface[] > ([]);
    const [batch, setBatch] = useState < string > ("");
    const [chapters, setChapters] = useState < Chapter[] > ([]);
    const [chapter, setChapter] = useState < string > ("");
    const [topics, setTopics] = useState < Topic[] > ([]);
    const [total, setTotal] = useState < number > (0);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    // Create an instance of axios with default settings
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if (elem) setScrollPosition(elem.scrollTop);
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleBatch = (classID : string) => {

        const fetchUp = async (courseID: string) => {
            setShowLoading(true);
            try {
                const result = await api.get('/courses/' + courseID);
                //sort chapters based on order
                let res = result.data.chapters.sort((a: Chapter, b: Chapter) => a.order! - b.order!);

                setChapters(res);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };


        if(!initialRender)
        {
            const theBatch = batches.find((val) => val._id === classID)!;

            if (theBatch.courseID)
            {
                fetchUp(theBatch.courseID);
            }
            else
            {
                setChapters([]);
            }
            
            setBatch(classID);
            setChapter("");
            setTopics([]);
            setSelectedTopic("all");
            setEvaluations([]);
            setTotal(0);
            
        }
    }

    const handleChapter = (chapter : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const tops = await api.get(`/topics/getByChapter/${chapter}`);
                setTopics(tops.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        if (chapter !== "")
        {
            setChapter(chapter);
            setSelectedTopic("all");
            setEvaluations([]);
            setTotal(0);
            fetchUp();
        }
    }

    const handleSubmit = () => {

        if(!chapter || chapter === "")
        {
            setMessage(t("topic_mandatory"));
            setIserror(true);
            return;
        }

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/evaluations/teacherByTopic/${authInfo.user._id}/${chapter}/${selectedTopic}`);
                setEvaluations(result.data.evaluations);
                setTotal(result.data.total);
                setShowLoading(false);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
    }


    const loadItems = async () => {

        if(!chapter || chapter === "")
        {
            setMessage(t("topic_mandatory"));
            setIserror(true);
            return;
        }

        let limit = skip+30;

        try {
            const result = await api.get(`/evaluations/teacherByTopic/${authInfo.user._id}/${chapter}/${selectedTopic}?skip=${limit}`);
            if(result.data.evaluations && result.data.evaluations.length)
            {
                setEvaluations([...evaluations, ...result.data.evaluations]);
                setSkip(limit);
            }
            

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        } 
        
    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            // Data is present in local storage, use it to initialize state
            const { evaluations, total, batches, chapters, topics, batch, chapter, selectedTopic, skip, scrollPosition } = JSON.parse(storedState);
            setEvaluations(evaluations);
            setTotal(total);
            setBatches(batches);
            setChapters(chapters);
            setTopics(topics);
            setBatch(batch);
            setChapter(chapter);
            setSelectedTopic(selectedTopic);
            setSkip(skip);
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
        } 
        else 
        {

            const fetchUp = async () => {
                setShowLoading(true);
                try {

                    const teacher = await api.get('/classes/getByTeacher/' + authInfo.user._id);

                    setBatches(teacher.data);
                    if (classID)
                    {
                        setBatch(classID);
                    }
    
                    if(topicID)
                    {
                        const top = await api.get(`/topics/${topicID}`);

                        const result = await api.get('/courses/' + top.data.courseID);
                        //sort chapters based on order
                        let resu = result.data.chapters.sort((a: Chapter, b: Chapter) => a.order! - b.order!);

                        setChapters(resu);
                        setChapter(top.data.chapterInfo.chapterID);
                        
                        const tops = await api.get(`/topics/getByChapter/${top.data.chapterInfo.chapterID}`);
                        setTopics(tops.data);
                        setSelectedTopic(topicID);

                        const res = await api.get(`/evaluations/teacherByTopic/${authInfo.user._id}/${top.data.chapterInfo.chapterID}/${topicID}`);
                        setEvaluations(res.data.evaluations);
                        setTotal(res.data.total);
                        
                    }

                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
       
        setInitialRender(false);

    }, [authInfo, classID, topicID, refreshed, history.action, history.location.pathname]);

     // Save state to local storage whenever it changes
     useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            evaluations,
            total,
            batches,
            topics,
            chapters,
            batch,
            chapter,
            selectedTopic,
            skip,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [evaluations, total, batches, topics, chapters, skip, selectedTopic, batch, chapter, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/planner`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_topic_wise")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_topic_wise")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
              
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-md">
      
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("select_batch")}</IonLabel>
                                            <IonSelect value={batch}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_batch")}
                                                    onIonChange={
                                                        (e) => handleBatch(e.detail.value)
                                                }>

                                                    {batches.map((classy) => (
                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name} ({classy.year})</IonSelectOption>)
                                                    ))}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("select_chapter")}</IonLabel>
                                            <IonSelect value={chapter}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_chapter")}
                                                    onIonChange={
                                                        (e) => handleChapter(e.detail.value)
                                                }>
                                                     
                                                     {chapters.map((chapter) => 
                                                        (chapter.isActive) && 
                                                        (<IonSelectOption key={chapter._id} value={`${chapter._id}`}>{chapter.chapterName}</IonSelectOption>)
                                                    )}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">                   
                                    
                                    <IonItem
                                    detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_topic")}</IonLabel>
                                        <IonSelect value={selectedTopic}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    className="input-field"
                                                    placeholder={t("select_topic")}
                                                    onIonChange={
                                                        (e) => e.detail.value && setSelectedTopic(e.detail.value)
                                                }>
                                                     <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                {topics.map((topic) => 
                                                    (topic.isActive) && 
                                                    (<IonSelectOption key={topic._id} value={`${topic._id}`} >{topic.name}</IonSelectOption>)
                                                )}
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonGrid className="ion-no-padding mb-60 mt-10">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_submissions")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">
                            <IonCol sizeLg="3" size="6" className='table-heading'>
                                
                              {t("name")}
                            </IonCol>
                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                                {t("topic_name")}
                            </IonCol>

                            <IonCol size="3" className='table-heading'>
                            {t("date")}
                            </IonCol>

                            <IonCol size="3" className='table-heading'>
                            {t("rating")}
                            </IonCol>
                        </IonRow>

                            {(evaluations.length > 0) ? (evaluations.map((evaluation, i) => {
                                return (
                                    <IonRow key={evaluation._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                   
                                    onClick={
                                        () => (evaluation.score !== undefined) ? history.push(`/app/assessment/${evaluation._id}`) : history.push(`/app/submission/${evaluation._id}`)
                                    }>
                                        
                                        <IonCol sizeLg="3" size="6" className='table-field' >
                                                <IonRow>
                                                    <IonCol>
                                                        
                                                        <IonText className='text-capitalize'>{evaluation.student.studentName}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field-1 text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel>
                                                        {evaluation.topic.topicName}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>

                                            </IonCol>
                                            <IonCol sizeLg="3" size="0" className='table-field ion-hide-lg-down'>
                                                {evaluation.topic.topicName}
                                            </IonCol>
                                            <IonCol size="3" className='table-field'>
                                                {formatDate(evaluation.createdAt)}
                                            </IonCol>
                                            <IonCol size="3" className='table-field'>
                                                {(evaluation.score !== undefined) && `${evaluation.score} %`}
                                                {(evaluation.score === undefined) && !evaluation.rating && (
                                                    <>
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium"
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 1) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium"
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 2) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark"
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 3) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark"
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 4) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark"
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 5) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark"
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                    </>
                                                )}
                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_evaluations")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                    </div>

                    {(evaluations.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                        <IonRow>
                            <IonCol>
                                <IonRow>
                                    <IonCol className="ion-padding-start footer-stats">
                                {t("showing")} {total === 0 ? t("no_records") : `1 - ${evaluations.length} ${t("of")} ${total}`} 
                                    </IonCol>
                                </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>
                                   
                                    <IonButton 
                                    onClick={
                                        () => history.push(`/app/teacher/submissions/student`)
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("view_student_wise")}</IonButton>
                           
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    </IonRow>
                        
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default TopicSubmissions;