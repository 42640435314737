import {
    IonAlert,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonPopover,
    IonProgressBar,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import { alertCircleOutline, busOutline, calendar, calendarOutline, chatbubbles, close, chevronForwardOutline, documentAttach, logInOutline, medical, newspaperOutline, notifications, sparkles, time, camera, trash, createOutline, documentText } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { SendIntent } from "send-intent";
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { InAppBrowser, InAppBrowserObject, InAppBrowserOptions } from '@ionic-native/in-app-browser';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import { Anchorme } from 'react-anchorme';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperInterface } from 'swiper';
import { useAuth } from "../../components/AuthContext";
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import SwitchStudent from '../../components/SwitchStudent';

import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

interface Student {
    _id: string,
    classID: string,
    classInfo?: ClassInfo,
    name: string,
    photo?: string,
    admnNo?: string,
    due: number
}

interface ClassInfo {
    className: string,
    session: string,
}

interface Trip {
    _id: string,
    name: string,
    type: string,
    scheduledAt: Date,
    finishedAt: Date,
    startedAt: Date
}

interface Circular {
    _id: string,
    title: string,
    text?: string,
    filename?: string,
    createdAt: Date,
    isActive: boolean
}

interface Attendance {
    _id: string,
    status: string,
    createdAt: Date
}

interface Attend {
    _id: string,
    status: string,
    dated: Date
}

interface Event {
    _id: string,
    title: string,
    description: string,
    media?: string,
    calendarDate: Date
}

interface Inquiry {
    _id: string,
    title: string,
    type: string,
    status: string,
    messages: Message[],
    createdAt: Date
}

interface Message {
    timestamp: Date
}

interface ClassDetail {
    _id: string,
    name: string,
    description?: string,
    isActive: boolean,
    createdAt: Date
}

interface Route {
    _id: string,
    name: string,
    type: string,
    startTime: string,
    driver: DriverObject,
    vehicle?: VehicleObject
}

interface DriverObject {
    driverID: string,
    name: string,
    phone?: string
}

interface VehicleObject {
    vehicleID: string,
    name: string,
    plate: string
}

interface Topic {
    _id: string,
    name: string,
    isActive: boolean,
    order?: number,
    questions?: Question[],
}
interface Question {
    _id: string,
    title: string,
    isActive: boolean,
    answer?: number,
    order?: number
}


const KTWSinstitutes = ["623b78f1bbe7171e30738c7a", "623b7220bbe7171e30738a7c", "623b7808bbe7171e30738c74", "623b784fbbe7171e30738c77"];

const ParentHome: React.FC = () => {
    const {authInfo, logOut} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [isexpired, setIsexpired] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [isold, setIsold] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [iserror1, setIserror1] = useState < boolean > (false);
    const [message1, setMessage1] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [student, setStudent] = useState < Student > ();
    const [ongoingTrip, setOngoingTrip] = useState < Trip > ();
    const [studentDoneAt, setStudentDoneAt] = useState < Date > ();
    const [studentStatus, setStudentStatus] = useState < string > ("");
    const [completedDoneAt, setCompletedDoneAt] = useState < Date > ();
    const [completedStatus, setCompletedStatus] = useState < string > ("");
    const [completedTrip, setCompletedTrip] = useState < Trip > ();
    const [events, setEvents] = useState < Event[] > ([]);
    const [circulars, setCirculars]= useState < Circular[] > ([]);
    const [convo, setConvo]= useState < Inquiry > ();
    const [attendance, setAttendance]= useState < Attendance > ();
    const [attend, setAttend]= useState < Attend > ();
    const [refreshed, setRefreshed] = useState < number > (0);
    const [nothing, setNothing] = useState < number > (0);
    const [payment, setPayment] = useState < boolean > (false);
    const [transport, setTransport] = useState < boolean > (false);
    const [unread, setUnread] = useState < number > (0);
    const [isInactive, setIsInactive] = useState < boolean > (false);
    const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
    const [totalCirculars, setTotalCirculars] = useState < number > (0);
    const [present, setPresent] = useState < number > (0);
    const [absent, setAbsent] = useState < number > (0);
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [showModal, setShowModal] = useState < boolean > (false);
    const [classDetail, setClassDetails] = useState < ClassDetail > ();
    const [showClass, setShowClass] = useState < boolean > (false);
    const [inRoutes, setInRoutes] = useState < Route[] > ([]);
    const [showRoute, setShowRoute] = useState < boolean > (false);
    const [imageURL, setImageURL] = useState < string > ("");
    const [topics, setTopics] = useState < Topic[] > ([]);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            Authorization: 'Bearer ' + authInfo.token
        }
    });

    const options: InAppBrowserOptions = {
        location: 'no',
        hidden: 'no',
        zoom: 'yes',
        toolbar: 'yes',
        hidenavigationbuttons: 'yes',
        toolbarposition: 'bottom',
        closebuttoncaption: 'Close'
    };

    const openURL = (value: string) => {

        let browser : InAppBrowserObject;

        // _blank for iphone, _self for others
        if (isPlatform('capacitor') && isPlatform('ios'))
        {
            browser = InAppBrowser.create(String(value), '_blank', options);
        }
        else
        {
            browser = InAppBrowser.create(String(value), '_self', options);
        }
    };

    const options1 = {
        colors : ['#333333', '#DDDDDD'],
        labels: [t("present"), t("absent")],
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
          },
        stroke: {
            show: false
          },
          plotOptions: {
            pie: {
                donut: {
                    labels: {
                      show: false
                    }},
              expandOnClick: false,
              
            }
          }
    };
    
    const handleNext = () => swiperInstance?.slideNext();
    const handlePrev = () => swiperInstance?.slidePrev();

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const getPercentage = () => {
        let per = 0;
        if (present !== 0 || absent !== 0)
        {
            per = Math.round((present/(present+absent))*100);
        }
        return per+"%";
    };

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };
      
    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const pickImage = async () => {
         try {

            const result = await FilePicker.pickImages({limit: 1});

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        setImageURL(webPath);
                    }
                    else
                    {
                        setImageURL("");
                    }
                    const blob = await fetch(webPath).then((r) => r.blob());
                    const rawFile = new File([blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    const rawFile = new File([file.blob], file.name, {
                        type: file.mimeType,
                    });
                    setUploadFile(rawFile);
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const base64 = await fileToDataUri(rawFile);
                        setImageURL(base64);
                    }
                    else
                    {
                        setImageURL("");
                    }
                }
            }
            
        } 
        catch(error: any) {
 
            setMessage(t("no_files_selected"));  
            setIserror(true);
        }
    };

    const handlePhoto = () => {

        if (student)
        {
            if (!uploadFile) {
                setMessage(t("logo_format"));
                setIserror(true);
                return;
            }
    
            const upStud = ( formData: FormData) => {
                return new Promise((resolve, reject) => {
                  api.post('/students/updatePhoto', formData).then(res => {
            
                    return resolve(res.data.student.name);
                    
                  }).catch(err => reject(err));
        
                });
              }
    
          // Change uploadFile to blob in native implementation using capacitor plugin
          let formData = new FormData();
          formData.append('image', uploadFile, uploadFile.name);
          formData.append('studentID', student._id);
      
          setShowLoading(true);
            upStud(formData)
            .then(data => {
            
                setPass(data+t("has_been_updated"));
                setIspass(true);
                setShowLoading(false);
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("try_again"));
    
                setIserror(true);
                setShowLoading(false);
            });
        }
    }

    const handleCustody = () => {

        const entry = (studentID: string) => {
            return new Promise((resolve, reject) => {
              api.get('/attendances/custody/' + studentID).then(res => {
        
                return resolve(res.data.attendance);
                
              }).catch(err => reject(err));
    
            });
          }

        if (student)
        {
            setShowLoading(true);
            entry(student._id)
            .then((data: any) => {
                setShowLoading(false);
                setAttendance(data);
                setShowModal(true);
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
        
                setIserror(true);
                setShowLoading(false);
            });
        }

    }
   
    useEffect(() => {
    
        const upUser = async (push: Token) => {
            try {
                const response = await api.put(`/users/${authInfo.user._id}`, { push: push.value });
                return response.data;
            } catch (err) {
                throw err;
            }
        };

        const registerPushNotifications = async () => {
            try {
                // Register with Apple / Google to receive push notifications
                await PushNotifications.register();
    
                // On success, we should be able to receive notifications
                await PushNotifications.addListener('registration', async (token: Token) => {
                    if (!authInfo.user.push || authInfo.user.push !== token.value) {
                        await upUser(token);
                    }
                });
    
                // Handle registration errors
                await PushNotifications.addListener('registrationError', () => {
                    setMessage(t("push_error"));
                    setIserror(true);
                });
            } catch (err) {
                console.error('Error in register function:', err);
            }
        };
    
      
        const fetchData = async () => {
            try {
    
                setPayment(authInfo.institute.payment);
                setTransport(authInfo.institute.transport);
    
                const studentRes = await api.get(`/students/getByParent/${authInfo.user._id}`);
                const studentData = studentRes.data;
    
                if (studentData) {
                    setStudent(studentData);
    
                    const [classRes, inRoutesRes, ongoingTripRes, completedTripRes, circularRes, attendanceRes, attendRes, convoRes, tasksRes, eventsRes] = await Promise.all([
                        api.get(`/classes/${studentData.classID}`),
                        api.get(`/routes/student/in/${studentData._id}`),
                        api.get(`/trips/student/ongoing/${studentData._id}`),
                        api.get(`/trips/student/completed/home/${studentData._id}`),
                        api.get(`/circulars/getOneByClass/${studentData.classID}`),
                        api.get(`/attendances/getOneByStudent/${studentData._id}`),
                        api.get(`/attends/getOneByStudent/${studentData._id}`),
                        api.get(`/inquiries/getOneByStudent/${studentData._id}`),
                        api.get(`/students/getPendingTasks/${studentData._id}`),
                        api.get(`/feeds/getEventsByInstitute/${authInfo.user.instituteID}`)
                    ]);
    
                    setClassDetails(classRes.data);
                    setInRoutes(inRoutesRes.data);
                    setOngoingTrip(ongoingTripRes.data);
                    if (ongoingTripRes.data) {
                        const studentInfo = ongoingTripRes.data.students.find((student: any) => student.studentID === studentData._id);
                        
                        if (studentInfo) {
                            setStudentStatus(studentInfo.status);
                            if (studentInfo.status !== 'upcoming') {
                                setStudentDoneAt(studentInfo.doneAt);
                            }
                        }
                    }
                    setCompletedTrip(completedTripRes.data);
                    if (completedTripRes.data) {
                        const studentInfo = completedTripRes.data.students.find((student: any) => student.studentID === studentData._id);
                        
                        if (studentInfo) {
                            setCompletedStatus(studentInfo.status);
                            if (studentInfo.status !== 'upcoming') {
                                setCompletedDoneAt(studentInfo.doneAt);
                            }
                        }
                    }
                    setCirculars(circularRes.data);
                    setAttendance(attendanceRes.data);
                    setAttend(attendRes.data);
                    setConvo(convoRes.data);
                    setTopics(tasksRes.data);
                    setEvents(eventsRes.data);
    
                    // Check if nothing is available
                    if (!ongoingTripRes.data && !completedTripRes.data && !attendRes.data && !attendanceRes.data && !convoRes.data && !circularRes.data.length && !eventsRes.data.length && studentData.due === 0) {
                        setNothing(1);
                    }
    
                    const [countRes, attStatRes, circularCountRes] = await Promise.all([
                        api.get(`/notifications/getCountUser/${authInfo.user._id}`),
                        api.get(`/attends/getStatByStudent/${studentData._id}`),
                        api.get(`/circulars/getCountByClass/${studentData.classID}`)
                    ]);
    
                    setUnread(countRes.data);
                    setPresent(attStatRes.data.present);
                    setAbsent(attStatRes.data.absent);
                    setTotalCirculars(circularCountRes.data.circulars);
    
                } else {
                    setIsInactive(true);
                }
            } catch (error: any) {
                handleApiError(error);
            }
        };
    
        const checkIntent = async () => {
            try {
                const result = await SendIntent.checkSendIntentReceived();
                if (result) {
                    setMessage1(t("intent_error"));
                    setIserror1(true);
                }
            } catch (err) {
                console.error('Error checking intent:', err);
            }
        };
    
        const fetchVersion = async () => {
            setShowDownloading(true);
            try {
                const versionRes = await api.get(`/version-check`);
                const currentVersion = parseInt(process.env.REACT_APP_VERSION!);
    
                if (parseInt(versionRes.data) > currentVersion) {
                    setIsold(true);
                } else {

                    if (isPlatform('android') && isPlatform('capacitor')) {
                        await checkIntent();
                    }

                    await fetchData();
    
                    if (isPlatform('capacitor')) {
                        const permissionRes = await PushNotifications.checkPermissions();
                        if (permissionRes.receive !== 'granted') {
                            const requestRes = await PushNotifications.requestPermissions();
                            if (requestRes.receive === 'granted') {
                                await registerPushNotifications();
                            }
                        } else {
                            await registerPushNotifications();
                        }
                    }
                }
            } catch (error: any) {
                handleApiError(error);
            } finally {
                setShowDownloading(false);
            }
        };
    
        const handleApiError = (error: any) => {
            if (error.response) {
                if (error.response.data.message === 'expired') {
                    setIsexpired(true);
                } else {
                    setMessage(error.response.data.message);
                    setIserror(true);
                }
            } else {
                setMessage(t("unable_to_connect"));
                setIserror(true);
            }
        };
    
        fetchVersion();
        
    }, [authInfo, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    <IonButtons slot="end">
                        {!isPlatform('mobile') && (
                        <>
                            <ThemeToggle />
                            <LanguageToggle />
                        </>
                        )}
                         <IonButton onClick={() => history.push('/app/user/notification')} fill="clear" className='ion-no-padding badge-button'> 
                            <IonIcon icon={notifications} color="secondary" className="badge-icon" />
                            {(unread !== 0) && (<span className="badge-label">{unread}</span>)}
                        </IonButton>
                    </IonButtons>
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isexpired}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/login");
                    }}
                    header={t("session_expired")}
                    message={t("login_again")}
                    buttons={[`${t("logout")}`]}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={iserror1}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => SendIntent.finish()
                    }
                    header={t("error")}
                    message={message1}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isold}
                    backdropDismiss={false}
                    onDidDismiss={
                        () => document.location.href = isPlatform('android') ? process.env.REACT_APP_PLAY_STORE! : process.env.REACT_APP_APP_STORE!
                    }
                    header={t("outdated_version")}
                    message={t("outdated_msg")}
                    buttons={
                        [`${t("update_app")}`]
                    }
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isInactive}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/home");
                    }}
                    header={t("inactive_account")}
                    message={t("inactive_msg")}
                    buttons={[`${t("logout")}`]}
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonModal isOpen={showModal}
                    onDidDismiss={
                        () => {
                            setShowModal(false);
                            setRefreshed(Math.random());
                        }
                }>

                    <IonContent className="grey-shade">
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="end">
                                    <IonButton onClick={
                                        () => {
                                            setShowModal(false);
                                            setRefreshed(Math.random());
                                        }
                                    }>
                                        <IonIcon icon={close} size="large" />
                                    </IonButton>
                                </IonButtons>
                                <IonTitle color="secondary">{t("pickup_slip")}</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonGrid>
                            <IonRow className="mt-60">
                                <IonCol className="ion-text-center">
                                    <div className="vertical-container">
                                        <div className="vertical-center"> 
                                            {student?.photo && (student?.photo !== "") ?
                                                (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${student?.photo}`} alt={t("profile_pic")} width="70" />)
                                                : (<img src={`assets/images/avatar.png`} alt={t("profile_pic")} width="70" />)
                                            }
                                        </div>
                                    </div>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonCard className="note-card-5">
                                        <IonCardContent>
                                             <IonRow className='text-bold font-color-3'>
                                                <IonCol size="4">
                                                    {t("date")}
                                                </IonCol>
                                                <IonCol size="8">
                                                    {attendance?.createdAt && formatDateTime(attendance.createdAt)}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="4">
                                                    {t("slip_no")}
                                                </IonCol>
                                                <IonCol size="8">
                                                    {attendance?._id.slice(-6)}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='text-bold font-color-5 mt-30'>
                                                <IonCol>
                                                    {t("received_child")} - 
                                                </IonCol>
                                            </IonRow> 
                                
                                            <IonRow>
                                                <IonCol size="4">
                                                    {t("search_student")}
                                                </IonCol>
                                                <IonCol size="8" className='text-capitalize'>
                                                    {student?.name}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="4">
                                                    {t("class_name")}
                                                </IonCol>
                                                <IonCol size="8">
                                                    {student?.classInfo?.className}
                                                </IonCol>
                                            </IonRow>

                                        </IonCardContent>

                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonContent>
                </IonModal>

                <IonModal isOpen={showClass}
                    onDidDismiss={
                        () => {
                            setShowClass(false);
                        }
                }>

                    <IonContent className="grey-shade">
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="end">
                                    <IonButton onClick={
                                        () => {
                                            setShowClass(false);
                                        }
                                    }>
                                        <IonIcon icon={close} size="large" />
                                    </IonButton>
                                </IonButtons>
                                <IonTitle color="secondary"> {classDetail && classDetail.name}</IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <IonCard className="mt-60">
                            <IonCardContent>

                                <IonRow>
                                    <IonCol size="11" offset="1" className="ion-padding announcement-card">
                                    
                                        {classDetail && classDetail.description && (<Anchorme>{classDetail.description}</Anchorme>)}
                                            
                                    </IonCol>
                                </IonRow>

                            </IonCardContent>

                        </IonCard>
                       
                    </IonContent>
                </IonModal>

                <IonModal isOpen={showRoute}
                    onDidDismiss={
                        () => {
                            setShowRoute(false);
                        }
                }>

                    <IonContent className="grey-shade">
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="end">
                                    <IonButton onClick={
                                        () => {
                                            setShowRoute(false);
                                        }
                                    }>
                                        <IonIcon icon={close} size="large" />
                                    </IonButton>
                                </IonButtons>
                                <IonTitle color="secondary">{t("route_details")}</IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <IonCard className="mt-60">
                            <IonCardContent>
                                {(inRoutes.length > 0) && (inRoutes.map((rout) => 
                                (
                                <IonRow key={rout._id}>
                                    <IonCol>
                                        <IonCard className={(rout.type === 'pickup') ? "action-card-c" : "action-card-b"} >
                                            <IonCardContent>

                                                    <IonRow>
                                                        <IonCol size="3">
                                                            <IonIcon className='first-icon'
                                                                icon={busOutline}
                                                                color={(rout.type === 'pickup') ? 'primary' : 'secondary'}/>
                                                            </IonCol>
                                                            <IonCol size="9" className="ion-text-right">
                                                                <IonText className='driver-route-text'>{rout.name}<br/>
                                                                {t("start_time")}: {(rout.startTime)}
                                                                </IonText>
                                                            </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="4">
                                                            {t("driver")}
                                                        </IonCol>
                                                        <IonCol size="8" className='text-capitalize'>
                                                            {rout.driver.name}
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="4">
                                                            {t("phone")}
                                                        </IonCol>
                                                        <IonCol size="8">
                                                            {rout.driver.phone}
                                                        </IonCol>
                                                    </IonRow>
                                                    {rout.vehicle && (
                                                    <IonRow>
                                                        <IonCol size="4">
                                                            {t("vehicle")}
                                                        </IonCol>
                                                        <IonCol size="8">
                                                            {rout.vehicle.plate}
                                                        </IonCol>
                                                    </IonRow>
                                                    )}

                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                                ))}
                            </IonCardContent>

                        </IonCard>
                       
                    </IonContent>
                </IonModal>

                <SwitchStudent refreshed={refreshed} setRefreshed={setRefreshed} />

                <IonGrid className='mb-60'>

                    <IonRow className="ion-padding ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol size="6">
                                    <IonRow>
                                        <IonCol className="ion-text-center" id="open-upload-input" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                            <div className="vertical-container">
                                                <div className="vertical-center"> 
                                                    {student && student.photo && (student.photo !== "") ?
                                                        (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${student.photo}`} alt={t("profile_pic")} width="70" />)
                                                        : (<img src={`assets/images/avatar.png`} alt={t("profile_pic")} width="70" />)
                                                    }
                                                </div>
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                    <IonPopover trigger="open-upload-input" size="auto">
                                        <IonRow>
                                            <IonCol>
                                                <IonCard>
                                                    <IonCardContent className="ion-no-padding">
                                                        <IonItem detail={true}
                                                            lines='none'
                                                            button={true}
                                                            color="primary"
                                                            onClick={pickImage}
                                                            >
                                                            <IonIcon
                                                                slot="start"
                                                                icon={camera}
                                                            />
                                                            <IonLabel>
                                                            {(uploadFile) ? t("change_photo") : t("upload_photo")}
                                                            </IonLabel>
                                                            
                                                        </IonItem>
                                                        {(uploadFile) && (
                                                        <IonItem detail={true}
                                                                detailIcon={trash}
                                                                lines='none'
                                                                button={true}
                                                                onClick={() => {
                                                                    setUploadFile(null);
                                                                    setImageURL("");
                                                                }}
                                                            >
                                                            <IonBadge color="danger" slot="start">1</IonBadge>
                                                            <IonLabel>
                                                                {t("remove_files")}
                                                            </IonLabel>
                                                            
                                                        </IonItem>
                                                        )}
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                        {imageURL && (
                                        <IonRow>
                                            <IonCol>
                                                <IonCard className="ion-text-center">
                                                    <IonCardHeader>
                                                        <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                                    </IonCardHeader>
                                                    <IonCardContent>
                                                    
                                                        <img src={imageURL} 
                                                            alt={t("image_preview")}
                                                            width="70" />

                                                    </IonCardContent>        
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                        )}
                                        <IonRow>
                                            <IonCol className="ion-text-center">
                                                <IonButton onClick={handlePhoto}
                                                size="small"
                                                    color="secondary">{t("update_photo")}</IonButton>

                                            </IonCol>
                                        </IonRow>
                                    </IonPopover>
                                    <IonRow>
                                        <IonCol>
                                            <IonButton onClick={ () => history.push(`/app/user/profile/student`)}
                                            expand="block" color="secondary">{t("view_profile")}</IonButton>

                                            {(KTWSinstitutes.includes(authInfo.institute._id)) && student && student.admnNo && (student.admnNo !== "") && 
                                                (<IonButton onClick={() => openURL(`https://softwareportal.gen.in/teacher/printidcard4app.php?id=${student.admnNo}`)}
                                                    expand="block" color="tertiary">{t("guardian_card")}
                                                </IonButton>
                                            )}

                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                                {/** 
                                ((present !== 0) || (absent !== 0)) && (
                                <IonCol size="6" className='mt-05'>
                                    <IonCard className="stat-card-3 ion-no-margin">
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>
                                                    <Chart options={options1} series={[present,absent]} type="donut" width={120} />
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="3">
                                                    <IonIcon 
                                                            icon={calendarOutline}
                                                            className='stat-icon-1'
                                                        />
                                                </IonCol>
                                                <IonCol size="9">
                                                        
                                                    <IonText className='stat-head-1'>{getPercentage()}</IonText>
                                                    <p className='stat-subhead-1'>{t("attendance")}</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                )
                                
                                */}
                                 
                                <IonCol size="6">
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="stat-card-6 ion-no-margin">
                                                <IonCardContent>
                                                    <IonRow>
                                                        <IonCol size="3">
                                                                <IonIcon 
                                                                     icon={logInOutline}
                                                                    className='stat-icon'
                                                                />
                                                        </IonCol>
                                                        <IonCol size="9">
                                                                <IonText className='stat-head'>{formatDate(authInfo.user.updatedAt)}</IonText>
                                                                <p className='stat-subhead'>{t("last_login")}</p>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                   
                                    
                                    <IonRow>
                                        <IonCol>
                                            {(classDetail && classDetail.description && classDetail.description !== "") && (
                                                <IonButton onClick={() => setShowClass(true)}
                                                expand="block" color="dark">{(authInfo.institute.type === "school") ? t("class_details") : t("batch_details")}</IonButton>
                                            )}
                                            {(inRoutes.length > 0) && (
                                                <IonButton onClick={() => setShowRoute(true)}
                                                expand="block" color="primary">{t("route_details")}</IonButton>
                                            )}
                                        </IonCol>
                                    </IonRow>
                                    

                                   
                                </IonCol>

                            </IonRow>
                        </IonCol>
                        
                    </IonRow>

                {(nothing === 1) && (
                <IonRow className="ion-margin">
                    <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        <IonCard className='red-card'>
                            <IonCardContent>
                                <IonItem lines="none">
                                    <IonIcon icon={alertCircleOutline}
                                        slot="start"
                                        color="danger"
                                        size="large" />
                                    <IonLabel className="list-title ion-text-wrap"><h3>{t("no_updates")}</h3></IonLabel>

                                </IonItem>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                )}

                {ongoingTrip && student && (
                <>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className="title-heading">
                                {t("ongoing_trip")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin" offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className="ongoing-card" routerLink={`/app/trip/ongoing/${ongoingTrip._id}/${student._id}`}>
                                <IonCardContent className="ion-no-padding ion-no-margin">

                                        <IonRow className="ion-padding">
                                            <IonCol size="2" >
                                                <IonIcon className='second-icon'
                                                    icon={busOutline}
                                                    color={(ongoingTrip.type === 'drop') ? 'secondary' : 'primary'}
                                                />
                                            </IonCol>
                                            <IonCol size="9">
                                                <IonText className='action-text'>{ongoingTrip.name}<br/>
                                                    {t("started_at")} {formatDateTime(ongoingTrip.startedAt)} 
                                                        
                                                </IonText>
                                            </IonCol>
                                            <IonCol size="1" >
                                                            
                                                <IonIcon className='second-icon'
                                                    icon={chevronForwardOutline}
                                                    color='dark'/>
                                                
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="border-top ion-padding">
                                            <IonCol className='ion-text-center'>
                                                <IonText className='action-text-4'>
                                                    {studentStatus && studentStatus === 'upcoming' && ongoingTrip.type === 'drop' && (t("drop_soon"))}
                                                    {studentStatus && studentStatus === 'upcoming' && ongoingTrip.type === 'pickup' && (t("pickup_soon"))}
                                                    {studentStatus && studentStatus !== 'upcoming' && studentDoneAt && (` ${t("child_marked")} ${studentStatus} at ${formatDateTime(studentDoneAt)}`)}
                                                            
                                                </IonText>
                                            </IonCol>
                                        </IonRow>

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </>
                )}

                {topics && (topics.length > 0) && (
                    <>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className="title-heading">
                                {t("pending_tasks")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-margin ion-no-padding">
                                <IonList>
                                    {topics.map((topic, i) => {
                                        return (
                                            <IonItem detail={false}
                                                lines={(i === (topics.length-1)) ? 'none' : 'inset'}
                                                button={false}
                                                key={topic._id}
                                                onClick={() => (topic.questions && (topic.questions.length > 0) && topic.questions.some((q) => (q.isActive && (q.answer !== undefined)))) ? history.push(`/app/assessments/topic/${topic._id}`) : history.push(`/app/submissions/topic/${topic._id}`)}
                                            >
                                                    
                                                <IonIcon
                                                    icon={createOutline}
                                                    color="success"
                                                    slot="start"
                                                />
                                                    
                                                <IonLabel>{topic.name}</IonLabel>
                                                <IonButtons slot="end">
                                                    <IonIcon
                                                        className='tran-icon'
                                                        icon={documentText} 
                                                        color="primary"
                                                    />
                                                </IonButtons>
                                                    
                                            </IonItem>
                                        )
                                        
                                        })
                                    }
                                    </IonList>
               
                                </IonCardContent>
                            </IonCard>
                         </IonCol>
                    </IonRow>
                    </>
                )}
                   
                {student && (attendance || attend) && ( 
                    <>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonTitle className="title-heading">
                                {t("attendance_status")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    {(attendance) && (
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    
                            <IonCard className={
                                    ((attendance.status === 'in') || (attendance.status === 'entry') || (attendance.status === 'reached')) ? 'success-card' : ((attendance.status === 'picked') || (attendance.status === 'left')) ? 'warning-card' : (attendance.status === 'custody') ? 'tertiary-card' : 'danger-card'
                                }
                                onClick={() => (attendance.status === 'custody') && setShowModal(true)}
                                >
                                <IonCardContent className='ion-no-padding ion-padding-vertical'>
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}
                                            
                                            >
                                            <IonIcon slot="start"
                                                icon={time}
                                                color={
                                                    ((attendance.status === 'in') || (attendance.status === 'entry') || (attendance.status === 'reached')) ? 'success' : ((attendance.status === 'picked') || (attendance.status === 'left')) ? 'warning' : (attendance.status === 'custody') ? 'tertiary' : 'danger'
                                                }
                                                />
                                                <IonLabel className="recent-label ion-text-wrap">
                                                        
                                                        {(attendance.status === 'out') && (
                                                            <>
                                                                <h3>{student.name} {(authInfo.institute.type === "school") ? t("left_school_on") : t("left_insti_on")} </h3>
                                                                <p>on {formatDateTime(attendance.createdAt)}</p>
                                                            </>
                                                        )}

                                                        {(attendance.status === 'in') && (
                                                            <>
                                                                <h3>{student.name} {(authInfo.institute.type === "school") ? t("entered_school_on") : t("entered_insti_on")} </h3>
                                                                <p>on {formatDateTime(attendance.createdAt)}</p>
                                                            </>
                                                        )}

                                                        {(attendance.status === 'entry') && (
                                                            <>
                                                                <h3>{student.name} {(authInfo.institute.type === "school") ? t("entered_school_on") : t("entered_insti_on")} </h3>
                                                                <p>on {formatDate(attendance.createdAt)}</p>
                                                            </>
                                                        )}
                                                        
                                                         {(attendance.status === 'custody') && (
                                                            <>
                                                                <h3>{student.name} {t("custody_with_parent")} </h3>
                                                                <p>on {formatDateTime(attendance.createdAt)}</p>
                                                            </>
                                                        )}
                                                        {(attendance.status === 'picked') && (
                                                            <>
                                                                <h3>{student.name} {t("picked_by_transport")} </h3>
                                                                <p>on {formatDateTime(attendance.createdAt)}</p>
                                                            </>
                                                        )}
                                                        {(attendance.status === 'reached') && (
                                                            <>
                                                                <h3>{student.name} {t("reached_by_transport")} </h3>
                                                                <p>on {formatDate(attendance.createdAt)}</p>
                                                            </>
                                                        )}
                                                         {(attendance.status === 'left') && (
                                                            <>
                                                                <h3>{student.name} {t("left_by_transport")} </h3>
                                                                <p>on {formatDateTime(attendance.createdAt)}</p>
                                                            </>
                                                        )}
                                                        {(attendance.status === 'dropped') && (
                                                            <>
                                                                <h3>{student.name} {t("dropped_by_transport")} </h3>
                                                                <p>on {formatDate(attendance.createdAt)}</p>
                                                            </>
                                                        )}
                                                </IonLabel>
                                        </IonItem>
                                    </IonCardContent>
                            </IonCard>
                        
                        {transport && (attendance.status !== 'custody') && (
                            <IonButton expand="block" color="dark" className='ion-margin-horizontal' onClick={handleCustody}>{t("received_child")}</IonButton>
                        )}
                        </IonCol>
                    </IonRow>
                    )}
                     {(attend) && (
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className={
                                    (attend.status === 'present') ? 'success-card' : 'danger-card'
                                }>
                                <IonCardContent className='ion-no-padding ion-padding-vertical'>
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}
                                            
                                            >
                                            <IonIcon slot="start"
                                                icon={calendar}
                                                color={
                                                    (attend.status === 'present') ? 'success' : 'danger'
                                                }
                                                />
                                                <IonLabel className="recent-label">
                                                        
                                                        {(attend.status === 'present') && (
                                                            <>
                                                                <h3>{student.name} {t("marked_present_on")} </h3>
                                                                <p>on {formatDate(attend.dated)}</p>
                                                            </>
                                                        )}

                                                        {(attend.status === 'absent') && (
                                                            <>
                                                            <h3>{student.name} {t("marked_absent_on")} </h3>
                                                            <p>on {formatDate(attend.dated)}</p>
                                                        </>
                                                        )}
                                                </IonLabel>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                </>
                )}
                                      
                {circulars && (circulars.length > 0) && (
                <IonRow>
                    <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">

                    <IonRow className="ion-padding-vertical">
                        <IonCol>
                                <IonTitle className="title-heading">
                                {t("latest_news")}
                                </IonTitle>   
                        </IonCol>
                       
                    </IonRow>

                    <IonRow>
                        <IonCol size="1" className="ion-padding-top pointer" onClick={handlePrev}>
                            <div className="vertical-container">
                                <div className="vertical-center"> 
                                    <img src="assets/images/left.png" alt="Prev" />
                                 </div>
                            </div>
                        </IonCol>
                        <IonCol size="10">
                            <Swiper 
                                spaceBetween={0}
                                initialSlide={0}
                                slidesPerView={1}
                                loop={false}
                                centeredSlides={true}
                                onSwiper={(swiper: SwiperInterface) => setSwiperInstance(swiper)}>
                            { 
                            circulars.map((circular) => {
                                return (
                                   
                                <SwiperSlide key={circular._id}>
                                     
                                     <IonRow>
                                        <IonCol>
                                            <IonCard className={circular.filename ? 'circular-card full-width' : 'announce-card full-width'}
                                                        routerLink={`/app/circular/${circular._id}`} >
                                                <IonCardContent className='ion-no-padding'>
     
                                                        <IonRow className='ion-padding-horizontal'>
                                                            <IonCol className='planner-col ion-padding-bottom'>
                                                                <IonBadge className={circular.filename ? 'circular-badge' : 'announce-badge'}>{circular.filename ? t("circular") : t("announcement") }</IonBadge>
                                                                    
                                                                    <h3>{formatDate(circular.createdAt)}<br/>{t("title")}</h3>
                                                                    <p>{circular.title}</p>

                                                            </IonCol>
                                                           
                                                        </IonRow>
 
                                                    </IonCardContent>
                                                </IonCard>
                                        </IonCol>
                                        
                                    </IonRow>
                                  
                                    </SwiperSlide>
                                   
                                    )})
                                    }
                                   
                                </Swiper>
                            </IonCol>
                            <IonCol size="1" className="ion-padding-top pointer" onClick={handleNext}>
                            <div className="vertical-container">
                                <div className="vertical-center"> 
                                <img src="assets/images/right.png" alt="Next" />
                                </div>
                            </div>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
                    )}

                {convo && (
                    <>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className="title-heading">
                                     {t("latest_comm")}
                                </IonTitle>   
                        </IonCol>
                       
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className="tertiary-card" routerLink={`/app/inquiry/${convo._id}`} >
                                <IonCardContent className='ion-no-padding'>
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}
                                    >
                                        <IonIcon slot="start"
                                            icon={((convo.type === "classwork") || (convo.type ===  "homework")) ? documentAttach : (convo.type ===  "leave") ? medical : chatbubbles}
                                            color="tertiary"
                                        />
                                        <IonLabel>
                                            <IonRow>
                                                <IonCol>
                                                    {convo.title}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='vid-responsive-field-2 text-capitalize'>
                                                <IonCol>
                                                    <IonLabel>
                                                        {convo.type} &#8226; {formatDate(convo.createdAt)}
                                                    </IonLabel>
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                        <IonBadge slot="end">{convo.messages.length+1}</IonBadge>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    </>
                    )}

                    {student && (student.due > 0) && payment && (
                        <>
                            <IonRow className="ion-padding-vertical">
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                        <IonTitle className="title-heading">
                                            {t("amt_due")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                    <IonCard className="action-card-d">
                                        <IonCardContent>
                                                <IonRow>
                                                   
                                                        <IonCol size="9" className="student-overdue mt-05">
                                                             <h1>{t("rs")} {student.due}</h1>
                                                        </IonCol>
                                                        <IonCol size="3">
                                                                <IonButton className="fifth-button" 
                                                                    size="small"
                                                                    fill="clear"
                                                                    onClick={() => history.push('/app/pay')}
                                                                    disabled={(student.due < 1)}
                                                                    >{t("pay")}</IonButton>
                                                        
                                                        </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                    </>
                    )}

                    {(events.length > 0) && (
                         <>
                         <IonRow className="ion-padding-vertical">
                             <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                     <IonTitle className="title-heading">
                                        {t("upcoming_events")}
                                     </IonTitle>
                             </IonCol>
                         </IonRow>
                        <IonRow>
                            <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">

                                {events.map((event) => {
                                return (
                                    <IonCard className="planner-card-2" key={event._id}>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>            
                                                    <IonItem lines="none"
                                                        button={false}
                                                        detail={false}
                                                        >
                                                            <IonIcon icon={sparkles}
                                                                slot="start"
                                                                color="primary" />
                                                            <IonLabel className="action-title-one">
                                                                <h3>{event.title}</h3>
                                                                <p>{formatDate(event.calendarDate)}</p>
                                                                </IonLabel>
                                                        
                                                        </IonItem>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>            
                                                    <IonText>{event.description}</IonText>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                        );
                                    })
                                }
                                      
                        </IonCol>
                    </IonRow>
                    </>
                    )}

                    {completedTrip && (
                    <>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className="title-heading">
                                    {t("last_trip")}
                                </IonTitle>   
                        </IonCol>
                       
                    </IonRow>
                    <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin" offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className={completedTrip.type === 'drop' ? "announce-card" : "circular-card"} routerLink={`/app/trip/detail/${completedTrip._id}/${student?._id}`}>
                                <IonCardContent className="ion-no-padding ion-no-margin">
   
                                        <IonRow className='ion-padding'>
                                            <IonCol size="2">
                                                <IonIcon className='second-icon'
                                                    icon={busOutline}
                                                    color={completedTrip.type === 'drop' ? 'secondary' : 'primary'} />
                                                </IonCol>
                                                <IonCol size="9">
                                                    <IonText className='action-text'>{completedTrip.name}({completedTrip.type === "pickup" ? t("pickup") : t("drop")})<br/>
                                                        {t("ended")}: {formatDateTime(completedTrip.finishedAt)}
                                                            </IonText> 
                                                           
                                                </IonCol>
                                                <IonCol size="1">
                                                                
                                                    <IonIcon className='second-icon'
                                                        icon={chevronForwardOutline}
                                                        color='dark'/>
                                                
                                            </IonCol>
                                        </IonRow>
                                        {completedStatus && completedDoneAt && (
                                            <IonRow className="border-top ion-padding">
                                                <IonCol className='ion-text-center'>
                                                    <IonText className='action-text-4'>
                                                    {t("child_marked")} {completedStatus} on {formatDateTime(completedDoneAt)}
                                                        </IonText>
                                                

                                                </IonCol>
                                            </IonRow>
                                            )}

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    </>
                    )}

                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ParentHome;
