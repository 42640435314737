import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import { attach, trash } from 'ionicons/icons';

const allRoles = ["Teacher", "Academic", "Driver", "Transporter", "Accountant", "AdmissionHead", "Inventory"];

const AddCircularStaff: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [roles, setRoles] = useState < string[] > ([]);
    const [title, setTitle] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [mode, setMode] = useState < string > ("Circular");
    const [imageURL, setImageURL] = useState < string > ("");
    const [upload, setUpload] = useState < File | null > (null);
    const [type, setType] = useState < string > ("");
    const [buttonColor, setButtonColor] = useState < string > ("light");

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const pickFile = async () => {
        try {

           const result = await FilePicker.pickFiles({limit: 1});
           if (result.files.length === 1)
           {
               const file = result.files[0];
               //for native
               if (file && file.path && file.name && file.mimeType) {
                   const webPath = convertFileSrc(file.path);
                   if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                   {
                       setImageURL(webPath);
                   }
                   else
                    {
                        setImageURL("");
                    }
                   const blob = await fetch(webPath).then((r) => r.blob());
                   const rawFile = new File([blob], file.name, {
                       type: file.mimeType,
                   });
                   setUpload(rawFile);
               }
               //for web
               else if (file && file.blob && file.name && file.mimeType) {
                   const rawFile = new File([file.blob], file.name, {
                       type: file.mimeType,
                   });
                   setUpload(rawFile);
                   if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                   {
                       const base64 = await fileToDataUri(rawFile);
                       setImageURL(base64);
                   }
                   else
                    {
                        setImageURL("");
                    }
               }
           }
       } 
       catch(error: any) {

           setMessage(t("no_files_selected"));  
           setIserror(true);
       }
   };

    const handleChecked = (value: string) => {

        let rolled = [...roles];

        if(rolled.includes(value))
        {
         const index = rolled.indexOf(value);
             if (index > -1) { // only splice array when item is found
                 rolled.splice(index, 1); // 2nd parameter means remove one item only
             }
        }
        else
        {
            rolled.push(value);
        }

        setRoles(rolled);
    }

    const handleAll = () => {

        if (buttonColor === "light")
        {
            setRoles(allRoles);
            setButtonColor("warning");
        }
        else
        {
            setRoles([]);
            setButtonColor("light");
        }
    }

    const handleSubmit = () => {

        if (!title || title === "") {
            setMessage(t("title_mandatory"));
            setIserror(true);
            return;
        }

        if (!type || type === "") {
            setMessage(t("type_mandatory"));
            setIserror(true);
            return;
        }

        if (mode === "Circular")
        {
            if (!upload) {
                setMessage(t("circular_format"));
                setIserror(true);
                return;
            }
        }

        if (mode === "Announcement")
        {
            if (!text || text === "") {
                setMessage(t("message_mandatory"));
                setIserror(true);
                return;
            }
        }

        if(!roles.length)
        {
            setMessage(t("select_roles"));
            setIserror(true);
            return;
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
              'Authorization': 'Bearer ' + authInfo.token
              }
          });

          const addCircular = (formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/circulars/add', formData).then(res => {
                return resolve(res.data.circular.title);
              }).catch(err => reject(err));
            });
          }

          const send = () => {
            return new Promise((resolve, reject) => {
              api.post('/circulars/admnAdd', { 'instituteID': authInfo.user.instituteID, 'adminID': authInfo.user._id, roles, title, text, type }).then(res => {
                return resolve(res.data.circular.title);
              }).catch(err => reject(err));
    
            });
          }


        if (mode === "Circular")
        {
           
            if (upload) {
            
            let formData = new FormData();

            formData.append('instituteID', authInfo.user.instituteID);
            formData.append('title', title);
            formData.append('type', type);
            formData.append('roles', JSON.stringify(roles));
            formData.append('adminID', authInfo.user._id);
            formData.append('circular', upload, 'circular');

            setShowLoading(true);
            addCircular(formData)
                .then(data => {
                    setPass(data+t("has_been_issued"));
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
        
                    setIserror(true);
                    setShowLoading(false);
                });
            }
        }

        if (mode === "Announcement")
        {
            setShowLoading(true);
            send()
              .then(data => {
                    setPass(data+t("has_been_issued"));
                    setIspass(true);
                    setShowLoading(false);
              })
              .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
        
                setIserror(true);
                setShowLoading(false);
              });
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/communication`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("issue_staff_comm")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("issue_staff_comm")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false); 
                            history.push(`/app/staff/circulars`);
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("communication_details")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding mt-15">
                                <IonCol>
                                    <IonSegment value={mode} onIonChange={e => setMode( e.detail.value!.toString() )} mode="ios" color="secondary">
                                        <IonSegmentButton value="Circular">
                                            <IonLabel>{t("circular")}</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="Announcement">
                                            <IonLabel>{t("announcement")}</IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("comm_title")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("comm_title")}
                                                        value={title}
                                                        onIonInput={
                                                            (e) => setTitle(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("comm_type")}*
                                                    </IonLabel>
                                                    <IonSelect value={type}
                                                        className="input-field"
                                                        placeholder={t("comm_type")}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        onIonChange={
                                                            (e) => setType(e.detail.value)
                                                        }>
                                                    
                                                        <IonSelectOption value="general">{t("general_comm")}</IonSelectOption>
                                                        <IonSelectOption value="academic">{t("academic_comm")}</IonSelectOption> 
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>

                            {(mode === "Circular") && (
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={true}
                                                    lines='none'
                                                    color="primary"
                                                    button={true}
                                                    onClick={pickFile}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={attach}
                                                    />
                                                    <IonLabel>
                                                    {(upload) ? t("change_file") : t("attach_file")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                {(upload) && (
                                                <IonItem detail={true}
                                                    detailIcon={trash}
                                                    lines='none'
                                                    button={true}
                                                    onClick={
                                                        () => {
                                                            setUpload(null);
                                                            setImageURL("");
                                                        }
                                                    }
                                                >
                                                    <IonBadge color="danger" slot="start">1</IonBadge>
                                                    <IonLabel>
                                                        {t("remove_files")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                )}
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}
                            {(mode === "Circular") && imageURL && (
                            <IonRow>
                                <IonCol>
                                    <IonCard className="ion-text-center">
                                        <IonCardHeader>
                                            <IonCardSubtitle>{t("circular_preview")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                        
                                            <img src={imageURL} 
                                                alt={t("circular_preview")}
                                                width="320" 
                                            />
        
                                        </IonCardContent>        
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}
                           
                            {(mode === "Announcement") && (
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="floating" color="secondary">{t("circular_msg")}</IonLabel>
                                                <IonTextarea rows={20} value={text} onIonInput={
                                                        (e) => setText(e.detail.value!)
                                                }></IonTextarea>
                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}

                        </IonCol>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("comm_roles")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow className="mt-30">
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonList className="ion-no-padding">
                                                <IonItemDivider color="secondary">
                                                   
                                                    <IonButtons slot="start">
                                                        <IonButton onClick={handleAll} color={buttonColor}>{t("select_all")}</IonButton>
                                                    </IonButtons>
                                                </IonItemDivider>
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Teacher" checked={roles.includes("Teacher")} 
                                                            onClick={e => handleChecked("Teacher")}/>
                                                        <IonLabel>{t("teacher")}</IonLabel>
                                                    </IonItem>
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Academic" checked={roles.includes("Academic")} 
                                                            onClick={e => handleChecked("Academic")}/>
                                                        <IonLabel>{t("academic_incharge")}</IonLabel>
                                                    </IonItem>
                                                    {(authInfo.institute.transport) && (<>
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Driver" checked={roles.includes("Driver")} 
                                                            onClick={e => handleChecked("Driver")}/>
                                                        <IonLabel>{t("driver")}</IonLabel>
                                                    </IonItem>
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Transporter" checked={roles.includes("Transporter")} 
                                                            onClick={e => handleChecked("Transporter")}/>
                                                        <IonLabel>{t("transport_incharge")}</IonLabel>
                                                    </IonItem>
                                                    </>)}
                                                    {(authInfo.institute.payment) && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Accountant" checked={roles.includes("Accountant")} 
                                                            onClick={e => handleChecked("Accountant")}/>
                                                        <IonLabel>{t("accountant")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                    {(authInfo.institute.admission) && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="AdmissionHead" checked={roles.includes("AdmissionHead")} 
                                                            onClick={e => handleChecked("AdmissionHead")}/>
                                                        <IonLabel>{t("admission_head")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                    {(authInfo.institute.inventory) && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Inventory" checked={roles.includes("Inventory")} 
                                                            onClick={e => handleChecked("Inventory")}/>
                                                        <IonLabel>{t("inventory_manager")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                   
                                            </IonList>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("issue_comm")} {mode === "Circular" ? t("circular") : t("announcement")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddCircularStaff;
