import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { alertCircleOutline, checkboxOutline, construct } from 'ionicons/icons';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useAuth} from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface ParamTypes {
    classID?: string
}
interface Session {
    _id: string,
    name: string,
    isActive: boolean
}
interface ClassInterface {
    _id: string,
    name: string
}
interface Student {
    _id: string,
    parentID: string,
    name: string,
    dob: Date,
    isChecked?: boolean
}

const PromoteClass: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {classID} = useParams < ParamTypes > ();
    const {t} = useTranslation();
    const history = useHistory();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [toSession, setToSession] = useState < string > ("");
    const [fromSession, setFromSession] = useState < string > ("");
    const [toClasses, setToClasses] = useState < ClassInterface[] > ([]);
    const [fromClasses, setFromClasses] = useState < ClassInterface[] > ([]);
    const [toClass, setToClass] = useState < string > ("");
    const [fromClass, setFromClass] = useState < string > ("");
    const [toStudents, setToStudents] = useState < Student[] > ([]);
    const [fromStudents, setFromStudents] = useState < Student[] > ([]);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const selectAll = () => {

        if((toClass !== "") && (toClass !== fromClass))
        {
            let studs = [...fromStudents];
            let toStuds = [...toStudents];
            for (let i=0; i < studs.length; i++)
            {
                let obj = toStuds.find(val => val._id === studs[i]._id);
                if (!obj)
                {
                    toStuds.push(studs[i]);
                }
                studs[i].isChecked = true;
            }
            setFromStudents(studs);
            setToStudents(toStuds);
        }
        else
        {
            if (authInfo.institute.type === "school") {
                setMessage(t("select_both_classes"));
            }
            else {
                setMessage(t("select_both_batches"));
            }
            setIserror(true);
        }
        
    }

    const handleChecked = (id : number) => {
        if((toClass !== "") && (toClass !== fromClass))
        {
            let studs = [...fromStudents];
            let toStuds = [...toStudents];
            studs[id].isChecked = !studs[id].isChecked;

            if (studs[id].isChecked)
            {
                let obj = toStuds.find(val => val._id === studs[id]._id);
                if (!obj)
                {
                    toStuds.push(studs[id]);
                }
            }
            else
            {
                let index = toStuds.findIndex(val => val._id === studs[id]._id);
                if (index > -1)
                {
                    toStuds.splice(index, 1);
                }
            }
            setFromStudents(studs);
            setToStudents(toStuds);
        }
    }

    const handleFromSession = (sessionID : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                setFromClasses(result.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setFromSession(sessionID);
        setFromClass("");
        setFromStudents([]);
    }

    const handleToSession = (sessionID : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                setToClasses(result.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setToSession(sessionID);
        setToClass("");
        setToStudents([]);
    }

    const handleFromClass = (classID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/nameByClass/${classID}`);
                setFromStudents(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setFromClass(classID);
    }

    const handleToClass = (classID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/nameByClass/${classID}`);
                setToStudents(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setToClass(classID);
    }

    const handleSubmit = () => {

        if (!toClass || toClass === "") {
            setMessage(t("to_class_mandatory"));
            setIserror(true);
            return;
        }

        if (!fromClass || fromClass === "") {
            setMessage(t("from_class_mandatory"));
            setIserror(true);
            return;
        }

        if(fromStudents.length < 1)
        {
            setMessage(t("shift_mandatory"));
            setIserror(true);
            return;
        }

        let checked = 0;
        for (let i=0; i < fromStudents.length; i++)
        {
            if(fromStudents[i].isChecked)
            {
                checked++;
            }
        }

        if (checked < 1)
        {
            setMessage(t("shift_mandatory"));
            setIserror(true);
            return;
        }
  
        setShowLoading(true);
        api.post('/classes/promote', { toClass, fromStudents })
        .then(async (res) => {
            const tostuds = await api.get(`/students/nameByClass/${toClass}`);
            setToStudents(tostuds.data);

            const fromstuds = await api.get(`/students/nameByClass/${fromClass}`);
            setFromStudents(fromstuds.data);

            setPass(checked + t("have_been_shifted")+res.data.classi.name);
            setIspass(true);
        })
        .catch((error: any) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
      }

      useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);

                if (classID)
                {
                    const result = await api.get(`/classes/${classID}`);
                    setFromSession(result.data.sessionID);

                    const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${result.data.sessionID}`);
                    setFromClasses(query.data);
                    setFromClass(classID);
                    const studs = await api.get(`/students/nameByClass/${classID}`);
                    setFromStudents(studs.data);
                }
                else
                {
                    setFromSession("");
                    setFromClasses([]);
                    setFromClass("");
                    setFromStudents([]);
                    setToStudents([]);
                    setToClasses([]);
                    setToClass("");
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, classID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/essentials`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{(authInfo.institute.type === "school") ? t("promote_class") : t("promote_batch")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{(authInfo.institute.type === "school") ? t("promote_class") : t("promote_batch")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {(authInfo.institute.type === "school") ? t("from_class") : t("from_batch")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("class_session")}</IonLabel>
                                                            <IonSelect value={fromSession}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={t("class_session")}
                                                                    onIonChange={
                                                                        (e) => handleFromSession(e.detail.value)
                                                                }>

                                                                    {sessions.map((session) => 
                                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                    )}

                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                                            <IonSelect value={fromClass}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                                    onIonChange={
                                                                        (e) => handleFromClass(e.detail.value)
                                                                }>
                                                                    
                                                                    {fromClasses.map((classy) => (
                                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                                    ))}
                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol className='ion-padding-horizontal'>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("students")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol className='ion-padding'>
                                    <div className="ion-table">
                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="7" className='table-heading'>
                                            <IonIcon 
                                                icon={checkboxOutline}
                                                color="light"
                                                className='table-icon'
                                                onClick={selectAll}
                                            />
                                            <IonText className='ion-margin-start'>{t("name")}</IonText>
                                        </IonCol>
                                        <IonCol size="4" className='table-heading'>
                                        {t("dob")}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color="light"
                                                className='table-icon'
                                                />
                                        </IonCol>
                                    </IonRow>
                                {(fromStudents.length > 0) ? (fromStudents.map((stud, i) => {
                                return (
                                    <IonRow key={stud._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                    
                                        <IonCol size="7" className='table-field' >
                                            <IonCheckbox labelPlacement="end" checked={stud.isChecked} 
                                                onClick={(e) => handleChecked(i) } 
                                                disabled={(toClass !== "") && (toClass !== fromClass) ? false : true}
                                            >
                                                    {stud.name}
                                            </IonCheckbox>
                                        
                                        </IonCol>
                                        <IonCol size="4" className='table-field'>
                                            {formatDated(stud.dob)}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color="dark"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/student/${stud._id}`)
                                                }
                                            />
                                            
                                        </IonCol>
                                
                                    </IonRow>
                                    )})) : (
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )}
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {(authInfo.institute.type === "school") ? t("to_class") : t("to_batch")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("class_session")}</IonLabel>
                                                            <IonSelect value={toSession}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={t("class_session")}
                                                                    onIonChange={
                                                                        (e) => handleToSession(e.detail.value)
                                                                }>

                                                                    {sessions.map((session) => 
                                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                    )}

                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                                            <IonSelect value={toClass}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                                    onIonChange={
                                                                        (e) => handleToClass(e.detail.value)
                                                                }>
                                                                    
                                                                    {toClasses.map((classy) => (
                                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                                    ))}
                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                    <IonRow className="mt-30">
                                        <IonCol className='ion-padding-horizontal'>
                                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                                    {t("students")}
                                                </IonTitle>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-margin-top">
                                        <IonCol className='ion-padding'>
                                            <div className="ion-table">
                                            <IonRow className="table-title ion-padding">
                                                <IonCol size="7" className='table-heading'>
                                                   {t("name")}
                                                </IonCol>
                                                <IonCol size="4" className='table-heading'>
                                                    {t("dob")}
                                                </IonCol>
                                                <IonCol size="1">
                                                    <IonIcon 
                                                        icon={construct}
                                                        color="light"
                                                        className='table-icon'
                                                        />
                                                </IonCol>
                                            </IonRow>
                                            {(toStudents.length > 0) ? (toStudents.map((stud, i) => {
                                            return (
                                                <IonRow key={stud._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                                
                                                    <IonCol size="7" className='table-field'>
                                                        {stud.name}
                                                    </IonCol>
                                                    <IonCol size="4" className='table-field'>
                                                        {formatDated(stud.dob)}
                                                    </IonCol>
                                                    <IonCol size="1">
                                                        <IonIcon 
                                                            icon={construct}
                                                            color="dark"
                                                            className='table-icon mt-10'
                                                            onClick={
                                                                () => history.push(`/app/student/${stud._id}`)
                                                            }
                                                        />
                                                        
                                                    </IonCol>
                                            
                                                </IonRow>
                                                )})) : (
                                                <IonRow>
                                                    <IonCol>
                                                        <IonCard>
                                                            <IonCardContent>
                                                                <IonItem lines="none">
                                                                    <IonIcon icon={alertCircleOutline}
                                                                        slot="start"
                                                                        color="danger" />
                                                                    <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                                                                </IonItem>
                                                            </IonCardContent>
                                                        </IonCard>
                                                    </IonCol>
                                                </IonRow>
                                                )}
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                >{t("shift_students")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default PromoteClass;
