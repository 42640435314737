import {
    ActionSheetButton,
    IonActionSheet,
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSkeletonText,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, book, chevronDownOutline, closeOutline, documentText, helpCircleOutline, schoolOutline, schoolSharp, sparkles } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { useHistory } from 'react-router-dom';

interface DaySchedule {
    startTime: string,
    endTime: string,
    classInfoName: string,
    subjectName: string
}

interface TimeTable {
    mon: DaySchedule[],
    tue: DaySchedule[],
    wed: DaySchedule[],
    thu: DaySchedule[],
    fri: DaySchedule[],
    sat: DaySchedule[]
}

interface Segment {
    day: string,
    date: Date
}

interface Event {
    _id: string,
    title: string,
    description: string,
    media?: string,
    calendarDate: Date
}

interface Batch {
    _id: string,
    name: string,
    year: string,
    courseID?: string,
    topics?: string[],
    isActive: boolean
}

interface Chapter {
    _id: string,
    chapterName: string,
    isActive: boolean,
    order: number
}
interface Topic {
    _id: string,
    name: string,
    isActive: boolean,
    order?: number
}

interface ResponseInterface {
    data: Batch[]
}

const Planner: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const [timetable, setTimeTable] = useState < TimeTable > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [day, setDay] = useState < string > ("mon");
    const [segments, setSegments] = useState <Segment[]> ([]);
    const [events, setEvents] = useState < Event[] > ([]);

    const [month, setMonth] = useState < string > ("");
    const [showActionSheet, setShowActionSheet] = useState < boolean > (false);
    const [batches, setBatches] = useState < ActionSheetButton[] > ([]);
    const [batch, setBatch] = useState < Batch > ();
    const [allBatches, setAllBatches] = useState < Batch[] > ([]);
    const [segmentsB, setSegmentsB] = useState < Chapter[] > ([]);
    const [topics, setTopics] = useState < Topic[] > ([]);
    
    const stateRef = useRef < Batch[] > ();
    stateRef.current = allBatches;

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const handleDay = (selectedDay: string) => {
        setDay(selectedDay);

        const d = segments.find(val => val.day === selectedDay)!.date;
        const day = {year: d.getFullYear(), month: d.getMonth()+1, day: d.getDate()};

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const eve = await api.post(`/feeds/getByDay`, {instituteID: authInfo.user.instituteID, day});
                setEvents(eve.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();
    }

    const swapBatch = async (selected: string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        if(stateRef.current?.length)
        {
            const theBatch = stateRef.current.find(val => val._id === selected)!;

            setBatch(theBatch);
    
            if(theBatch.courseID)
            {
                const course = await api.get('/courses/' + theBatch.courseID);
                //sort chapters based on order
                const resu: Chapter[] = course.data.chapters.sort((a: Chapter, b: Chapter) => a.order! - b.order!);
                setSegmentsB(resu);

                setMonth(resu[0]._id);

                const tops = await api.get(`/topics/getByChapter/${resu[0]._id}`);
                setTopics(tops.data);
               
            }
            else
            {
                setTopics([]);
                setSegmentsB([]);
                setMonth("");
            }
            
            setShowActionSheet(false);
        }
    }

    const handleMonth = (chapter : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const tops = await api.get(`/topics/getByChapter/${chapter}`);
                setTopics(tops.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();
        setMonth(chapter);
        
    }

    const handleChecked = (topicID: string, index: number) => {
       if (batch)
       {
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });
        
            const upClass = () => {
                return new Promise((resolve, reject) => {
                api.put('/classes/'+batch._id, { topicID }).then(res => {
            
                    return resolve(res.data);
                    
                }).catch(err => reject(err));
        
                });
            }

            setShowLoading(true);
            upClass()
            .then((data: any) => {
                let comps = [...allBatches];
                const index = comps.findIndex((val) => val._id === batch._id);
                if (index > -1)
                {
                    comps[index] = data;
                }
                
                //update batch.topics && allBatches
                setAllBatches(comps);
                setBatch(data);
                setShowLoading(false);
                
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            });
        }
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                if (authInfo.institute.timetable)
                {
                    const result = await api.get(`/timeTables/getByTeacher/${authInfo.user.instituteID}/${authInfo.user._id}`);
                    setTimeTable(result.data);
    
                    const weekday = ["sun","mon","tue","wed","thu","fri","sat"];
    
                    const d = new Date(Date.now());
    
                    if (d.getDay() === 0)
                    {
                        d.setDate(d.getDate() + 1);
                    }
    
                    setDay(weekday[d.getDay()]);
    
                    const day = {year: d.getFullYear(), month: d.getMonth()+1, day: d.getDate()};
                    const eve = await api.post(`/feeds/getByDay`, {instituteID: authInfo.user.instituteID, day});
                    setEvents(eve.data);
    
                    let segs: Segment[] = [];
    
                    for (let i=0; i<6; i++)
                    {
    
                        let theday = weekday[d.getDay()];
                        if(theday === 'sun')
                        {
                            d.setDate(d.getDate() + 1);
                            theday = weekday[d.getDay()];
                        }
                        segs.push({'day': theday, 'date': new Date(d)});
                        d.setDate(d.getDate() + 1);
                    }
                    setSegments(segs);
                }
                else
                {
                    const res = await api.get(`/employees/getByUID/${authInfo.user._id}`);

                    if(res.data.classID && res.data.classID !== "none")
                    {
                        const result: ResponseInterface = await api.get('/classes/getByTeacher/' + authInfo.user._id);

                        setBatch(result.data.find((val: any) => val._id === res.data.classID));
        
                        if(result.data.length > 1)
                        {
                            let bObjects: Batch[] = [];
                            
                            let cObjects: ActionSheetButton[] = [];
        
                            for (let i=0; i < result.data.length; i++)
                            {
                                let cObject: ActionSheetButton;
                                
                                    if (result.data[i].isActive === true)
                                    {
                                        cObject = {
                                            text: result.data[i].name + ' (' + result.data[i].year + ')',
                                            icon: !isPlatform('ios') ? schoolOutline : undefined,
                                            handler: () => swapBatch(result.data[i]._id)
                                        }
                                        cObjects.push(cObject);
                                        bObjects.push(result.data[i]);
        
                                    }
                                
                            }
    
                            cObjects.push({
                                text: t('cancel'),
                                icon: !isPlatform('ios') ? closeOutline : undefined,
                                role: 'cancel'
                            });
        
                            setBatches(cObjects);
                            setAllBatches(bObjects);
                        }
    
                        if(result.data.find((val: any) => val._id === res.data.classID)!.courseID !== undefined)
                        {
                            const course = await api.get('/courses/' + result.data.find((val: any) => val._id === res.data.classID)!.courseID);
                            //sort chapters based on order
                            const resu: Chapter[] = course.data.chapters.sort((a: Chapter, b: Chapter) => a.order! - b.order!);
    
                            setSegmentsB(resu);
    
                            setMonth(resu[0]._id);

                            const tops = await api.get(`/topics/getByChapter/${resu[0]._id}`);
                            setTopics(tops.data);
                        
                        }
    
                        
                    }
                }

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("my_time_table")}</IonTitle>
                    
                    <IonButtons slot="end">
                    {!isPlatform('mobile') && (
                    <>
                        <ThemeToggle />
                        <LanguageToggle />
                    </>
                    )}
                    
                        <IonButton id="open-planner-info" fill="clear" className='ion-no-padding badge-button'> 
                            <IonIcon color="success" icon={helpCircleOutline} className="badge-icon" />
                            <IonPopover trigger="open-planner-info" className='info-popover'>
                                <IonCard className='note-card-3 ion-no-margin'>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonRow>
                                            <IonCol className='note-text'>
                                                <p>
                                                {authInfo.institute.timetable ? t("my_timetable_help"): t("my_planner_help")}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonPopover>
                        </IonButton>
                    </IonButtons>
                    
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("my_time_table")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                    {!authInfo.institute.timetable && (
                    <>
                        <IonActionSheet isOpen={showActionSheet}
                            onDidDismiss={
                                () => setShowActionSheet(false)
                            }
                            cssClass='swap-sheet'
                            subHeader={`${t("currently_selected")} - ${batch?.name} (${batch?.year})`}
                            header={t("switch_batch")}
                            buttons={batches} 
                        />


                        <IonGrid className="ion-no-padding">
                            <IonRow className="ion-no-padding">
                                <IonCol>
                                <IonToolbar className="pl-16" color="secondary" mode="ios">
                                    {batches && (batches.length > 1) && (
                                    <IonIcon slot='start' icon={schoolSharp} color="warning" />
                                    )}
                                    <IonTitle color="secondary" className='parent-home-title'>
                                    {
                                        batch ? (batch.name + ' (' + batch.year + ')') : 
                                        ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                                    }
                                    </IonTitle>
                                    {batches && (batches.length > 1) && (
                                    <IonButtons slot="end">
                                            <IonButton onClick={() => setShowActionSheet(true)}><IonIcon icon={chevronDownOutline} color="warning" /></IonButton>
                                    </IonButtons>
                                    )}
                                </IonToolbar> 
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                    </>)}

                <IonGrid className="ion-no-padding">

                    {authInfo.institute.timetable && (
                    <>

                    <IonRow>
                        <IonCol>
                            <IonSegment mode="md" scrollable={true} value={day} onIonChange={(e) => handleDay(e.detail.value!.toString())} className="planner-segment">

                            {segments && segments.map((seg, i) => (
                                <IonSegmentButton key={i} value={seg.day} className='planner-button'>
                                    <IonLabel>{t(seg.day)}<br/>{seg.date.getDate().toString()}</IonLabel>
                                </IonSegmentButton>
                            ))}
                            

                            </IonSegment>
                        </IonCol>
                    </IonRow>
                    {events && events.map((event) => {
                    return (
                        <IonRow key={event._id} className="ion-padding">
                            <IonCol>
                                <IonCard className="planner-card-2">
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>            
                                                <IonItem lines="none"
                                                    button={false}
                                                    detail={false}
                                                    >
                                                        <IonIcon icon={sparkles}
                                                            slot="start"
                                                            color="primary" />
                                                        <IonLabel className="action-title-one">
                                                            <h3>{event.title}</h3>
                                                            <p>{formatDate(event.calendarDate)}</p>
                                                            </IonLabel>
                                                    
                                                    </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>            
                                                <IonText>{event.description}</IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        
                            );
                        })
                    }
                  
                    {(day === 'mon') && (timetable && timetable.mon.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.mon.map((entry, i) => (
                                    <IonRow key={i}>
                                        <IonCol size="2" className='planner-timeline'>
                                            <h1>{entry.startTime}</h1>
                                            <h2>{entry.endTime}</h2>
                                        </IonCol>
                                        <IonCol size="10">
                                            <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                                <IonCardContent className="ion-no-padding ion-no-margin">
                                                   
                                                        <IonRow>
                                                            <IonCol className='planner-col ion-margin-horizontal ion-padding-vertical'>
                                                           
                                                                <IonLabel>
                                                                    <h3>{t("class")}</h3>
                                                                    <p>{entry.classInfoName}</p>
                                                                </IonLabel>
                                                                
                                                                <IonLabel>
                                                                    <h3>{t("subject")}</h3>
                                                                    <p>{entry.subjectName}</p>
                                                                </IonLabel>
                                                              
                                                            </IonCol>
                                                        </IonRow>

                                                    </IonCardContent>
                                                </IonCard>
                                        </IonCol>
                                    </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'mon') && (!timetable || (timetable && timetable.mon.length === 0)) && (
                        <IonRow className="ion-padding">
                        <IonCol>
                             <IonCard className='red-card ion-margin'>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger"
                                                size="large" />
                                            <IonLabel className="list-title ion-text-wrap"><h3>{t("no_classes_scheduled")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                        </IonCol>
                    </IonRow>
                    )}

                    {(day === 'tue') && (timetable && timetable.tue.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.tue.map((entry, i) => (
                                <IonRow key={i}>
                                <IonCol size="2" className='planner-timeline'>
                                    <h1>{entry.startTime}</h1>
                                    <h2>{entry.endTime}</h2>
                                </IonCol>
                                <IonCol size="10">
                                    <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                           
                                                <IonRow>
                                                    <IonCol className='planner-col ion-margin-horizontal ion-padding-vertical'>
                                                   
                                                        <IonLabel>
                                                            <h3>{t("class")}</h3>
                                                            <p>{entry.classInfoName}</p>
                                                        </IonLabel>
                                                        
                                                        <IonLabel>
                                                            <h3>{t("subject")}</h3>
                                                            <p>{entry.subjectName}</p>
                                                        </IonLabel>
                                                      
                                                    </IonCol>
                                                </IonRow>

                                            </IonCardContent>
                                        </IonCard>
                                </IonCol>
                            </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'tue') && (!timetable || (timetable && timetable.tue.length === 0)) && (
                    <IonRow className="ion-padding">
                        <IonCol>
                             <IonCard className='red-card ion-margin'>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger"
                                                size="large" />
                                            <IonLabel className="list-title ion-text-wrap"><h3>{t("no_classes_scheduled")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                        </IonCol>
                    </IonRow>
                    )}

                    {(day === 'wed') && (timetable && timetable.wed.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.wed.map((entry, i) => (
                                 <IonRow key={i}>
                                 <IonCol size="2" className='planner-timeline'>
                                     <h1>{entry.startTime}</h1>
                                     <h2>{entry.endTime}</h2>
                                 </IonCol>
                                 <IonCol size="10">
                                     <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                         <IonCardContent className="ion-no-padding ion-no-margin">
                                            
                                                 <IonRow>
                                                     <IonCol className='planner-col ion-margin-horizontal ion-padding-vertical'>
                                                    
                                                        <IonLabel>
                                                            <h3>{t("class")}</h3>
                                                            <p>{entry.classInfoName}</p>
                                                        </IonLabel>
                                                        
                                                        <IonLabel>
                                                            <h3>{t("subject")}</h3>
                                                            <p>{entry.subjectName}</p>
                                                        </IonLabel>
                                                       
                                                     </IonCol>
                                                 </IonRow>

                                             </IonCardContent>
                                         </IonCard>
                                 </IonCol>
                             </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'wed') && (!timetable || (timetable && timetable.wed.length === 0)) && (
                       <IonRow className="ion-padding">
                       <IonCol>
                            <IonCard className='red-card ion-margin'>
                                   <IonCardContent>
                                       <IonItem lines="none">
                                           <IonIcon icon={alertCircleOutline}
                                               slot="start"
                                               color="danger"
                                               size="large" />
                                           <IonLabel className="list-title ion-text-wrap"><h3>{t("no_classes_scheduled")}</h3></IonLabel>

                                       </IonItem>
                                   </IonCardContent>
                               </IonCard>
                       </IonCol>
                   </IonRow>
                    )}

                    {(day === 'thu') && (timetable && timetable.thu.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.thu.map((entry, i) => (
                                <IonRow key={i}>
                                <IonCol size="2" className='planner-timeline'>
                                    <h1>{entry.startTime}</h1>
                                    <h2>{entry.endTime}</h2>
                                </IonCol>
                                <IonCol size="10">
                                    <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                           
                                                <IonRow>
                                                    <IonCol className='planner-col ion-margin-horizontal ion-padding-vertical'>
                                                        <IonLabel>
                                                            <h3>{t("class")}</h3>
                                                            <p>{entry.classInfoName}</p>
                                                        </IonLabel>
                                                        
                                                        <IonLabel>
                                                            <h3>{t("subject")}</h3>
                                                            <p>{entry.subjectName}</p>
                                                        </IonLabel>
                                                      
                                                    </IonCol>
                                                </IonRow>

                                            </IonCardContent>
                                        </IonCard>
                                </IonCol>
                            </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'thu') && (!timetable || (timetable && timetable.thu.length === 0)) && (
                       <IonRow className="ion-padding">
                       <IonCol>
                            <IonCard className='red-card ion-margin'>
                                   <IonCardContent>
                                       <IonItem lines="none">
                                           <IonIcon icon={alertCircleOutline}
                                               slot="start"
                                               color="danger"
                                               size="large" />
                                           <IonLabel className="list-title ion-text-wrap"><h3>{t("no_classes_scheduled")}</h3></IonLabel>

                                       </IonItem>
                                   </IonCardContent>
                               </IonCard>
                       </IonCol>
                   </IonRow>
                    )}

                    {(day === 'fri') && (timetable && timetable.fri.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.fri.map((entry, i) => (
                                <IonRow key={i}>
                                <IonCol size="2" className='planner-timeline'>
                                    <h1>{entry.startTime}</h1>
                                    <h2>{entry.endTime}</h2>
                                </IonCol>
                                <IonCol size="10">
                                    <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                           
                                                <IonRow>
                                                    <IonCol className='planner-col ion-margin-horizontal ion-padding-vertical'>
                                                   
                                                    <IonLabel>
                                                        <h3>{t("class")}</h3>
                                                        <p>{entry.classInfoName}</p>
                                                    </IonLabel>
                                                    
                                                    <IonLabel>
                                                        <h3>{t("subject")}</h3>
                                                        <p>{entry.subjectName}</p>
                                                    </IonLabel>
                                                      
                                                    </IonCol>
                                                </IonRow>

                                            </IonCardContent>
                                        </IonCard>
                                </IonCol>
                            </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'fri') && (!timetable || (timetable && timetable.fri.length === 0)) && (
                        <IonRow className="ion-padding">
                        <IonCol>
                             <IonCard className='red-card ion-margin'>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger"
                                                size="large" />
                                            <IonLabel className="list-title ion-text-wrap"><h3>{t("no_classes_scheduled")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    {(day === 'sat') && (timetable && timetable.sat.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.sat.map((entry, i) => (
                               <IonRow key={i}>
                               <IonCol size="2" className='planner-timeline'>
                                   <h1>{entry.startTime}</h1>
                                   <h2>{entry.endTime}</h2>
                               </IonCol>
                               <IonCol size="10">
                                   <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                       <IonCardContent className="ion-no-padding ion-no-margin">
                                          
                                               <IonRow>
                                                   <IonCol className='planner-col ion-margin-horizontal ion-padding-vertical'>
                                                  
                                                     <IonLabel>
                                                            <h3>{t("class")}</h3>
                                                            <p>{entry.classInfoName}</p>
                                                        </IonLabel>
                                                        
                                                        <IonLabel>
                                                            <h3>{t("subject")}</h3>
                                                            <p>{entry.subjectName}</p>
                                                        </IonLabel>
                                                     
                                                   </IonCol>
                                               </IonRow>

                                           </IonCardContent>
                                       </IonCard>
                               </IonCol>
                           </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'sat') && (!timetable || (timetable && timetable.sat.length === 0)) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                 <IonCard className='red-card ion-margin'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger"
                                                    size="large" />
                                                <IonLabel className="list-title ion-text-wrap"><h3>{t("no_classes_scheduled")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                            </IonCol>
                        </IonRow>
                    )}

                </>)}
                {!authInfo.institute.timetable && (
                    <>
                     <IonRow>
                        <IonCol>
                            <IonSegment mode="md" scrollable={true} value={month} onIonChange={(e) => handleMonth(e.detail.value!.toString())} className="planner-segment">

                            {segmentsB && segmentsB.map((seg, i) => seg.isActive && (
                                <IonSegmentButton key={i} value={seg._id} className='planner-button'>
                                    <IonLabel>{seg.chapterName}</IonLabel>
                                </IonSegmentButton>
                            ))}
                               
                
                            </IonSegment>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-padding">
                        <IonCol>
                        {topics.length > 0 ? (
                                            
                            <IonCard className='border-radius-10 ion-margin-bottom planner-card-3'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonList className='ion-no-margin ion-no-padding'>
                                    {topics.map((topic, i) => 
                                    (topic.isActive) &&
                                    ( 
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={topic._id}
                                            className="topic-item" >

                                                <IonIcon
                                                    slot="start"
                                                    className='tran-icon'
                                                    icon={book}
                                                    color="tertiary"
                                                    />

                                                <IonLabel className='topic-label ion-text-wrap'>
                                                    <h3>{topic.name}</h3>
                                                </IonLabel>

                                                <IonCheckbox slot="end" value={topic._id} checked={batch!.topics && batch!.topics.includes(topic._id)} 
                                                onClick={e => handleChecked(topic._id, i)}/>

                                                <IonButtons slot="end" className="ml-16">
                                                    <IonIcon
                                                        className='tran-icon'
                                                        icon={documentText} 
                                                        color="primary"
                                                        onClick={() => history.push(`/app/teacher/submissions/topic/${topic._id}/${batch!._id}`)}
                                                    />
                                                </IonButtons>

                                        </IonItem> 
                                        )
                                    )}
                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                                
                            ) : (
                            <IonCard className='red-card ion-margin'>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_schedule")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            )
                        }

                        </IonCol>
                    </IonRow>

                    </>)}
                    
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Planner;
