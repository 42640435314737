import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React from 'react';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { helpCircleOutline } from 'ionicons/icons';


const Transport: React.FC = () => {
    const {t} = useTranslation();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("transport")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("transport")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid className='mb-60'>

                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol>
                            <IonRow>
                                <IonCol>
                                    <IonTitle id="open-trips-info" className='border-bottom-sec'>
                                        {t("manage_trips")}
                                    <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-trips-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("trips_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/tracking`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("realtime_tracking")} src="assets/images/tracking.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("realtime_tracking")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/trips/completed`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("completed_trips")} src="assets/images/route-history.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("completed_trips")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/trips/reports`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("trips_reports")} src="assets/images/reports.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("trips_reports")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/trips/alerts`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("trips_alerts")} src="assets/images/notif.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("trips_alerts")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonTitle id="open-routes-info" className='border-bottom-sec'>
                                        {t("manage_routes")}
                                    <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-routes-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("routes_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/routes/add`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("add_new_route")} src="assets/images/route-1.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("add_new_route")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/routes`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("manage_routes")} src="assets/images/route-1.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("manage_routes")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                             
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonTitle id="open-vehicles-info" className='border-bottom-sec'>
                                        {t("manage_vehicles")}
                                    <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-vehicles-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("vehicles_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/vehicles/add`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("add_new_vehicle")} src="assets/images/transporters.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("add_new_vehicle")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/vehicles`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("manage_vehicles")} src="assets/images/transporters.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("manage_vehicles")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/transactions/transport`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("transport_expense")} src="assets/images/bill.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("transport_expense")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>


                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Transport;