import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { alertCircleOutline, attach, camera, checkboxOutline, construct, trash, videocam } from 'ionicons/icons';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

interface ParamTypes {
    projectID?: string
}

interface Employee {
    _id: string,
    dob: Date,
    userInfo: UserInfo,
    isActive: boolean,
    isChecked?: boolean
}

interface UserInfo {
    userID: string,
    name: string
}

interface Department {
    _id: string,
    name: string
}

interface Project {
    _id: string,
    name: string,
    status: string
}

const AddTask: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {projectID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [imageURL, setImageURL] = useState < string > ("");
    const [upload, setUpload] = useState < File[] > ([]);
    const [priority, setPriority] = useState < string > ("");
    const [departments, setDepartments] = useState < Department[] > ([]);
    const [department, setDepartment] = useState < string > ("");
    const [employees, setEmployees] = useState < Employee[] > ([]);
    const [projects, setProjects] = useState < Project[] > ([]);
    const [project, setProject] = useState < string > ("");
    const [startDate, setStartDate] = useState < string | string[] | undefined > ();
    const [endDate, setEndDate] = useState < string | string[] | undefined > ();
    const [buttonColor, setButtonColor] = useState < string > ("light");

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const fileToDataUri = (file: File) => new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    const convertFileSrc = (path: string) => {
        return Capacitor.convertFileSrc(path);
    };

    const pickImages = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
            const result = await FilePicker.pickImages();
            const theUploads: File[] =  [];
            let imgUrl = "";

            for (let i=0; i < result.files.length; i++)
            {
                const file = result.files[i];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        //Display first photo as preview
                        if (i === 0) {
                            imgUrl = webPath;
                        }
                    }
                    if ((file.mimeType === 'image/heic' || file.mimeType === 'image/heif'))
                    {
                        const jpegPath = await FilePicker.convertHeicToJpeg({path: file.path});
                        const webPath = convertFileSrc(jpegPath.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: 'image/jpeg',
                        });
                        theUploads.push(rawFile);
                        //Display first photo as preview
                        if (i === 0) {
                            imgUrl = webPath;
                        }
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'image/jpeg') || (file.mimeType === 'image/png'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                        //Display first photo as preview
                        if (i === 0) {
                            const base64 = await fileToDataUri(rawFile);
                            imgUrl = base64;
                        }
                    }
                }
            }
            setUpload(theUploads);
            setImageURL(imgUrl);
        } 
        catch (error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }

    };

    const pickVideo = async () => {
        isPlatform('capacitor') && setShowLoading(true);
        try {
           const result = await FilePicker.pickVideos({limit: 1});
           const theUploads: File[] =  [];

           if (result.files.length === 1)
           {
               const file = result.files[0];
               //for native
               if (file && file.path && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const webPath = convertFileSrc(file.path);
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
               }
               //for web
               else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'video/mp4') || (file.mimeType === 'video/quicktime'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
               }
           }
           setUpload(theUploads);
       } 
       catch(error: any) {
           setMessage(t("no_files_selected"));  
           setIserror(true);
       } finally {
            setShowLoading(false);
        }
   };
      
   const pickFiles = async () => {
    isPlatform('capacitor') && setShowLoading(true);
        try {

            const result = await FilePicker.pickFiles({limit: 1});
            const theUploads: File[] = [];

            if (result.files.length === 1)
            {
                const file = result.files[0];
                //for native
                if (file && file.path && file.name && file.mimeType) {
                    const webPath = convertFileSrc(file.path);
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const blob = await fetch(webPath).then((r) => r.blob());
                        const rawFile = new File([blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
                }
                //for web
                else if (file && file.blob && file.name && file.mimeType) {
                    if ((file.mimeType === 'application/pdf') || (file.mimeType === 'application/msword') || (file.mimeType === 'application/vnd.ms-excel') || (file.mimeType === 'application/vnd.ms-powerpoint') 
                        || (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'))
                    {
                        const rawFile = new File([file.blob], file.name, {
                            type: file.mimeType,
                        });
                        theUploads.push(rawFile);
                    }
                }
            }
            setUpload(theUploads);
        } 
        catch(error: any) {
            setMessage(t("no_files_selected"));  
            setIserror(true);
        } finally {
            setShowLoading(false);
        }
    };

    const handleDepartment = (departmentID: string) => {

        setDepartment(departmentID);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/employees/getByDepartment/${departmentID}`);
                setEmployees(studs.data);
            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/employees/getAllInstitute/${authInfo.user.instituteID}`);
                setEmployees(studs.data);
            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        if (departmentID === 'all')
        {
            fetchAll();
        }
        else
        {
            fetchUp();
        }
        
    }

    const handleChecked = (id : number) => {

        let studs = [...employees];
        studs[id].isChecked = !studs[id].isChecked;
        setEmployees(studs);
        
    }

    const handleAll = () => {

        if (buttonColor === "light")
        {
            let studs = [...employees];

            for (let i=0; i < studs.length; i++)
            {
                if (studs[i].userInfo.userID !== "none")
                    studs[i].isChecked = true;
            }
            setEmployees(studs);
            setButtonColor("warning");
        }
        else
        {
            let studs = [...employees];

            for (let i=0; i < studs.length; i++)
            {
                if (studs[i].userInfo.userID !== "none")
                    studs[i].isChecked = false;
            }
            setEmployees(studs);
            setButtonColor("light");
        }
    }

    const handleSubmit = () => {

        if (!title || title === "") {
            setMessage(t("task_name_mandatory"));
            setIserror(true);
            return;
        }

        if (!priority || priority === "") {
            setMessage(t("task_priority_mandatory"));
            setIserror(true);
            return;
        }

        if (!text || text === "") {
            setMessage(t("task_info_mandatory"));
            setIserror(true);
            return;
        }

        if (!project || project === "") {
            setMessage(t("task_project_mandatory"));
            setIserror(true);
            return;
        }

        const projectName = projects.find(val => val._id === project)!.name;


        let receipients: Array < string > = [];
        for (let i = 0; i < employees.length ; i++) {
            if (employees[i].isChecked)
            receipients.push(employees[i]._id);
        }

        if(!receipients.length)
        {
            setMessage(t("pick_employees"));
            setIserror(true);
            return;
        }

        if (typeof startDate !== "string")
        {
            setMessage(t("start_date_mandatory"));
            setIserror(true);
            return;
        }

        if (typeof endDate !== "string")
        {
            setMessage(t("end_date_mandatory"));
            setIserror(true);
            return;
        }

        const fDate = new Date(startDate);
        const tDate = new Date(endDate);

        if (tDate < fDate)
        {
            setMessage(t("dates_invalid"));
            setIserror(true);
            return;
        }

        const addTask = (projectID: string, projectName: string, employees: string[]) => {
            return new Promise((resolve, reject) => {
                api.post('/staffChats/addTask', { 'instituteID': authInfo.user.instituteID, 'adminID': authInfo.user._id, employees, title, 'type': 'task', text, priority, startDate, endDate, projectID, projectName }).then(res => {
                    return resolve(res.data.title);
                }).catch(err => reject(err));
            });
        }
  
        const addAttach = (formData: FormData) => {
            return new Promise((resolve, reject) => {
                api.post('/staffChats/addTask/attach/add', formData).then(res => {
                    return resolve(res.data.title);
                }).catch(err => reject(err));
            });
        }
  
        if (upload.length > 0)
        {
              
            let formData = new FormData();

            formData.append('instituteID', authInfo.user.instituteID);
            formData.append('adminID', authInfo.user._id);
            formData.append('employees', JSON.stringify(receipients));
            formData.append('title', title);
            formData.append('type', 'task');
            formData.append('text', text);
            formData.append('priority', priority);
            formData.append('startDate', startDate);
            formData.append('endDate', endDate);
            formData.append('projectID', project);
            formData.append('projectName', projectName);
            for (let i=0; i < upload.length; i++)
            {
                formData.append('attachment[]', upload[i], `${authInfo.user.instituteID}`);
            }

            setShowLoading(true);
            addAttach(formData)
            .then(data => {
                setPass(data+t("has_been_issued"));
                setIspass(true);
            })
            .catch((error) => {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            })
            .finally(() => setShowLoading(false));
        }
        else
        {
            setShowLoading(true);
            addTask(project, projectName, receipients)
            .then(data => {
            
                setPass(data + t("has_been_issued"));
                setIspass(true);
            })
            .catch((error) => {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            })
            .finally(() => setShowLoading(false));
        }
  
    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const res = await api.get(`/departments/getAllInstitute/${authInfo.user.instituteID}`);
                setDepartments(res.data);

                const result = await api.get(`/projects/getAllInstitute/${authInfo.user.instituteID}`);
                setProjects(result.data);

                if (projectID)
                {
                    setProject(projectID);
                }
                else
                {
                    setProject("");
                }
                

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, projectID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_task")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_task")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                
                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false); 
                            history.goBack();
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("select_employees")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("select_department")}</IonLabel>
                                                    <IonSelect value={department}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("select_department")}
                                                            onIonChange={
                                                                (e) => handleDepartment(e.detail.value)
                                                        }>
                                                            <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                            {departments.map((dep) => (
                                                                (<IonSelectOption key={dep._id} value={`${dep._id}`}>{dep.name}</IonSelectOption>)
                                                            ))}
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol className='ion-padding-horizontal'>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("employees")}
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol className='ion-padding'>
                                    <div className="ion-table">
                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="7" className='table-heading'>
                                            <IonIcon 
                                                icon={checkboxOutline}
                                                color={buttonColor}
                                                className='table-icon'
                                                onClick={handleAll}
                                            />
                                            <IonText className='ion-margin-start'>{t("name")}</IonText>
                                        </IonCol>
                                        <IonCol size="4" className='table-heading'>
                                        {t("dob")}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color="light"
                                                className='table-icon'
                                                />
                                        </IonCol>
                                    </IonRow>
                                {(employees.length > 0) ? (employees.map((stud, i) => 
                                (stud.userInfo.userID !== "none") && (
                                    <IonRow key={stud._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                    
                                        <IonCol size="7" className='table-field text-capitalize' >
                                            <IonCheckbox labelPlacement="end" checked={stud.isChecked} 
                                                onClick={(e) => handleChecked(i) } 
                                            >
                                                    {stud.userInfo.name}
                                            </IonCheckbox>
                                        
                                        </IonCol>
                                        <IonCol size="4" className='table-field'>
                                            {formatDated(stud.dob)}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color="dark"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/employee/${stud._id}`)
                                                }
                                            />
                                            
                                        </IonCol>
                                
                                    </IonRow>
                                    ))) : (
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_employees")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )}
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol sizeLg="6" size="12" className='ion-padding'>
                            <IonCard>
                                <IonCardHeader>
                                        <IonCardSubtitle className='info-subtitle'>{t("task_info")}</IonCardSubtitle>
                                    </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>
                                            <IonList className="ion-no-padding">

                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    className='ion-margin-top'
                                                    >
                                                    <IonLabel position="stacked"color="secondary">{t("select_proj")}</IonLabel>
                                                        <IonSelect value={project}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("select_proj")}
                                                            onIonChange={
                                                                (e) => setProject(e.detail.value)
                                                        }>
                                                            {projects.map((dep) => (
                                                                (<IonSelectOption key={dep._id} value={`${dep._id}`}>{dep.name}</IonSelectOption>)
                                                            ))}
                                                    </IonSelect>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    className='ion-margin-top'
                                                    >
                                                    <IonLabel position="stacked" color="secondary">{t("select_priority")}</IonLabel>
                                                        <IonSelect value={priority}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                            placeholder={t("select_priority")}
                                                            onIonChange={
                                                                (e) => setPriority(e.detail.value)
                                                        }>
                                                                
                                                                    <IonSelectOption value="high">{t("high")}</IonSelectOption>  
                                                                    <IonSelectOption value="medium">{t("medium")}</IonSelectOption>  
                                                                    <IonSelectOption value="low">{t("low")}</IonSelectOption>   
                                                        </IonSelect>
                                                </IonItem>
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    className='ion-margin-top'
                                                    >
                                                    <IonLabel position="stacked" color="secondary">{t("task_name")}</IonLabel>
                                                    <IonInput type="text"
                                                            value={title}
                                                            onIonInput={
                                                                (e) => setTitle(e.detail.value!)
                                                        }></IonInput>
                                                </IonItem>
                                                
                                                <IonItem detail={false}
                                                            lines='full'
                                                            button={false}>
                                                            <IonLabel position="floating" color="secondary">{t("task_text")}</IonLabel>
                                                            <IonTextarea rows={4} value={text} onIonInput={
                                                                    (e) => setText(e.detail.value!)
                                                            }></IonTextarea>
                                                        
                                                </IonItem>

                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-date-input">
                                                    <IonLabel position="stacked" color="secondary">
                                                        {t("project_start")}
                                                    </IonLabel>
                                                    <IonInput value={formatDate(startDate)} readonly={true} />
                                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("project_start")}</IonTitle>
                                                                
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={startDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setStartDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                        
                                                    </IonModal>
                                                </IonItem>

                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-dated-input">
                                                    <IonLabel position="stacked" color="secondary">
                                                    {t("project_end")}
                                                    </IonLabel>
                                                    <IonInput value={formatDate(endDate)} readonly={true} />
                                                    <IonModal trigger="open-dated-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("project_end")}</IonTitle>
                                                                
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={endDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setEndDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                        
                                                    </IonModal>
                                                </IonItem>

                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    onClick={pickImages}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={camera}
                                                    />
                                                    <IonLabel>
                                                        {t("upload_photos")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    onClick={pickVideo}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={videocam}
                                                    />
                                                    <IonLabel>
                                                        {t("upload_video")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                <IonItem detail={true}
                                                    lines='none'
                                                    button={true}
                                                    onClick={pickFiles}
                                                    >
                                                    <IonIcon
                                                        slot="start"
                                                        icon={attach}
                                                    />
                                                    <IonLabel>
                                                        {t("attach_doc")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                {(upload.length > 0) && (
                                                <IonItem detail={true}
                                                        detailIcon={trash}
                                                        color="danger"
                                                        lines='none'
                                                        button={true}
                                                        onClick={() => {
                                                                setUpload([]);
                                                                setImageURL("");
                                                            }
                                                        }
                                                    >
                                                    <IonBadge color="light" slot="start">{upload.length}</IonBadge>
                                                    <IonLabel>
                                                        {t("remove_files")}
                                                    </IonLabel>
                                                    
                                                </IonItem>
                                                )}

                                            </IonList>
                                        </IonCol>
                                    </IonRow>
                                    {imageURL && (
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-text-center">
                                                <IonCardHeader>
                                                    <IonCardSubtitle>{t("image_preview")}</IonCardSubtitle>
                                                </IonCardHeader>
                                                <IonCardContent>
                                                
                                                    <img src={imageURL} 
                                                        alt={t("image_preview")}
                                                        width="320" 
                                                    />
                
                                                </IonCardContent>        
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )}

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("assign_task")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddTask;
