import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import { addCircle, removeCircle } from 'ionicons/icons';

interface ParamTypes {
    itemID?: string
}

interface TranItem {
    item: ItemObj,
    qty: number,
    price: number
}

interface ItemObj {
    itemID: string,
    itemName: string
}

interface Vendor {
    _id: string,
    name: string,
    due: number,
    isActive: boolean
}

interface Item {
    _id: string,
    name: string,
    buyPrice: number,
    quantity: number,
    photo?: string,
    stock: boolean,
    isActive: boolean
}

interface Store {
    _id: string,
    name: string,
    isActive: boolean
}

const BuyItem: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {itemID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [amount, setAmount] = useState < number > (0);
    const [paid, setPaid] = useState < number > (0);
    const [paidMode, setPaidMode] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > (new Date().toISOString());
    const [tags, setTags] = useState < string[] > ([]);
    const [vendors, setVendors] = useState < Vendor[] > ([]);
    const [vendorID, setVendorID] = useState < string > ("none");
    const [stores, setStores] = useState < Store[] > ([]);
    const [storeID, setStoreID] = useState < string > ("");
    const [items, setItems] = useState < Item[] > ([]);
    const [selectedItem, setSelectedItem] = useState < string > ("");
    const [tranItems, setTranItems] = useState < TranItem[] > ([]);

    const formatDate = (value: string | string[]) => {
        if (typeof(value) === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
    };

    const handleTags = (value: string) => {
        let inputTags = value.toLowerCase();
        let strArray: Array < string > = [];
        strArray = inputTags.split(" ");
        setTags(strArray);
    }

    const handleAdd = (index: number) => {

        let subs = [...tranItems];
        subs[index].qty = subs[index].qty + 1;

        setAmount(amount + subs[index].price);
        setTranItems(subs);
        
    }

    const handleSubtract = (index: number) => {

        let subs = [...tranItems];
        
        subs[index].qty = subs[index].qty - 1;

        setAmount(amount - subs[index].price);

        if (subs[index].qty === 0)
        {
            subs.splice(index, 1); // 2nd parameter means remove one item only
            setTranItems(subs);
        }
        else
        {
            setTranItems(subs);
        }
        
    }

    const handleItem = () => {
        if (selectedItem !== "")
        {
            if (tranItems.find((val) => val.item.itemID === selectedItem))
            {
                setMessage(t("item_already_added"));
                setIserror(true);
                return;
            }
            else
            {
                const theItem = items.find((val) => val._id === selectedItem)!;
                const tranItemObj = {
                    item: {
                        itemID: selectedItem,
                        itemName: theItem.name
                    },
                    qty: 1,
                    price: theItem.buyPrice
                };
                let subs = [...tranItems];
                subs.push(tranItemObj);
                setTranItems(subs);
                setAmount(amount + theItem.buyPrice);
            }
            
        }
        
    }

    const handleSelectedItem = (itemID: string) => {
        if (itemID && itemID !== "")
        {
            if (tranItems.find((val) => val.item.itemID === itemID))
            {
                setMessage(t("item_already_added"));
                setIserror(true);
                return;
            }
            else
            {
                const theItem = items.find((val) => val._id === itemID)!;
                const tranItemObj = {
                    item: {
                        itemID,
                        itemName: theItem.name
                    },
                    qty: 1,
                    price: theItem.buyPrice
                };
                let subs = [...tranItems];
                subs.push(tranItemObj);
                setTranItems(subs);
                setAmount(amount + theItem.buyPrice);
                setSelectedItem(itemID);
            }
            
        }
        
    }

    const handleStore = (selectedStore: string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/items/getAllStore/${selectedStore}`);
                setItems(result.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchUp();
        setStoreID(selectedStore);
        setSelectedItem("");
    }

    const handleSubmit = () => {

        if ((vendorID === "none") || (paid > 0))
        {
            if (!paidMode || paidMode === "") {
                setMessage(t("payment_mode_mandatory"));
                setIserror(true);
                return;
            }
        }

        if (!tranItems.length)
        {
            setMessage(t("item_mandatory"));
            setIserror(true);
            return;
        }

        if (!amount || amount === 0) {
            setMessage(t("tran_amount_mandatory"));
            setIserror(true);
            return;
        }

        let vendorName = "none";
        let amt = amount;
        if (vendorID !== "none")
        {
            vendorName = vendors.find((val) => val._id === vendorID)!.name;
            amt = paid;
        }

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const buyTran = (dated: string | string[], amt: number) => {
            return new Promise((resolve, reject) => {
              api.post('/itemTransactions/buy', { 'instituteID': authInfo.user.instituteID, 'adminID': authInfo.user._id, 'items': tranItems, vendorID, vendorName, amount, paid: amt, paidMode, dated, tags}).then(res => {
        
                return resolve(res.data);
                
              }).catch(err => reject(err));
    
            });
          }
  
            setShowLoading(true);
            buyTran(selectedDate, amt)
            .then(data => {
            
                setPass(t("items_purchased"));
                setIspass(true);
                setShowLoading(false);
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
        
                setIserror(true);
                setShowLoading(false);
            });
        
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/stores/getAllInstitute/${authInfo.user.instituteID}`);
                setStores(res.data);

                const result = await api.get(`/vendors/getAllInstitute/${authInfo.user.instituteID}`);
                setVendors(result.data);

                if (itemID)
                {
                    const theItem = await api.get('/items/' + itemID);
                    setStoreID(theItem.data.store.storeID);
                    const theItems = await api.get(`/items/getAllStore/${theItem.data.store.storeID}`);
                    setItems(theItems.data);
                    setSelectedItem(itemID);

                    const tranItemObj = {
                        item: {
                            itemID,
                            itemName: theItem.data.name
                        },
                        qty: 1,
                        price: theItem.data.buyPrice
                    };

                    setTranItems([tranItemObj]);
                    setAmount(theItem.data.buyPrice);
                
                }

                setShowLoading(false);
            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, itemID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/inventory`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("buy_item")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("buy_item")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/inventory`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>


                <IonGrid className="ion-no-padding">

                <IonRow className="max-xxl">
                    <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                        <IonRow className="mt-30">
                            <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("item_details")}
                                    </IonTitle>
                            </IonCol>
                        </IonRow>
                        
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                <IonRow>
                                    <IonCol size="6">
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("tran_store")}</IonLabel>
                                                        <IonSelect value={storeID}
                                                                className="input-field"
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                placeholder={t("tran_store")}
                                                                onIonChange={
                                                                    (e) => handleStore(e.detail.value)
                                                            }>

                                                                {stores.map((store) => (
                                                                    (store.isActive) && 
                                                                    (<IonSelectOption key={store._id} value={store._id}>{store.name}</IonSelectOption>)
                                                                ))}

                                                        </IonSelect>
                                                    </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("tran_item")}</IonLabel>
                                                        <IonSelect value={selectedItem}
                                                                className="input-field"
                                                                cancelText={t("cancel")}
                                                                okText={t("ok")}
                                                                placeholder={t("tran_item")}
                                                                onIonChange={
                                                                    (e) => handleSelectedItem(e.detail.value)
                                                            }>
                                                                
                                                                {items.map((item) => (
                                                                    (item.isActive) && (item.stock) &&
                                                                    (<IonSelectOption key={item._id} value={item._id}>{item.name}</IonSelectOption>)
                                                                ))}
                                                        </IonSelect>
                                                    </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>

                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="ion-padding-horizontal">
                                <IonButton expand="block" color="dark" disabled={(selectedItem === "")} onClick={() => handleItem()}>{t("add_item")}</IonButton> 

                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                        
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardSubtitle className='info-subtitle'>
                                            <IonRow>
                                                <IonCol size="4.5">
                                                    {t("item_name")}
                                                </IonCol>
                                                <IonCol size="3.5">
                                                    {t("item_price")}
                                                </IonCol>
                                                <IonCol size="4" className='ion-text-center'>
                                                    {t("quantity")}
                                                </IonCol>
                                            </IonRow>
                                        </IonCardSubtitle>
                                    </IonCardHeader>
                                    <IonCardContent className="ion-no-padding ion-padding-bottom">
                                        {(tranItems.length > 0) && tranItems.map((item, i) => (
                                        <IonRow key={item.item.itemID} className='border-top-sec ion-padding-horizontal pt-08'>
                                            <IonCol size="4.5" className='mt-15'>
                                                {item.item.itemName}
                                            </IonCol>
                                            <IonCol size="3.5" className='mt-15'>
                                            &#8377; {item.price}
                                            </IonCol>
                                            <IonCol size="4" >
                                            
                                                <IonItem detail={false}
                                                    lines='none'
                                                    button={false}
                                                    className='ion-text-center ion-no-padding'
                                                    >
                                                    <IonIcon icon={removeCircle} size="large" onClick={() => handleSubtract(i)}></IonIcon>
                                                    <IonInput type="number"
                                                        value={item.qty}
                                                        readonly={true}></IonInput>
                                                    <IonIcon icon={addCircle} size="large" onClick={() => handleAdd(i)}></IonIcon>
                                                    
                                                </IonItem>
                                            </IonCol>
                                            
                                        </IonRow>
                                        ))}
                                    </IonCardContent>
                                </IonCard>
                                        
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                        <IonRow className="mt-30">
                            <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("tran_details")}
                                    </IonTitle>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding">
                                        <IonItem>
                                            
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("tran_vendor")}*
                                            </IonLabel>
                                                <IonSelect value={vendorID}
                                                    className="input-field" 
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("tran_vendor")}
                                                    onIonChange={
                                                        (e) => setVendorID(e.detail.value)
                                                }>
                                                    <IonSelectOption value="none" >{t("none")}</IonSelectOption>
                                                    { vendors.map((vendor) => (
                                                        (<IonSelectOption key={vendor._id} value={vendor._id}>{vendor.name}</IonSelectOption>)
                                                    ))}
                                                    
                                                </IonSelect>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding">
                                        <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                id="open-date-input">
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("tran_date")}*
                                                </IonLabel>
                                                <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                                <IonModal trigger="open-date-input" showBackdrop={true} 
                                                    initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                    <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                        <IonToolbar>
                                                            <IonTitle>{t("tran_date")}</IonTitle>
                                                            
                                                        </IonToolbar>
                                                        <IonDatetime
                                                            value={selectedDate}
                                                            presentation="date"
                                                            cancelText={t("cancel")}
                                                            doneText={t("done")}
                                                            onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                            showDefaultButtons={true}
                                                            size="cover"
                                                        />
                                                    </IonContent>
                                                    
                                                </IonModal>
                                            </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("amount_paid")}*
                                                </IonLabel>
                                            <IonInput type="number"
                                                value={(vendorID === "none") ? amount : paid}
                                                placeholder={t("amount_paid")}
                                                className="input-field" 
                                                disabled={vendorID === "none"}
                                                onIonInput={
                                                    (e) => e.detail.value && setPaid(Math.abs(parseInt(e.detail.value)))
                                            }></IonInput>
                                        </IonItem>
                                        
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        {((vendorID === "none") || (paid > 0)) && (
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding">
                                        <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("tran_mode")}*
                                                </IonLabel>
                                                <IonSelect placeholder={t("tran_mode")}
                                                    className='input-field' 
                                                    style={{'maxWidth': '100%'}} 
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    value={paidMode}
                                                    onIonChange={
                                                        (e) => setPaidMode(e.detail.value!)
                                                    }
                                                    >
                                                    <IonSelectOption value="cash">{t("cash")}</IonSelectOption>   
                                                    <IonSelectOption value="online">{t("online")}</IonSelectOption>   
                                                    
                                                </IonSelect>
                                            </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )}
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("tran_tags")}
                                                </IonLabel>
                                            <IonInput type="text"
                                                placeholder={t("tran_tags")}
                                                className="input-field" 
                                                value={tags.join(' ')}
                                                onIonInput={
                                                    (e) => handleTags(e.detail.value!)
                                            }></IonInput>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-padding-bottom'>
                            <IonCol className="ion-padding-horizontal">
                                <IonText className='info-text'>
                                    {t("tags_info")}
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol size="4" className='ion-text-center'>
                               
                                    <p className='checkout-label'>
                                    {t("total_amount")}
                                    </p>
                                    <p className='checkout-amt'>
                                    &#8377; {amount}
                                    </p>

                        </IonCol>
                        <IonCol size="8" className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                >{t("buy_items")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
            
        </IonPage>
    );
};

export default BuyItem;
