import {
    ActionSheetButton,
    IonActionSheet,
    IonAlert,
    IonAvatar,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonProgressBar,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import { accessibilityOutline, calendarOutline, cashOutline, chatboxEllipses, chatbubbles, chatbubblesOutline, chevronForward, closeOutline, create, documentsOutline, idCardOutline, medicalOutline, notifications, qrCodeOutline, sparkles, time } from 'ionicons/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { BarcodeScanner, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning';
import { InAppBrowser, InAppBrowserObject, InAppBrowserOptions } from '@ionic-native/in-app-browser';
import { SendIntent } from "send-intent";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Scrollbar } from 'swiper/modules';
import { useAuth } from "../../components/AuthContext";
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import SwitchBranch from '../../components/SwitchBranch';

import 'swiper/css';
import 'swiper/css/scrollbar';
import "swiper/css/autoplay";
import '@ionic/react/css/ionic-swiper.css';

interface Student {
    _id: string,
    name: string,
    photo: string,
    dob: Date
}

interface Event {
    _id: string,
    title: string,
    description: string,
    media?: string,
    calendarDate: Date
}

interface Inquiry {
    _id: string,
    title: string,
    type: string,
    student?: Stud,
    students?: string[],
    createdAt: Date
}

interface Stud {
    studentID: string,
    name: string
}

interface Chat {
    _id: string,
    title: string,
    type: string,
    status: string,
    employee?: ChatEmployee,
    employees?: string[],
    taskInfo?: TaskInfo,
    createdAt: Date,
    updatedAt: Date
}

interface TaskInfo {
    startDate: Date,
    endDate: Date,
    priority: string
}

interface ChatEmployee {
    employeeID: string,
    name: string
}

interface AdmnInquiry {
    _id: string,
    status: string,
    type: string,
    userInfo: User,
    updatedAt: Date
}

interface User {
    userID: string,
    name: string,
    phone: string
}

interface StudentAttendance {
    _id: string,
    studentID: string,
    studentName: string,
    status: string,
    createdAt: Date
}

interface StaffAttendance {
    _id: string,
    employee: Employee,
    status: string,
    createdAt: Date
}

interface Employee {
    employeeID: string,
    name: string
}

const AdminHome: React.FC = () => {
    const {authInfo, logOut} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [showDownloading, setShowDownloading] = useState < boolean > (false);
    const [isexpired, setIsexpired] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [isold, setIsold] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [transport, setTransport] = useState < boolean > (false);
    const [comm, setComm] = useState < boolean > (false);
    const [payment, setPayment] = useState < boolean > (false);
    const [what, setWhat] = useState < boolean > (false);
    const [admn, setAdmn] = useState < boolean > (false);
    const [invent, setInvent] = useState < boolean > (false);
    const [acad, setAcad] = useState < boolean > (false);
    const [medical, setMedical] = useState < boolean > (false);
    const [timetable, setTimetable] = useState < boolean > (false);
    const [reportcard, setReportCard] = useState < boolean > (false);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [students, setStudents] = useState < Student[] > ([]);
    const [events, setEvents] = useState < Event[] > ([]);
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);
    const [admnInquiries, setAdmnInquiries] = useState < AdmnInquiry[] > ([]);
    const [chats, setChats] = useState < Chat[] > ([]);
    const [tasks, setTasks] = useState < Chat[] > ([]);
    const [studentAttend, setStudentAttend] = useState < StudentAttendance[] > ([]);
    const [staffAttend, setStaffAttend] = useState < StaffAttendance[] > ([]);
    const [sharedFile, setSharedFile] = useState < string > ("");
    const [student, setStudent] = useState < Student > ();
    const [actionButtons, setActionButtons] = useState < ActionSheetButton[] > ([]);
    const [showActionSheet, setShowActionSheet] = useState < boolean > (false);
    const [scannerActive, setScannerActive] = useState < boolean > (false);

    const [total, setTotal] = useState < number > (0);
    const [studentsPresent, setStudentsPresent] = useState < number > (0);
    const [studentsWithdrawn, setStudentsWithdrawn] = useState < number > (0);

    const [subjects, setSubjects] = useState < number > (0);
    const [male, setMale] = useState < number > (0);
    const [female, setFemale] = useState < number > (0);

    const [circulars, setCirculars] = useState < number > (0);
    const [opened, setOpened] = useState < number > (0);
    const [processed, setProcessed] = useState < number > (0);

    const [trips, setTrips] = useState < number > (0);
    const [drivers, setDrivers] = useState < number > (0);
    const [routes, setRoutes] = useState < number > (0);

    const [dues, setDues] = useState < number > (0);
    const [payments, setPayments] = useState < number > (0);
    const [expenses, setExpenses] = useState < number > (0);

    const [pendingApps, setPendingApps] = useState < number > (0);
    const [totalApps, setTotalApps] = useState < number > (0);
    const [fees, setFees] = useState < number > (0);

    const [staff, setStaff] = useState < number > (0);
    const [staffPresent, setStaffPresent] = useState < number > (0);
    const [staffLeave, setStaffLeave] = useState < number > (0);
    
    const [incidentsToday, setIncidentsToday] = useState < number > (0);
    const [incidentsMonth, setIncidentsMonth] = useState < number > (0);
    const [incidents, setIncidents] = useState < number > (0);

    const [totalItems, setTotalItems] = useState < number > (0);
    const [outOfStock, setOutOfStock] = useState < number > (0);
    const [soldMonth, setSoldMonth] = useState < number > (0);

    const [newLeads, setNewLeads] = useState < number > (0);
    const [openLeads, setOpenLeads] = useState < number > (0);
    const [convertedLeads, setConvertedLeads] = useState < number > (0);

    const [classes, setClasses] = useState < number > (0);
    const [eventsNo, setEventsNo] = useState < number > (0);
    const [notifs, setNotifs] = useState < number > (0);

    const [unread, setUnread] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const options: InAppBrowserOptions = {
        location: 'no',
        hidden: 'no',
        zoom: 'yes',
        toolbar: 'yes',
        hidenavigationbuttons: 'yes',
        toolbarposition: 'bottom',
        closebuttoncaption: 'Close'
    }

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const openURL = (value: string) => {
        let browser : InAppBrowserObject;

        // _blank for iphone, _self for others
        if (isPlatform('capacitor') && isPlatform('ios'))
        {
            browser = InAppBrowser.create(String(value), '_blank', options);
        }
        else
        {
            browser = InAppBrowser.create(String(value), '_self', options);
        }
    };

    const openScanner = async () => {
        try {
            const status = await BarcodeScanner.checkPermissions();

            if (status.camera !== 'granted')
            {
                const res = await BarcodeScanner.requestPermissions();
                if (res.camera === 'denied')
                {
                    setMessage(t("camera_denied"));
                    setIserror(true);
                }
                else if (res.camera === 'granted') {
                    document.body.classList.add('scanner-active');
                    document.getElementById("ongoing-trip")?.classList.add('hide');
                    setScannerActive(true);

                    // Add the `barcodeScanned` listener
                    const listener = await BarcodeScanner.addListener(
                        'barcodeScanned',
                        async (result) => {
                            await listener.remove();
                            document.body.classList.remove('scanner-active');
                            document.getElementById("ongoing-trip")?.classList.remove('hide');
                            setScannerActive(false);
                            await BarcodeScanner.stopScan();
                            handleScan(result.barcode.displayValue);
                        },
                    );

                    await BarcodeScanner.startScan({ formats: [BarcodeFormat.QrCode] });
                    
                }
            }
            else
            {
                document.body.classList.add('scanner-active');
                document.getElementById("ongoing-trip")?.classList.add('hide');
                setScannerActive(true);

                // Add the `barcodeScanned` listener
                const listener = await BarcodeScanner.addListener(
                    'barcodeScanned',
                    async (result) => {
                        await listener.remove();
                        document.body.classList.remove('scanner-active');
                        document.getElementById("ongoing-trip")?.classList.remove('hide');
                        setScannerActive(false);
                        await BarcodeScanner.stopScan();
                        handleScan(result.barcode.displayValue);
                    },
                );

                await BarcodeScanner.startScan({ formats: [BarcodeFormat.QrCode] });
            }

        } catch (error: any) {
            setMessage(error.message);
            setIserror(true);
        }
        
    };

    const stopScan = async () => {
        document.body.classList.remove('scanner-active');
        document.getElementById("ongoing-trip")?.classList.remove('hide');
        setScannerActive(false);
        await BarcodeScanner.removeAllListeners();
        await BarcodeScanner.stopScan();
    };

    const handleScan = async (data : string) => {

        const studentID = data.split("/").pop();

        if (studentID && studentID.match(/^[0-9a-fA-F]{24}$/))
        {
            try {
                const result = await api.get('/students/' + studentID);
                
                if(result.data)
                {
                    setStudent(result.data);

                    let cObjects: ActionSheetButton[] = [];

                    cObjects.push({
                        text: t("view_profile"),
                        icon: !isPlatform('ios') ? accessibilityOutline : undefined,
                        handler: () => history.push(`/app/student/${studentID}`)
                    });

                    if (((authInfo.institute._id === "623b78f1bbe7171e30738c7a") || (authInfo.institute._id === "623b7220bbe7171e30738a7c") || (authInfo.institute._id === "623b7808bbe7171e30738c74") || (authInfo.institute._id === "623b784fbbe7171e30738c77") ) && result.data.admnNo && (result.data.admnNo !== ""))
                    {
                        cObjects.push({
                            text: t("view_official_card"),
                            icon: !isPlatform('ios') ? idCardOutline : undefined,
                            handler: () => openURL(`https://softwareportal.gen.in/teacher/printidcard4app.php?id=${result.data.admnNo}`)
                        });
                    } 

                    cObjects.push({
                        text: t("view_ledger"),
                        icon: !isPlatform('ios') ? cashOutline : undefined,
                        handler: () => history.push(`/app/student/ledger/${studentID}`)
                    });

                    cObjects.push({
                        text: t("view_communications"),
                        icon: !isPlatform('ios') ? chatbubblesOutline : undefined,
                        handler: () => history.push(`/app/inquiries/student/${studentID}`)
                    });

                    cObjects.push({
                        text: t("view_attendance"),
                        icon: !isPlatform('ios') ? calendarOutline : undefined,
                        handler: () => history.push(`/app/student/attendance/${studentID}`)
                    });

                    if (!authInfo.institute.timetable)
                    {
                        cObjects.push({
                            text: t("view_submissions"),
                            icon: !isPlatform('ios') ? documentsOutline : undefined,
                            handler: () => history.push(`/app/submissions/student/${studentID}`)
                        });
                    } 

                    if (authInfo.institute.medical)
                    {
                        cObjects.push({
                            text: t("view_records"),
                            icon: !isPlatform('ios') ? medicalOutline : undefined,
                            handler: () => history.push(`/app/student/incidents/${studentID}`)
                        });
                    }

                    cObjects.push({
                        text: t('cancel'),
                        icon: !isPlatform('ios') ? closeOutline : undefined,
                        role: 'cancel'
                    });

                    setActionButtons(cObjects);

                    setShowActionSheet(true);
                }
                else
                {
                    setMessage(t("qr_error"));
                    setIserror(true);
                }
            } catch {
                setMessage(t("qr_error"));
                setIserror(true);
            }

        }
        else 
        {
            setMessage(t("qr_error"));
            setIserror(true);
        }
        
    }
   
    useEffect(() => {

        const upUser = async (push: Token) => {
            try {
                const response = await api.put(`/users/${authInfo.user._id}`, { push: push.value });
                return response.data;
            } catch (error) {
                throw error;
            }
        };

        const register = async () => {
            try {
                // Register with Apple / Google to receive push notifications
                await PushNotifications.register();
    
                // On success, we should be able to receive notifications
                await PushNotifications.addListener('registration', async (token: Token) => {
                    if (!authInfo.user.push || authInfo.user.push !== token.value) {
                        await upUser(token);
                    }
                });
    
                // Handle registration errors
                await PushNotifications.addListener('registrationError', () => {
                    setMessage(t("push_error"));
                    setIserror(true);
                });
            } catch (err) {
                console.error('Error in register function:', err);
            }
        };

        const fetchData = async () => {
            try {
                const instituteData = authInfo.institute;
                setType(instituteData.type);
                setTransport(instituteData.transport);
                setComm(instituteData.communication);
                setPayment(instituteData.payment);
                setAdmn(instituteData.admission);
                setInvent(instituteData.inventory);
                setAcad(instituteData.academics);
                setWhat(instituteData.whatsapp);
                setMedical(instituteData.medical);
                setTimetable(instituteData.timetable);
                setReportCard(instituteData.reportcard);
    
                const stats = await api.get(`/institutes/stats/${authInfo.user.instituteID}`);
                const statsData = stats.data;
    
                setSubjects(statsData.subjects);
                setMale(statsData.male);
                setFemale(statsData.female);
                setClasses(statsData.classes);
                setEventsNo(statsData.events);
                setNotifs(statsData.notifs);
                setTotal(statsData.students);
                setStudentsPresent(statsData.present);
                setStudentsWithdrawn(statsData.withdrawn);
                setStaffPresent(statsData.staffPresent);
                setStaffLeave(statsData.staffLeave);
                setStaff(statsData.employees);
                setCirculars(statsData.circulars);
                setOpened(statsData.open);
                setProcessed(statsData.closed);
                setDrivers(statsData.drivers);
                setRoutes(statsData.routes);
                setTrips(statsData.trips);
                setDues(statsData.dues);
                setPayments(statsData.payments);
                setExpenses(statsData.expenses);
                setTotalApps(statsData.applications);
                setPendingApps(statsData.pending);
                setFees(statsData.fees);
                setIncidentsToday(statsData.incidentToday);
                setIncidentsMonth(statsData.incidentMonth);
                setIncidents(statsData.incidents);
                setTotalItems(statsData.totalItems);
                setOutOfStock(statsData.outOfStock);
                setSoldMonth(statsData.soldMonth);
                setNewLeads(statsData.newLeads);
                setOpenLeads(statsData.openLeads);
                setConvertedLeads(statsData.convertedLeads);
    
                const [birthday, events, inquiries, chats, admnInquiries, studentAttend, staffAttend, tasks, notificationsCount] = await Promise.all([
                    api.get(`/students/getBirthdaySchool/${authInfo.user.instituteID}`),
                    api.get(`/feeds/getEventsByInstitute/${authInfo.user.instituteID}`),
                    api.get(`/inquiries/getSomePendingInstitute/${authInfo.user.instituteID}`),
                    api.get(`/staffChats/getSomeOpenInstitute/${authInfo.user.instituteID}`),
                    api.get(`/admissionInquiries/getSomePendingInstitute/${authInfo.user.instituteID}`),
                    api.get(`/attendances/getByInstitute/${authInfo.user.instituteID}`),
                    api.get(`/staffAttends/getLogInstitute/${authInfo.user.instituteID}`),
                    authInfo.institute.task ? api.get(`/staffChats/getSomeTaskInstitute/${authInfo.user.instituteID}`) : Promise.resolve({ data: [] }),
                    api.get(`/notifications/getCountUser/${authInfo.user._id}`)
                ]);
    
                setStudents(birthday.data);
                setEvents(events.data);
                setInquiries(inquiries.data);
                setChats(chats.data);
                setAdmnInquiries(admnInquiries.data);
                setStudentAttend(studentAttend.data.attends);
                setStaffAttend(staffAttend.data.attends);
                setTasks(tasks.data);
                setUnread(notificationsCount.data);
    
            } catch (error: any) {
                if (error.response) {
                    if (error.response.data.message === 'expired') {
                        setIsexpired(true);
                    } else {
                        setMessage(error.response.data.message);
                        setIserror(true);
                    }
                } else {
                    setMessage(t("unable_to_connect"));
                    setIserror(true);
                }
            }
        };
        
        const checkIntent = async () => {
            try {
                const result = await SendIntent.checkSendIntentReceived();
                if (result && result.url) {
                    setSharedFile(encodeURIComponent(result.url));
                }
            } catch (err) {
                console.error('Error checking intent:', err);
            }
        };

        const fetchVersion = async () => {
            setShowDownloading(true);
            try {
                const versionRes = await api.get(`/version-check`);
                const currentVersion = parseInt(process.env.REACT_APP_VERSION!);
    
                if (parseInt(versionRes.data) > currentVersion) {
                    setIsold(true);
                } else {

                    if (isPlatform('android') && isPlatform('capacitor')) {
                        await checkIntent();
                    }

                    await fetchData();
                
                    if (isPlatform('capacitor')) {
                        const permissionRes = await PushNotifications.checkPermissions();
                        if (permissionRes.receive !== 'granted') {
                            const requestRes = await PushNotifications.requestPermissions();
                            if (requestRes.receive === 'granted') {
                                await register();  // Register for push notifications
                            }
                        } else {
                            await register();  // Already has permission
                        }
                    }
                }

                
            } catch (error: any) {  
                if (error.response) {
                    if (error.response.data.message === 'expired') {
                        setIsexpired(true);
                    } else {
                        setMessage(error.response.data.message);
                        setIserror(true);
                    }
                } else {
                    setMessage(t("unable_to_connect"));
                    setIserror(true);
                }

            } finally {
                setShowDownloading(false);
            }
        };

        fetchVersion();

        return () => {
            if (isPlatform('capacitor')) {
                stopScan();
            }
        };

    }, [authInfo, refreshed]);

    if(sharedFile && sharedFile !== "")
    {
       return (<Redirect to={`/app/bills/add/${sharedFile}`} />);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    <IonButtons slot="end">
                        {!isPlatform('mobile') && (
                        <>
                            <ThemeToggle />
                            <LanguageToggle />
                        </>
                        )}
                         <IonButton onClick={() => history.push('/app/user/notification')} fill="clear" className='ion-no-padding badge-button'> 
                            <IonIcon icon={notifications} color="secondary" className="badge-icon" />
                            {(unread !== 0) && (<span className="badge-label">{unread}</span>)}
                        </IonButton>
                        {(isPlatform("capacitor")) && !scannerActive && (
                        <IonButton onClick={openScanner} >
                            <IonIcon 
                                icon={qrCodeOutline}
                                color="warning" />
                        </IonButton>
                        )}
                        {scannerActive && (
                        <IonButton onClick={stopScan} >
                            <IonIcon 
                                icon={closeOutline}
                                color="warning" />
                        </IonButton>
                        )}
                    </IonButtons>
                    {showDownloading && (
                        <IonProgressBar type="indeterminate"></IonProgressBar>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" id="ongoing-trip">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isexpired}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/login");
                    }}
                    header={t("session_expired")}
                    message={t("login_again")}
                    buttons={[`${t("logout")}`]}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isold}
                    backdropDismiss={false}
                    onDidDismiss={
                        () => document.location.href = isPlatform('android') ? process.env.REACT_APP_PLAY_STORE! : process.env.REACT_APP_APP_STORE!
                    }
                    header={t("outdated_version")}
                    message={t("outdated_msg")}
                    buttons={
                        [`${t("update_app")}`]
                    }
                />

                <IonActionSheet isOpen={showActionSheet}
                    onDidDismiss={
                        () => setShowActionSheet(false)
                    }
                    cssClass='swap-sheet'
                    subHeader={`${t("currently_selected")} - ${student?.name}`}
                    header={t("manage_student")}
                    buttons={actionButtons} 
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
               
               {(isPlatform("capacitor")) && (
                <IonGrid>
                    <IonRow className='mt-30'>
                        <IonCol size="4">
                            <IonCard className="dash-card-4 ion-no-margin" routerLink={`/app/essentials`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={(type === "school") ? t("school_essentials") : t("institute_essentials")} src="assets/images/school.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                            {(type === "school") ? t("school_essentials") : t("institute_essentials")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard className="dash-card-9 ion-no-margin" routerLink={`/app/student`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("manage_students")} src="assets/images/children.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("manage_students")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard className="dash-card-6 ion-no-margin" routerLink={`/app/staff`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("manage_staff")} src="assets/images/teacher.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("manage_staff")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        {comm && (
                        <IonCol size="4">
                            <IonCard className="dash-card-2 ion-no-margin" routerLink={`/app/communication`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("communications")} src="assets/images/announce.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding ion-text-nowrap">
                                                {t("communications")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                        {transport && (
                        <IonCol size="4">
                            <IonCard className="dash-card-8 ion-no-margin" routerLink={`/app/transport`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("transport")} src="assets/images/transport.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("transport")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                         {payment && (
                        <IonCol size="4">
                            <IonCard className="dash-card-3 ion-no-margin" routerLink={`/app/accounting`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("accounting")} src="assets/images/payments.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                            {t("accounting")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                        {acad && (
                        <IonCol size="4">
                            <IonCard className="dash-card-1 ion-no-margin" routerLink={`/app/academics`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("academics")} src="assets/images/subjects.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("academics")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                        {invent && (
                        <IonCol size="4">
                            <IonCard className="dash-card-7 ion-no-margin" routerLink={`/app/inventory`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("inventory")} src="assets/images/inventory.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("inventory")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                        {what && (
                        <IonCol size="4">
                            <IonCard className="dash-card-5 ion-no-margin" routerLink={`/app/crm`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding ion-text-center">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("crm")} src="assets/images/inquiries.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("crm")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                    </IonRow>
                </IonGrid>
                )}
                <IonGrid className="ion-margin-top">
                    <IonRow>
                        <IonCol className='ion-no-padding'>
                            <Swiper 
                                 modules={[Autoplay, Scrollbar]}
                                 slidesPerView={1}
                                 spaceBetween={10}
                                 initialSlide={0}
                                 scrollbar={{el: '.swiper-scrollbar', draggable: true}}
                                 loop={false}         
                                 autoplay={{
                                     delay: 3000
                                 }}
                                 breakpoints={{
                                     768: {
                                      slidesPerView: 2,
                                      spaceBetween: 20
                                     },
                                     1200: {
                                      slidesPerView: 3,
                                      spaceBetween: 30
                                     }
                                 }}
                                >

                                     {/** 
                                     <SwiperSlide>
                                        <IonCard className="dash-card dash-bg-5" onClick={() => history.push(`/app/essentials`)} >
                                            <IonCardContent className="ion-padding">
                                                <IonRow>
                                                    <IonCol size="9" className="dash-title font-color-6">         
                                                        {t("essentials_overview")}
                                                    </IonCol>
                                                    <IonCol size="3" className='dash-icon'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <img alt={t("essentials_overview")} src="assets/images/class-stats.png" />
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top">
                                                    <IonCol>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {(type === "school") ? t("total_classes") : t("total_batches")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {classes}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("total_events")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {eventsNo}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("notifications_sent")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {notifs}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </SwiperSlide> 
                                    */} 
                                    <SwiperSlide>
                                        <IonCard className="dash-card dash-bg-2" onClick={() => history.push(`/app/student`)} >
                                            <IonCardContent className="ion-padding">
                                                <IonRow>
                                                    <IonCol size="9" className="dash-title text-dark">         
                                                        {t("students_overview")}
                                                    </IonCol>
                                                    <IonCol size="3" className='dash-icon'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <img alt={t("students_overview")} src="assets/images/students.png" />
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top">
                                                    <IonCol>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("total_no_students")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {total}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("students_present")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {studentsPresent}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("students_withdrawn")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {studentsWithdrawn}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </SwiperSlide>  
                                    <SwiperSlide>
                                        <IonCard className="dash-card dash-bg-1" onClick={
                                                () => history.push(`/app/staff`)
                                            }>
                                            <IonCardContent className="ion-padding">
                                                <IonRow>
                                                    <IonCol size="9" className="dash-title text-dark">         
                                                        {t("staff_overview")}
                                                    </IonCol>
                                                    <IonCol size="3" className='dash-icon'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <img alt={t("staff_overview")} src="assets/images/staff-stats.png" />
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top">
                                                    <IonCol>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("total_staff")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {staff}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("staff_present")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {staffPresent}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("staff_on_leave")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {staffLeave}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                
                                                </IonRow>
                                            
                                            </IonCardContent>
                                        </IonCard>
                                    </SwiperSlide>
                                    {comm && false && (
                                    <SwiperSlide>
                                        <IonCard className="dash-card dash-bg-3" onClick={
                                                                    () => history.push(`/app/communication`)
                                                                }>
                                            <IonCardContent className="ion-padding">
                                                <IonRow>
                                                    <IonCol size="9" className="dash-title  text-dark">         
                                                        {t("comm_overview")}
                                                    </IonCol>
                                                    <IonCol size="3" className='dash-icon'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <img alt={t("comm_overview")} src="assets/images/circular-1.png" />
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top">
                                                    <IonCol>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("circular_issued")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {circulars}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("open_inquiries")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {opened}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("closed_inquiries")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {processed}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    
                                                </IonRow>
                                            
                                            </IonCardContent>
                                        </IonCard>
                    
                                    </SwiperSlide>
                                    )}
                                    {transport && (
                                    <SwiperSlide>
                                        <IonCard className="dash-card dash-bg-4" onClick={
                                                                    () => history.push(`/app/transport`)
                                                                }>
                                            <IonCardContent className="ion-padding">
                                                <IonRow>
                                                    <IonCol size="9" className="dash-title text-dark">         
                                                        {t("trans_overview")}
                                                    </IonCol>
                                                    <IonCol size="3" className='dash-icon'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <img alt={t("trans_overview")} src="assets/images/transport-stats.png" />
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top">
                                                    <IonCol>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("ongoing_trips")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {trips}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("total_drivers")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {drivers}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("total_routes")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {routes}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    
                                                </IonRow>
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    </SwiperSlide>
                                    )}
                                    {payment && (
                                    <SwiperSlide>
                                        <IonCard className="dash-card dash-bg-6" onClick={
                                                                    () => history.push(`/app/accounting`)
                                                                }>
                                            <IonCardContent className="ion-padding">
                                                <IonRow>
                                                    <IonCol size="9" className="dash-title text-dark">         
                                                        {t("account_overview")}
                                                    </IonCol>
                                                    <IonCol size="3" className='dash-icon'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <img alt={t("account_overview")} src="assets/images/finance-stats.png" />
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top">
                                                    <IonCol>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("pending_dues")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {dues}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("collected_today")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {payments}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("expenses_today")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {expenses}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    
                                                </IonRow>
                                            
                                            </IonCardContent>
                                        </IonCard>
                                    </SwiperSlide>
                                    )}
                                    {what && ( 
                                    <SwiperSlide>
                                        <IonCard className="dash-card dash-bg-5" onClick={
                                                                    () => history.push(`/app/crm`)
                                                                }>
                                            <IonCardContent className="ion-padding">
                                                <IonRow>
                                                    <IonCol size="9" className="dash-title text-dark">         
                                                        {t("enquiries_overview")}
                                                    </IonCol>
                                                    <IonCol size="3" className='dash-icon'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <img alt={t("enquiries_overview")} src="assets/images/admn-stats.png" />
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top">
                                                    <IonCol>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("new_leads")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {newLeads}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("open_leads")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {openLeads}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("leads_converted")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {convertedLeads}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                
                                                </IonRow>
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    </SwiperSlide>
                                    )}
                                    {admn && false && ( 
                                    <SwiperSlide>
                                        <IonCard className="dash-card dash-bg-3" onClick={
                                                                    () => history.push(`/app/admission`)
                                                                }>
                                            <IonCardContent className="ion-padding">
                                                <IonRow>
                                                    <IonCol size="9" className="dash-title text-dark">         
                                                        {t("admn_overview")}
                                                    </IonCol>
                                                    <IonCol size="3" className='dash-icon'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <img alt={t("admn_overview")} src="assets/images/form-1.png" />
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top">
                                                    <IonCol>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("pending_apps")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {pendingApps}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("total_apps")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {totalApps}
                                                            </IonCol>
                                                        </IonRow>
                                                        
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("total_fees")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {fees}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                
                                                </IonRow>
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    </SwiperSlide>
                                    )}
                                    
                                    {acad && false && (
                                    <SwiperSlide>
                                        <IonCard className="dash-card dash-bg-2" onClick={
                                                () => history.push(`/app/academics`)
                                            }>
                                            <IonCardContent className="ion-padding">
                                                <IonRow>
                                                    <IonCol size="9" className="dash-title text-dark">         
                                                        {t("acad_overview")}
                                                    </IonCol>
                                                    <IonCol size="3" className='dash-icon'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <img alt={t("acad_overview")} src="assets/images/attendance.png" />
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top">
                                                    <IonCol>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {timetable ? t("total_subjects") : t("total_courses")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {subjects}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {reportcard ? t("report_issued") : t("certi_issued")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {male}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("total_submissions")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {female}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                
                                                
                                                </IonRow>
                                            
                                            </IonCardContent>
                                        </IonCard>
                                    </SwiperSlide>
                                    )}
                                    {invent && (
                                    <SwiperSlide>
                                        <IonCard className="dash-card dash-bg-1" onClick={
                                                                    () => history.push(`/app/inventory`)
                                                                }>
                                            <IonCardContent className="ion-padding">
                                                <IonRow>
                                                    <IonCol size="9" className="dash-title text-dark">         
                                                        {t("inventory_overview")}
                                                    </IonCol>
                                                    <IonCol size="3" className='dash-icon'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <img alt={t("inventory_overview")} src="assets/images/inventory-stats.png" />
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top">
                                                    <IonCol>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("total_items")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {totalItems}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("out_of_stock")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {outOfStock}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("sold_this_month")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {soldMonth}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    
                                                </IonRow>
                                            
                                            </IonCardContent>
                                        </IonCard>
                                    </SwiperSlide>
                                    )}
                                    
                                    {medical && false && ( 
                                    <SwiperSlide>
                                        <IonCard className="dash-card dash-bg-3" onClick={
                                                () => history.push(`/app/essentials`)
                                            }>
                                            <IonCardContent className="ion-padding">
                                                <IonRow>
                                                    <IonCol size="9" className="dash-title text-dark">         
                                                        {t("medical_overview")}
                                                    </IonCol>
                                                    <IonCol size="3" className='dash-icon'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <img alt={t("medical_overview")} src="assets/images/medical-1.png" />
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-margin-top">
                                                    <IonCol>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("today_incidents")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {incidentsToday}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("incidents_this_month")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {incidentsMonth}
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="8" className="dash-label-2">
                                                            {t("total_incidents")}
                                                            </IonCol>
                                                            <IonCol size="4" className='dash-info-2 ion-text-right'>
                                                            {incidents}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    
                                                </IonRow>
                                            
                                            </IonCardContent>
                                        </IonCard>
                                    </SwiperSlide>
                                    )}
                            </Swiper>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <div className="swiper-scrollbar" />
                        </IonCol>
                    </IonRow>
                        
                </IonGrid>
                <IonGrid className="mb-60">
                    <IonRow>
                        <IonCol className='ion-no-padding'>
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{768: 1, 992: 2, 1200: 3}}
                            >
                                <Masonry>
                                {(events.length > 0) && (
                                
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("upcoming_events")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="primary" size="small" className='pr-16' onClick={() => history.push(`/app/planner`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>

                                            {events.map((event) => {
                                            return (
                                                <IonCard className="planner-card-2" key={event._id}>
                                                    <IonCardContent>
                                                        <IonRow>
                                                            <IonCol>            
                                                                <IonItem lines="none"
                                                                    button={false}
                                                                    detail={false}
                                                                    >
                                                                        <IonIcon icon={sparkles}
                                                                            slot="start"
                                                                            color="primary" />
                                                                        <IonLabel className="action-title-one">
                                                                            <h3>{event.title}</h3>
                                                                            <p>{formatDate(event.calendarDate)}</p>
                                                                            </IonLabel>
                                                                    
                                                                    </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>            
                                                                <IonText>{event.description}</IonText>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCardContent>
                                                </IonCard>
                                                    );
                                                })
                                            }
                                                    </IonCol>
                                                </IonRow>          
                                                
                                            </IonCardContent>
                                        </IonCard>
                                )}

                                {(chats.length > 0) && (
                                    <IonCard className='ion-margin'>
                                        <IonCardHeader>
                                            <IonRow>
                                                <IonCol size="8">
                                                    <IonCardSubtitle className='info-subtitle pt-08'>{t("staff_chats")}</IonCardSubtitle>
                                                </IonCol>
                                                <IonCol size="4" className='ion-text-right'>
                                                    <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/chats/open`)}>{t("view_all")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>

                                                    <IonList className='ion-no-padding'>
                                                        {(chats.map((chat, i) => {
                                                                return (
                                                                    <IonItem detail={true}
                                                                    detailIcon={chevronForward}
                                                                    lines='full'
                                                                    button={true}
                                                                    key={chat._id}
                                                                    onClick={
                                                                        () => history.push(`/app/chat/${chat._id}`)
                                                                    }>
                                                                    <IonIcon slot="start"
                                                                        icon={chatbubbles}
                                                                        color='secondary'/>
                                                                    <IonLabel className="list-label text-capitalize">
                                                                        <h3>{chat.title}<br/> {chat.employee ? chat.employee.name : `${t("employees")}: ${chat.employees!.length}`} ({chat.type})<br/>{formatDate(chat.createdAt)}</h3>
                                                                    </IonLabel>
                                                                </IonItem>
                                                                )
                                                            }))
                                                            } 
                                                    </IonList>
                                                </IonCol>
                                            </IonRow>     
                                        
                                            
                                        </IonCardContent>
                                    </IonCard>

                                )}
                                                    
                                {/* Student Chat Threads */}
                                {(inquiries.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("manage_inquiries")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="tertiary" size="small" className='pr-16' onClick={() => history.push(`/app/inquiries/pending`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol>

                                                        <IonList className='ion-no-padding'>
                                                            {(inquiries.map((inquiry, i) => {
                                                                    return (
                                                                        <IonItem detail={true}
                                                                        detailIcon={chevronForward}
                                                                        lines='full'
                                                                        button={true}
                                                                        key={inquiry._id}
                                                                        onClick={
                                                                            () => history.push(`/app/inquiry/${inquiry._id}`)
                                                                        }>
                                                                        <IonIcon slot="start"
                                                                            icon={chatbubbles}
                                                                            color='tertiary'/>
                                                                        <IonLabel className="list-label text-capitalize">
                                                                            <h3>{inquiry.title}<br/> {inquiry.student ? inquiry.student.name : `${t("students")}: ${inquiry.students!.length}`} ({inquiry.type})<br/>{formatDate(inquiry.createdAt)}</h3>
                                                                        </IonLabel>
                                                                    </IonItem>
                                                                    )
                                                                }))
                                                                } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                )}

                                {(tasks.length > 0) && (
                                    <IonCard className='ion-margin'>
                                        <IonCardHeader>
                                            <IonRow>
                                                <IonCol size="8">
                                                    <IonCardSubtitle className='info-subtitle pt-08'>{t("employee_tasks")}</IonCardSubtitle>
                                                </IonCol>
                                                <IonCol size="4" className='ion-text-right'>
                                                    <IonButton color="danger" size="small" className='pr-16' onClick={() => history.push(`/app/tasks/board`)}>{t("view_all")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>

                                                    <IonList className='ion-no-padding'>
                                                        {(tasks.map((chat, i) => {
                                                                return (
                                                                    <IonItem detail={true}
                                                                    detailIcon={chevronForward}
                                                                    lines='full'
                                                                    button={true}
                                                                    key={chat._id}
                                                                    onClick={
                                                                        () => history.push(`/app/task/${chat._id}`)
                                                                    }>
                                                                    <IonIcon slot="start"
                                                                        icon={create}
                                                                        color='danger'/>
                                                                    <IonLabel className="list-label text-capitalize ion-text-wrap">
                                                                        <h3>{chat.title}
                                                                        <IonBadge className='ml-05' color={(chat.taskInfo!.priority === "high") ? "danger" : (chat.taskInfo!.priority === "medium") ? "warning" : "medium"}>
                                                                            {t(chat.taskInfo!.priority)}
                                                                        </IonBadge>
                                                                        <br/> 
                                                                        {t(chat.status)} <br/>
                                                                        {formatDate(chat.taskInfo!.startDate)} {t("to")} {formatDate(chat.taskInfo!.endDate)}
                                                                        </h3>
                                                                    </IonLabel>
                                                                </IonItem>
                                                                )
                                                            }))
                                                            } 
                                                    </IonList>
                                                </IonCol>
                                            </IonRow>     
                                        
                                            
                                        </IonCardContent>
                                    </IonCard>
                                )}

                                {(admnInquiries.length > 0) && (
                                        <IonCard className="ion-margin">
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("pending_admn_inq")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="primary" size="small" className='pr-16' onClick={() => history.push(`/app/admission/inquiries/board`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonList className='ion-no-padding'>
                                                            {(admnInquiries.map((inquiry, i) => {
                                                                    return (
                                                                        <IonItem detail={true}
                                                                        detailIcon={chevronForward}
                                                                        lines='full'
                                                                        button={true}
                                                                        key={inquiry._id}
                                                                        onClick={
                                                                            () => history.push(`/app/admission/inquiry/${inquiry._id}`)
                                                                        }>
                                                                        <IonIcon slot="start"
                                                                            icon={chatboxEllipses}
                                                                            color='primary'/>
                                                                        <IonLabel className="list-label text-capitalize">
                                                                        <IonBadge color={(inquiry.status === "new") ? "medium" : ((inquiry.status === "shared") || (inquiry.status === "unreachable"))
                                                                             ? "secondary" : (inquiry.status === "converted") ? "success" : ((inquiry.status === "failed") || (inquiry.status === "disqualified")) ? "danger" : "warning"}>
                                                                        {t(inquiry.status)}
                                                                        </IonBadge>
                                                                        <h3>{inquiry.userInfo.name} ({inquiry.type})<br/>{formatDate(inquiry.updatedAt)}</h3>
                                                                        </IonLabel>
                                                                    </IonItem>
                                                                    )
                                                                }))
                                                                } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                )}
                                
                                {(students.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("birthdays_this_week")}</IonCardSubtitle>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol>

                                                        <IonList className='ion-no-padding'>
                                                            {(students.map((student, i) => {
                                                                    return (
                                                                        <IonItem detail={false}
                                                                            lines='inset'
                                                                            button={false}
                                                                            key={student._id}
                                                                            className="leaderboard-item"
                                                                            >
                                                                        
                                                                            <IonAvatar className="leaderboard-avatar" onClick={() => history.push(`/app/student/${student._id}`)}>
                                                                            {student.photo && (student.photo !== "") ?
                                                                                (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${student.photo}`} alt={student.name} />)
                                                                                : (<img src={`assets/images/avatar.png`} alt={student.name} />)
                                                                            }
                                                                            </IonAvatar>
                                                                            <IonLabel className='leaderboard-name'>
                                                                                <h3>{student.name}</h3>
                                                                                <p>{formatDate(student.dob)}</p>
                                                                            </IonLabel>
                                                                        
                                                                        </IonItem>
                                                                    )
                                                                }))
                                                                } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    )}

                                    {(studentAttend.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("attend_logs")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/attend/log`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent className='ion-no-padding'>
                                                <IonRow>
                                                    <IonCol className='ion-no-padding'>
                                                        <IonList className='ion-no-padding'>
                                                        {(studentAttend.map((attend, i) => {
                                                        return (
                                                            <IonItem detail={false}
                                                                lines='full'
                                                                button={false}
                                                                key={attend._id}
                                                                className={((i%2) === 0) ? 'alt-item' : ''}
                                                            >
                                                                <IonIcon slot="start"
                                                                    icon={time}
                                                                    color={
                                                                        ((attend.status === 'in') || (attend.status === 'entry') || (attend.status === 'reached')) ? 'success' : ((attend.status === 'picked') || (attend.status === 'left')) ? 'warning' : (attend.status === 'custody') ? 'tertiary' : 'danger'
                                                                    }/>
                                                                    <IonLabel className="list-label ion-text-wrap text-capitalize">
                                                                        <h3>
                                                                        <Link to={`/app/student/${attend.studentID}`} className='link-dark'>{attend.studentName}</Link>
                                                                        {(attend.status === 'out') && (
                                                                            <span> {t("exit_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'in') && (
                                                                            <span> {t("entry_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'entry') && (
                                                                            <span> {(authInfo.institute.type === "school") ? t("entered_school_on") : t("entered_insti_on")} on {formatDate(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'custody') && (
                                                                            <span> {t("custody_with_parent")} on {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'picked') && (
                                                                            <span> {t("picked_by_transport")} on {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'reached') && (
                                                                            <span> {t("reached_by_transport")} on {formatDate(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'left') && (
                                                                            <span> {t("left_by_transport")} on {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'dropped') && (
                                                                            <span> {t("dropped_by_transport")} on {formatDate(attend.createdAt)}</span>
                                                                        )}
                                                                        </h3>
                                                                    </IonLabel>
                                                            </IonItem>
                                                        )
                                                        }))
                                                        } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    )}

                                    {(staffAttend.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("staff_attend_logs")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/attend/staff/entryexit/log`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent className='ion-no-padding'>
                                                <IonRow>
                                                    <IonCol className='ion-no-padding'>
                                                        <IonList className='ion-no-padding'>
                                                        {(staffAttend.map((attend, i) => {
                                                        return (
                                                            <IonItem detail={false}
                                                                lines='full'
                                                                button={false}
                                                                key={attend._id}
                                                                className={((i%2) === 0) ? 'alt-item' : ''}
                                                            >
                                                                <IonIcon slot="start"
                                                                    icon={time}
                                                                    color={
                                                                        (attend.status === 'in') ? 'success' : 'danger'
                                                                    }/>
                                                                    <IonLabel className="list-label ion-text-wrap text-capitalize">
                                                                        <h3>
                                                                        <Link to={`/app/employee/${attend.employee.employeeID}`} className='link-dark'>{attend.employee.name}</Link>
                                                                        {(attend.status === 'out') && (
                                                                            <span> {t("exit_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'in') && (
                                                                            <span> {t("entry_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        </h3>
                                                                    </IonLabel>
                                                            </IonItem>
                                                        )
                                                        }))
                                                        } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    )}
                                 </Masonry>
                            </ResponsiveMasonry>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default AdminHome;